<template>
  <div class="q-my-xs">
    <div class="row items-center justify-between q-mb-md q-mr-md">
      <div class="col-4 text-accent h3">{{ message[lang].listDegree360 }}</div>
    </div>
    <div class="q-pb-md" v-if="listTaskDegree360 && listTaskDegree360.length>0">
      <div v-for="(item, index) in listTaskDegree360" :key="index">
        <q-btn
          size="md"
          flat
          round
          color="secondary"
          icon="add_circle_outline"
          @click="taskItem=item; self_modal=true"
        >
          <q-tooltip class="bg-primary h-tt">{{ message[lang].degree_self }}</q-tooltip>
        </q-btn>
        <span class="text-accent">{{item.name}}</span>
        <div v-if="item.description" class="q-pl-md h5 text-positive" style="white-space: pre-line;">
          {{item.description}}
        </div>
      </div>
    </div>

      <q-tabs v-model="tab" class="text-accent" active-color="primary" align="left">
        <q-tab name="listDegree360" :label="message[lang].im_agreemer"  />
        <q-tab name="listMyDegree360" :label="message[lang].its_my_degree" />
      </q-tabs>


      <q-tab-panels v-model="tab" class="bg-dark text-accent">
    
        <!--Я-оценивающий, список компетенций для ответов -->
        <q-tab-panel
          name="listDegree360"
          style="max-height: 750px; padding: 0px; margin: 0px"  >
          <div class="q-mr-md">
            <q-card class="cat-card bg-negative">
              <q-card-section style="max-height: 700px" class="scroll">
                <q-list separator v-if="listDegree360 && listDegree360.length">
                  <q-expansion-item
                    group="somegroup"
                    clickable
                    v-for="(item, index) in listDegree360"
                    :key="index"
                    :label="`${item.student ? item.student.name : ''}` + ', ' + item.name + ', ' + parent.customFormatter(item.date_start) + ' - ' + parent.customFormatter(item.date_finish)"
                    :header-class="parent.isAllResultsFilled(item)?'text-accent':'text-primary'"
                    @click="openItem(item, index)"
                  >
                    <div v-if="item.description" class="q-pl-md h5 text-positive" style="white-space: pre-line;">
                      {{item.description}}
                    </div>
                    
                    <div v-if="item?.comps?.length">
                      <q-list v-for="(comp, comp_index) in item.comps" :key="comp_index">
                        <q-item-label header>{{ comp.name }}</q-item-label>
                        <q-item v-ripple v-for="(item1, index1) in comp.indicators" :key="index1">
                          <!-- <q-item-section avatar>
                            <q-icon size="md" color="positive" :name="'more_vert'" />
                          </q-item-section> -->
                          <q-item-section top class="col-8 gt-sm">
                            <q-item-label class="text-accent">{{ item1.name }}</q-item-label>
                          </q-item-section>
                          <q-item-section top>
                            <q-select v-if="!loading"
                              :ref="'ref_scale_'+item1.id"
                              :options="item.scale"
                              option-label="name"
                              option-value="value"
                              map-options
                              v-model="item1.selected"
                              :label="message[lang].assessment"
                              dense
                              class="text-accent"
                              @update:model-value="scaleChange(item1, comp, item)"
                              :disable="loading"
                              :loading="item1.loading"
                            />
                          </q-item-section>
                        </q-item>
                        <q-separator inset />
                      </q-list>
                    </div>

                    <!--  Комментарии -->
                      <Comments
                          v-bind:lang="lang"
                          v-bind:entity-id="`udegree360_${item.ID}`"
                          v-bind:user_current="parent.user_current"
                          v-bind:parent="parent"
                          v-bind:mode="'list'"
                          v-bind:hide_fio="item.flags && item.flags.hide_fio==='Y'"
                        />

                  </q-expansion-item>
                </q-list>
              </q-card-section>
            </q-card>
          </div>
        </q-tab-panel>

        <!--Я-оцениваемый Результаты меня как оцениваемого -->
        <q-tab-panel
          name="listMyDegree360"
          style="max-height: 750px; padding: 0px; margin: 0px"
        >
          <div class="q-mr-md q-my-md">
            <q-card class="cat-card bg-negative">
              <q-card-section style="max-height: 700px" class="scroll">
                <q-list separator v-if="listMyDegree360 && listMyDegree360.length">
                  <q-expansion-item
                    group="somegroup"
                    clickable
                    v-for="(item, index) in listMyDegree360"
                    :key="index"
                    :label="`${item.student ? item.student.name : ''}` + ', ' + item.name + ', ' + parent.customFormatter(item.date_start) + ' - ' + parent.customFormatter(item.date_finish)"
                    :header-class="parent.isAllResultsFilled(item)?'text-accent':'text-primary'"
                    @click="openItem(item, index)"
                  >
                    <div v-if="item.description" class="q-pl-md h5 text-positive" style="white-space: pre-line;">
                      {{item.description}}
                    </div>
                    
                      <q-card flat v-if="item?.scale?.length && item?.listUserDegree360?.length && item?.averTable?.length">
                      <q-card-section v-if="item?.scale?.length">
                          <div class="h3 text-primary q-my-sm">{{message[lang].selectscale}}</div>
                          <!--  Шкала оценки -->
                          <div v-if="item.scale && item.scale.length">
                            <q-card flat bordered  class="bg-info text-accent" >
                              <q-card-section>
                                <q-list dense separator >
                                  <q-item dense v-for="scale in item.scale" :key="scale.id">
                                    <q-item-section> 
                                      <div class="row items-center">
                                        <div class="col-2">{{ scale.value }}</div>
                                        <div class="col-5">{{ scale.name }}</div>
                                        <div class="col-5"><div v-html="scale.desc"/></div>
                                      </div>                  
                                    </q-item-section>
                                  </q-item>
                                </q-list>
                              </q-card-section>
                            </q-card>
                          </div>
                        </q-card-section>

                        <q-card-section v-if="item?.listUserDegree360?.length">
                          <!-- Количество респондентов, прнимавших участие в оценке -->
                          <q-table 
                              flat bordered
                              :title="message[lang].degree360_stat_title"
                              :rows="parent.rowsStat(item.listUserDegree360)"
                              :columns="parent.columnsStat()"
                              row-key="name"
                              hide-bottom
                            >
                            <template v-slot:header="props">
                                <q-tr :props="props">
                                  <q-th
                                    v-for="col in props.cols"
                                    :key="col.name"
                                    :props="props"
                                    class="bg-primary text-info h4"
                                    style="max-width: 200px"
                                    v-html="col.label"
                                  />
                                </q-tr>
                              </template>
                          </q-table>
                        </q-card-section>

                        <q-card-section v-if="item?.averTable?.length">
                          <!-- Результаты оценок (средние значения) -->
                          <div class="h3 text-primary q-my-sm">{{message[lang].degree360_aver_title}}</div>
                          <div v-for="rowItem,indItem in item.averTable" :key="indItem">
                            <q-table v-if="rowItem.comps && rowItem.comps.length"
                              flat bordered
                              :title="rowItem.stud_name"
                              :rows="rowItem.comps"
                              :columns="parent.columnsAver()"
                              row-key="name"
                              v-model:pagination="pagination"
                              :rows-per-page-label="message[lang].rows_per_page"
                            >

                              <template v-slot:header="props">
                                <q-tr :props="props">
                                  <q-th
                                    v-for="(col,index) in props.cols"
                                    :key="col.name"
                                    :props="props"
                                    class="bg-primary text-info h4"
                                    :style="index==0 ? 'max-width: 500px' : 'max-width: 100px'"
                                    v-html="col.label"
                                  />
                                </q-tr>
                              </template>

                            
                            </q-table>
                            <!-- График -->
                            <div v-if="rowItem.chart" class="text-center q-my-md text-accent">
                              <apexcharts height="350" type="bar" :options="rowItem.chart.options" :series="rowItem.chart.series" />
                            </div>
                          </div>
                        </q-card-section>

                      </q-card>

                    <!--  Комментарии -->
                      <Comments
                          v-bind:lang="lang"
                          v-bind:entity-id="`udegree360_${item.ID}`"
                          v-bind:user_current="parent.user_current"
                          v-bind:parent="parent"
                          v-bind:mode="'list'"
                          v-bind:hide_fio="item.flags && item.flags.hide_fio==='Y'"
                        />

                  </q-expansion-item>
                </q-list>
              </q-card-section>
            </q-card>
          </div>
        </q-tab-panel>
    </q-tab-panels>
    <q-inner-loading :showing="loading">
      <q-spinner-oval size="5em" color="secondary" />
    </q-inner-loading>

    <q-dialog v-model="self_modal" persistent position="top">
        <q-card class="bg-dark" style="width: 700px; max-width: 80vw;">
          <q-toolbar>
            <q-toolbar-title>{{ message[lang].degree_self }}</q-toolbar-title>
            <q-btn flat round dense icon="close" v-close-popup />
          </q-toolbar>

            <q-card-section>
                <degree360-self
                    :degree360Item="selfItem"
                    :taskItem="taskItem"
                    :parent="parent"
                    :lang="lang"
                    :back="back"
                    @self_close="self_close"
                    @self_task_update="self_task_update"
                />
            </q-card-section>
        </q-card>
    </q-dialog>
  </div>
</template>
<script>
import { messageObj } from "src/helpers/message";
import dayjs from "dayjs";
import VueApexCharts from 'vue3-apexcharts'; //https://apexcharts.com/docs/
import degree360Self from "src/Degree360Self.vue";
import Comments from './Comments.vue';

export default {
  name: "degree360list",
  props: {
    selfItem: Object,
    parent: Object,
    lang: String,
    back: String,
  },
  data() {
    return {
      tab:"listDegree360",
      self_modal: false,
      loading: false,
      listDegree360: [],
      listMyDegree360:[],
      listTaskDegree360: [],
      taskItem: null,
      total: 0,
      count: 500,
      totalMy: 0,
      countMy: 500,
      last_id: null,
      message: messageObj,
    };
  },
  computed: {
    isMore() {
      return (
        this.listDegree360 &&
        this.total &&
        !this.loading &&
        Object.keys(this.listDegree360).length >= this.count
      );
    },
    isMoreMy() {
      return (
        this.listMyDegree360 &&
        this.totalMy &&
        !this.loading &&
        Object.keys(this.listMyDegree360).length >= this.countMy
      );
    },
  },
  watch: {},
  mounted() {
    // console.log(':: mounted Degree360ListLK selfItem=', this.selfItem);
    if(this.selfItem){
      //Редактирование Оценки переданной из ЛК
      this.self_modal = true;
    }
    this.loading = true;
    this.loadMyExpertDegree360().then(() => {
      this.loadMyStudentDegree360().then(() => {
        //Мои Задания 
        this.loadDegree360Task().then(() => {
          // console.log("::loadPrograms listTaskDegree360=", this.listTaskDegree360);
          this.loading = false; 
        })
        
      })
    })
   
  },
  methods: {
    loadDegree360Task(){
      // console.log("::loadDegree360Task");
      let $this=this;
      return new Promise((resolve, reject) => {
        const uid = this.parent.user_current.ID;
        const filter = {
          1: {
            LOGIC: "AND",
            "%STUFF": `%"${uid}"%`,
          },
        };

        $this.parent.loadPrograms( "degree360",null,null,null,null,null,null,filter).then((res) => {
          // console.log(":Мои Задания :loadDegree360Task degree360 res=", res);
          for(let i in res){
            let degree360 = $this.parent.isJSON(res[i].DETAIL_TEXT)?JSON.parse(res[i].DETAIL_TEXT):res[i].DETAIL_TEXT;
            if(!degree360?.flags?.is_self || degree360?.agreeID?.[uid]){
              continue;
            }
            // console.log(":Мои Задания :loadDegree360Task degree360=", degree360);
            let Im = degree360.students && degree360.students.find(el=>{return el.id == $this.parent.user_current.ID});
            if(Im){
              degree360.ID = res[i].ID;
              $this.listTaskDegree360.push(degree360);
            }
          }
          resolve(true)
        });
      });

    },
    openItem(item, index){
      // console.log(':: openItem ', item);
      if(item.itsMyDegree){
        item.listUserDegree360 = [item];
        this.parent.averageTable(item);
      }

    },
    loadMyStudentDegree360() {
      let $this=this;
      return new Promise((resolve, reject) => {
         //Оценки где я оцениваемый
        let filter = {
          1: {
            LOGIC: "AND",
            "PREVIEW_TEXT": $this.parent.user_current.ID,
          },
        };
          // console.log(":я оцениваемый:loadMyStudentDegree360 udegree360 filter=", filter);

        $this.parent.loadPrograms( "udegree360", $this.count, $this.last_id,null,null,null,null,filter).then((res) => {
            // console.log(":я оцениваемый:loadMyStudentDegree360 udegree360 res=", res);
            $this.listMyDegree360 = [];
            for (let i in res) {
              let item = res[i].DETAIL_TEXT;
              if ($this.parent.isJSON(item)) {
                item = JSON.parse(item);
                //Проверяем что оценка публикуется в ЛК оцениваемого
                if(!item.results || !item.flags || item.flags.pub_lk!=='Y'){
                  continue;
                }
                //Проверяем что все эксперты ответили на оценку
                let experts = item.student.experts.map((el)=>{ return el.id});
                for(let exp_id of experts){
                  if(!$this.parent.isAllResultsFilled(item)){
                    // console.log(':: не все эксперты ответили на оценку experts=',exp_id, experts, item )
                    continue;
                  }
                }
                item.ID = res[i].ID;
                item.itsMyDegree=true; //это моя оценка здесь я оцениваемый
                $this.listMyDegree360.push(item);
                $this.last_id = item.ID;
                // console.log(':я оцениваемый: добавлено=',item )
              }
            }
            resolve(true);
        });
      });
    },
    loadMyExpertDegree360() {
      let $this=this;
      return new Promise((resolve, reject) => {
         //Оценки где я эксперт
        let filter = {
          1: {
            LOGIC: "AND",
            "%STUFF": '%"' + $this.parent.user_current.ID + '"%',
          },
        };
        // console.log(":я эксперт:loadMyExpertDegree360 udegree360 filter=", filter);
            
        $this.parent.loadPrograms( "udegree360", $this.count, $this.last_id,null,null,null,null,filter).then((res) => {
            // console.log(":я эксперт:loadMyExpertDegree360 udegree360 res=", res);
            $this.listDegree360 = [];
            let expert_id = $this.parent.user_current.ID; //Оценивающий
            for (let i in res) {
              let item = res[i].DETAIL_TEXT;
              if ($this.parent.isJSON(item)) {
                item = JSON.parse(item);
                if(!item.scale){
                  continue;
                }
                item.ID = res[i].ID;
                item.name = this.parent.restoreQuotes(item.name);
                item.description = this.parent.restoreQuotes(item.description);
                $this.listDegree360.push(item);
                $this.last_id = item.ID;
                let results = item.results || [];

                //проставим результаты если есть
                // console.log(":: results=", results);
                for (let comp1 of item.comps) {
                  for (let ind1 of comp1.indicators) {
                    if (ind1.hasOwnProperty("selected")) {
                      delete ind1.selected;
                    }

                    let index = results.findIndex(
                      (el) =>
                        parseInt(el.comp_id) === parseInt(comp1.id) &&
                        parseInt(el.ind_id) == parseInt(ind1.id) &&
                        parseInt(el.user_id) == parseInt(expert_id)
                    );

                    if (index >= 0) {
                      let scale = item.scale.find(
                        (el) => parseInt(el.id) === results[index].scale_id
                      );
                      if (scale) {
                        ind1.selected = scale;
                      }
                    }
                  }
                }
              }else{
                // console.log('::loadMyExpertDegree360 bad json ',item)
              }
            }
            // console.log(":я эксперт: loadMyExpertDegree360 listDegree360=", $this.listDegree360);
            resolve(true);
          });
      });
    },
    self_close(){
      this.self_modal = false;
      this.$emit("self_close");
    },
    scaleChange(ind, comp, degree360Item) {
      let $this = this;
      let degree360 = {...degree360Item};
      // console.log("::scaleChange degree360=", degree360);
      // console.log("::scaleChange comp=", comp);
      // console.log("::scaleChange ind=", ind);
      let expert_id = this.parent.user_current.ID; //Оценивающий
      //Нужно в назначение degree360.id записать результат
      //                 Компетенция       оценивающий             индикатор id               ответ(оценка)
      // degree360.results={comp_id:comp.id, user_id:user_current.ID, ind_id:item1.selected.id, value:item1.selected.value}
      degree360.results = degree360.results || [];
      let index = degree360.results.findIndex((el) => {
        return el.comp_id == comp.id && el.ind_id == ind.id && el.user_id == expert_id;
      });
      let itemResult = {
        comp_id: comp.id,
        user_id: expert_id,
        ind_id: ind.id,
        scale_id: ind.selected.id,
      };
      // console.log("::itemResult= ", index, itemResult);
      if (index >= 0) {
        degree360.results[index] = itemResult;
      } else {
        degree360.results.push(itemResult);
      }

      degree360Item.results = degree360.results;
      // console.log('::degree360.results= ',degree360.results)
      // for(let comp1 of degree360.comps){
      //   for(let ind1 of comp1.indicators){
      //     if(ind1.hasOwnProperty('selected')){
      //       delete ind1.selected;
      //     }
      //   }
      // }
      // delete ind.selected;

      let method = "entityItemUpdate";
      let params = {
        ENTITY: "uni_udegree360",
        ID: degree360.ID,
        // 'NAME': degree360.id
      };
      // console.log(":: scaleChange params=", params);
      
      if($this.parent.isAllResultsFilled(degree360)){
        //Если это последний индикатор в оценке
        $this.parent.countMyDegree360 --;
      }
      degree360.name = this.parent.replaceQuotes(degree360.name);
      degree360.description = this.parent.replaceTags(degree360.description);

      var url = $this.parent.bx24Init({ AJAX_ACTION: method, params: params });
      $this.parent.bx24params.DETAIL_TEXT = JSON.stringify(degree360);
      $this.loading = true;
      ind.loading = true;
      $this.parent.b24POST(url, $this.parent.bx24params, function (res) {
        // console.log(":: scaleChange res=", res);
        $this.loading = false;
        ind.loading = false;
      });
    },
    self_task_update( ){ 
      this.$emit("self_task_update", this.taskItem); 
      //Мои Задания 
      this.loadDegree360Task().then(() => {
        // console.log("::loadPrograms listTaskDegree360=", this.listTaskDegree360);
      })
    },
  },
  components: {
    degree360Self,
    Comments,
    apexcharts: VueApexCharts,
  },
};
</script>
<style scoped>
.myselect:hover {
  filter: brightness(70%);
}
</style>
