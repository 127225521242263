<template>
  <div>
    <q-file
      clearable
      v-model="listFiles"
      :label="uploadsubtitle[lang]"
      :hint="uploadtitle[lang]"
      @update:model-value="fileListChange"
      accept=".udata, .xls, .xlsx, application/vnd.ms-excel"
      borderless
      multiple
      use-chips
      class="bg-negative"
      style="padding-left:15px; border-radius: 25px !important;"
    >
      <template v-slot:prepend>
        <q-img src="img/secondary/doc/file.svg" style="height: 40px; width: 40px;"/>
      </template>
      <template v-slot:after v-if="listFiles && Object.keys(listFiles).length>0">
        <q-btn
          color="primary"
          dense
          icon="cloud_upload"
          round
          @click="upload"
          :disable="!listFiles||Object.keys(listFiles).length==0"
          :loading="isLoading"
        />
      </template>
    </q-file>
    <div class="row justify-between">
      <div class="col-4 positive h5">
        <q-toggle
          v-model="set_published"
          color="primary"
          :label="message[lang].set_published"
        />
      </div>
      <div class="col-6 text-right">
        <a class="text-positive body-2" target="_blank" :href="'./assets/test_import_example_'+lang+'.xlsx'">{{message[lang].obrazec_xlsx}}</a>
      </div>
    </div>

    <q-dialog v-model="maxSizePropsAlert">
      <q-card>
        <q-card-section>
          <div class="text-h6">{{message[lang].attention}}</div>
        </q-card-section>

        <q-card-section class="q-pt-none">
          {{message[lang].tooo_many_quests}}
        </q-card-section>

        <q-card-actions align="right">
          <q-btn flat :label="message[lang].my_understud" color="primary" v-close-popup />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
<!--    <b-row v-if="lang" class="my-1 mx-2" align-v="center">-->
<!--        <b-col>-->
<!--            <file-upload @fileListChange___import="fileListChange"-->
<!--                 id="__import"-->
<!--                 v-bind:lang="lang"-->
<!--                 v-bind:parent="0"-->
<!--                 v-bind:title="uploadtitle"-->
<!--                 v-bind:subtitle="uploadsubtitle"-->
<!--                 v-bind:files="listFiles?listFiles:''"-->
<!--                 v-bind:multiple=true-->
<!--                 file_types=".udata, application/vnd.ms-excel, .xlsx"-->
<!--            ></file-upload>-->
<!--            <div v-if="alertMessage" class="text-warning">{{alertMessage}}</div>-->
<!--        </b-col>-->
<!--        <b-col cols="2">-->
<!--            <b-button v-if="listFiles && Object.keys(listFiles).length>0 " variant="primary" @click="upload">{{message[lang].upload}}</b-button>-->
<!--            <b-button v-else variant="outline-secondary" disabled>{{message[lang].upload}}</b-button>-->
<!--            <div class="mt-1"> <a target="_blank" :href="'./assets/test_import_example_'+lang+'.xlsx'">{{message[lang].obrazec_xlsx}}</a> </div>-->
<!--        </b-col>-->
<!--    </b-row>-->
</template>
<script>
// import fileUpload from './FileUpload.vue';
import XLSX from 'xlsx';
import dayjs from "dayjs";

export default {
    props:{
      lang:String,
      parent:Object
    },
    data: function () {
        return {
          set_published:false,
            countLoaded:0,
            maxCount:100000,
            alertMessage:'',
            isLoading:false,
            listFiles:'',
            uploadtitle:{
              ru:"Для загрузки тестов можно использовать файлы ранее выгруженные в формате .udata или .xlsx, шаблон можно скачать справа под кнопкой",
              en:"To upload tests, you can use previously uploaded .udata or .xlsx files. Template is available on the right under the button",
              ua:"Для завантаження тестів можна використовувати файли які були раніше вивантажені в форматі .udata або .xlsx, шаблон можна скачати справа під кнопкою",
              fr:"Pour charger les tests, vous pouvez utiliser des fichiers .udata ou .xlsx précédemment enregistrés. Les templates sont accessibles à droite, sous le bouton.",
              it:"Per caricare i test, è possibile usare file .udata o .xlsx precedentemente caricati. Il template è disponibile sulla destra sotto il pulsante",
              tr:"Testleri yüklemek için önceden yüklenmiş .udata veya .xlsx dosyalarını kullanabilirsiniz. Şablon, düğmenin sağ tarafında bulunur ",
              de:"Zum Hochladen von Tests können Sie zuvor hochgeladene .udata- oder .xlsx-Dateien verwenden. Vorlage ist rechts unter der Schaltfläche verfügbar",
              pl:"Aby przesłać testy, możesz użyć wcześniej przesłanych plików .udata lub .xlsx. Szablon jest dostępny po prawej stronie pod przyciskiem",
              pt:"Para fazer upload de testes, você pode usar arquivos .udata ou .xlsx carregados anteriormente. O modelo está disponível à direita sob o botão",
              es:"Para cargar pruebas, puede usar archivos .udata o .xlsx cargados previamente. La plantilla está disponible a la derecha debajo del botón ",
              vn:"Để tải lên các bài kiểm tra, bạn có thể sử dụng các tệp .udata hoặc .xlsx đã tải lên trước đó. Mẫu có sẵn ở bên phải dưới nút",
            },
            uploadsubtitle:{
              ru:"Перетащите сюда файлы в формате .udata или .xlsx или кликните здесь для выбора файлов на диске.",
              en:"Drag your .udata or .xlsx files here, or click here to upload files from your disc.",
              ua:"Перетягніть сюди файли в форматі .udata або .xlsx або клікніть тут для вибору файлів на диску.",
              fr:"Faites glisser ici vos fichiers .udata ou .xlsx, ou cliquez ici pour télécharger le fichier depuis votre PC.",
              it:"Trascina qui i tuoi file .udata o .xlsx, oppure fai click qui per caricare file dal tuo disco.",
              tr:".udata veya .xlsx dosyalarınızı buraya sürükleyin veya diskinizden dosya yüklemek için burayı tıklayın.",
              de:"Ziehen Sie Ihre .udata- oder .xlsx-Dateien hierher oder klicken Sie hier, um Dateien von Ihrer Disc hochzuladen.",
              pl:"Przeciągnij tutaj swoje pliki .udata lub .xlsx albo kliknij tutaj, aby przesłać pliki z dysku.",
              pt:"Arraste seus arquivos .udata ou .xlsx aqui, ou clique aqui para fazer upload de arquivos de seu disco.",
              es:"Arrastre sus archivos .udata o .xlsx aquí, o haga clic aquí para cargar archivos desde su disco.",
              vn:"Kéo tệp .udata hoặc .xlsx của bạn vào đây hoặc nhấp vào đây để tải tệp lên từ đĩa của bạn.",
            },
            level_options: {
                ru: [
                    {text: 'легкий', value: 'low'},
                    {text: 'средний', value: 'moderate'},
                    {text: 'сложный', value: 'high'},
                ],
                en: [
                    {text: 'low', value: 'low'},
                    {text: 'moderate', value: 'moderate'},
                    {text: 'high', value: 'high'},
                ],
              ua: [
                {text: 'легкий', value: 'low'},
                {text: 'середній', value: 'moderate'},
                {text: 'складний', value: 'high'},
              ],
              fr: [
                {text: 'faible', value: 'low'},
                {text: 'moyen', value: 'moderate'},
                {text: 'fort', value: 'high'},
              ],
              it: [
                {text: 'basso', value: 'low'},
                {text: 'intermedio', value: 'moderate'},
                {text: 'alto', value: 'high'},
              ],
              tr: [
                {text: 'düşük', value: 'low'},
                {text: 'ılıman', value: 'moderate'},
                {text: 'yüksek', value: 'high'},
              ],
              de: [
                {text: 'niedrig', value: ' low'},
                {text: 'mäßig', value: 'moderate'},
                {text: 'hoch', value: 'high'},
              ],
              pl: [
                {text: 'niska', value: 'low'},
                {text: 'umiarkowany', value: 'moderate'},
                {text: 'wysoka', value: 'high'},
              ],
              pt: [
                {text: 'baixo', value: 'low'},
                {text: 'moderado', value: 'moderate'},
                {text: 'alto', value: 'high'},
              ],
              es: [
                {text: 'bajo', value: 'low'},
                {text: 'moderado', value: 'moderate'},
                {text: 'alto', value: 'high'},
              ],
              vn: [
                {text: 'thấp', value: 'low'},
                {text: 'vừa phải', value: 'moderate'},
                {text: 'cao', value: 'high'},
              ],

            },
            typeTest_options: {
                ru: [
                    {text: 'Итоговый', value: 'final'},
                    {text: 'Промежуточный', value: 'midpoint'},
                ],
                en: [
                    {text: 'Final', value: 'final'},
                    {text: 'Midpoint', value: 'midpoint'},
                ],
              ua: [
                {text: 'Підсумковий', value: 'final'},
                {text: 'Проміжний', value: 'midpoint'},
              ],
              fr:[
                {text: 'Final', value: 'final'},
                {text: 'Moyenne', value: 'midpoint'},
              ],
              it: [
                {text: 'Finale', value: 'final'},
                {text: 'Intermedio', value: 'midpoint'},
              ],
              tr: [
                {text: 'Son', value: 'final'},
                {text: 'Orta nokta', value: 'midpoint'},
              ],
              de: [
                {text: 'Finale', value: 'final'},
                {text: 'Mittelpunkt', value: 'midpoint'},
              ],
              pl: [
                {text: 'Finał', value: 'final'},
                {text: 'Środek', value: 'midpoint'},
              ],
              pt: [
                {text: 'Final', value: 'final'},
                {text: 'Ponto médio', value: 'midpoint'},
              ],
              es: [
                {text: 'Final', value: 'final'},
                {text: 'Punto medio', value: 'midpoint'},
              ],
              vn: [
                {text: 'Cuối cùng', value: 'final'},
                {text: 'Điểm giữa', value: 'midpoint'},
              ],

            },
          typeQuest_options: {
            ru: [
              {text: 'Выбор', value: 'qa'},
              {text: 'Последовательность', value: 'seq'},
              {text: 'Соответствие', value: 'eqw'},
              {text: 'Открытый', value: 'opn'},
              {text: 'Подстановка', value: 'sub'}
            ],
            en: [
              {text: 'Option', value: 'qa'},
              {text: 'Sequencing', value: 'seq'},
              {text: 'Matching', value: 'eqw'},
              {text: 'Open', value: 'opn'},
              {text: 'Substitution', value: 'sub'}
            ],
            ua: [
              {text: 'вибір', value: 'qa'},
              {text: 'послідовність', value: 'seq'},
              {text: 'відповідність', value: 'eqw'},
              {text: 'Відкритий', value: 'opn'},
              {text: 'Підстановка', value: 'sub'}
            ],
            fr:[
              {text: 'Choix multiple', value: 'qa'},
              {text: 'Séquence', value: 'seq'},
              {text: 'Correspondance', value: 'eqw'},
              {text: 'Ouvert', value: 'opn'},
              {text: 'Substitution', value:'sub'}
            ],
            it: [
              {text: 'Opzione', value: 'qa'},
              {text: 'Sequenziamento', value: 'seq'},
              {text: 'Matching', value: 'eqw'},
              {text: 'Aperto', value: 'opn'},
              {text: 'Sostituzione', value:'sub'}
            ],
            tr: [
              {text: 'Seçenek', value: 'qa'},
              {text: 'Sıralama', value: 'seq'},
              {text: 'Eşleştirme', value: 'eqw'},
              {text: 'Açık', value: 'opn'},
              {text: 'Ikame', value: 'sub'}
            ],
            de: [
              {text: 'Möglichkeit', value: 'qa'},
              {text: 'Sequenzierung', value: 'seq'},
              {text: 'Passende', value: 'eqw'},
              {text: 'Offen', value: 'opn'},
              {text: 'Substitution', value: 'sub'}
            ],
            pl: [
              {text: 'Opcja', value: 'qa'},
              {text: 'Sekwencjonowanie', value: 'seq'},
              {text: 'Dopasowanie', value: 'eqw'},
              {text: 'Otwarty', value: 'opn'},
              {text: 'Podstawienie', value: 'sub'}
            ],
            pt: [
              {text: 'Opção', value: 'qa'},
              {text: 'Sequenciamento', value: 'seq'},
              {text: 'Coincidindo', value: 'eqw'},
              {text: 'Abrir', value: 'opn'},
              {text: 'Substituição', value:'sub'}
            ],
            es: [
              {text: 'Opción', value: 'qa'},
              {text: 'Secuenciación', value: 'seq'},
              {text: 'Pareo', value: 'eqw'},
              {text: 'Abierto', value: 'opn'},
              {text: 'Sustitución', value:'sub'}
            ],
            vn: [
              {text: 'Lựa chọn', value: 'qa'},
              {text: 'Giải trình tự', value: 'seq'},
              {text: 'Phù hợp', value: 'eqw'},
              {text: 'Mở ra', value: 'opn'},
              {text: 'Thay', value: 'sub'}
            ],

          },
          maxSizePropsAlert:false,
            message:{
                ru:{
                  set_published:"Публиковать сразу",
                  tooo_many_quests:"Слишком много вопросов/ответов или они слишком длинные.",
                  my_understud:"Понятно",
                  accept:"Неверный тип файла",
                  max_file_size:"Размер файла не должен превышать 200Мб",
                  upload:"Загрузить",
                  obrazec_xlsx:"Скачать образец xlsx-файла для импорта",
                  no_type_quest:"Неправильный или не задан тип вопроса",
                    },
                en:{
                  set_published:"Publish immediately",
                  too_many_quests:"There are too many questions/answers or they are too long.",
                  my_understudy:"I see",
                  accept: "Invalid file type",
                  max_file_size: "The file size should not exceed 200Mb",
                  upload:"Upload",
                  obrazec_xlsx:"Download an xlsx file template for import",
                  no_type_quest:"Incorrect type or not set question type",
                },
              ua:{
                set_published:"Публікувати відразу",
                too_many_quests: "занадто багато запитань / відповідей або вони занадто довгі.",
                my_understudy:"зрозуміло",
                accept: "Неправильний тип файлу",
                max_file_size: "Розмір файлу не повинен перевищувати 200Мб",
                upload:"Завантажити",
                obrazec_xlsx:"Завантажити зразок xlsx-файлу для імпорту",
                no_type_quest:"Неправильний або не заданий тип питання",
              },
              fr:{
                set_published:"Publier immédiatement",
                too_many_quests: "trop de questions/réponses ou trop longues.",
                my_understudy: "Clair",
                accept: "Type de fichier Incorrect",
                max_file_size: "La taille du fichier ne doit pas dépasser 200 Mo",
                upload:"Télécharger",
                obrazec_xlsx:"Télécharger un fichier .xlsx comme template",
                no_type_quest:"Type de question incorrect ou non défini",
              },
              it:{
                set_published:"Pubblica immediatamente",
                too_many_quests: "ci sono troppe domande/risposte o sono troppo lunghe.",
                my_understudy:"chiaro",
                accept: "Tipo di file non valido",
                max_file_size: "La dimensione del file non deve superare i 200 MB",
                upload:"Carica",
                obrazec_xlsx:"Scarica un template di file xlsx per importazione",
                no_type_quest:"Tipo sbagliato o tipo di domanda non impostato",
              },
              tr:{
                set_published:"Hemen yayınla",
                too_many_quests:"Çok fazla soru/cevap var mı yoksa çok uzun mu?",
                my_understudy:"Anlaşıldı",
                accept: "Geçersiz dosya türü",
                max_file_size: "Dosya boyutu 200Mb'ı geçmemelidir",
                upload:"Yüklemek",
                obrazec_xlsx:"İçe aktarmak için bir xlsx dosya şablonu indirin",
                no_type_quest:"Yanlış tür veya soru tipi belirlenmemiş",
              },
              de:{
                set_published:"Sofort veröffentlichen",
                too_many_quests:"Es gibt zu viele Fragen/Antworten oder sie sind zu lang.",
                my_understudy:"Klar",
                accept: "ungültiger Dateityp",
                max_file_size: "Die Dateigröße sollte 200 MB nicht überschreiten",
                upload:"Hochladen",
                obrazec_xlsx:"Laden Sie eine XLSX-Dateivorlage zum Importieren herunter",
                no_type_quest:"Falscher Typ oder nicht eingestellter Fragetyp",
              },
              pl:{
                set_published:"Opublikuj natychmiast",
                too_many_quests: "jest za dużo pytań / odpowiedzi lub są za długie.",
                my_understudy: "Rozumiem",
                accept: "Nieprawidłowy typ pliku",
                max_file_size: "Rozmiar pliku nie powinien przekraczać 200 MB",
                upload:"Wgrywać",
                obrazec_xlsx:"Pobierz szablon pliku xlsx do importu",
                no_type_quest:"Nieprawidłowy typ lub nieustawiony typ pytania",
              },
              pt:{
                set_published:"Publicar imediatamente",
                too_many_quests: "muitas perguntas / respostas ou são muito longas.",
                my_understudy: "entendi",
                accept: "Tipo de arquivo inválido",
                max_file_size: "O tamanho do arquivo não deve exceder 200Mb",
                upload:"Envio",
                obrazec_xlsx:"Baixe um modelo de arquivo xlsx para importação",
                no_type_quest:"Tipo incorreto ou tipo de pergunta não definido",
              },
              es:{
                set_published:"Publicar inmediatamente",
                too_many_quests: "Demasiadas preguntas / respuestas o son demasiado largas.",
                my_understudy: "Claro",
                accept: "Tipo de archivo invalido",
                max_file_size: "El tamaño del archivo no debe exceder los 200Mb.",
                upload:"Subir",
                obrazec_xlsx:"Descargue una plantilla de archivo xlsx para importar",
                no_type_quest:"Tipo de pregunta incorrecto o no establecido",
              },
              vn:{
                set_published:"Xuất bản ngay lập tức",
                too_many_quests:" có quá nhiều câu hỏi / câu trả lời hoặc chúng quá dài.",
                my_understudy: "tôi thấy",
                accept: "Loại tệp không hợp lệ",
                max_file_size: "Kích thước tệp không được vượt quá 200Mb",
                upload:"Tải lên",
                obrazec_xlsx:"Tải xuống mẫu tệp xlsx để nhập",
                no_type_quest:"Loại câu hỏi không chính xác hoặc không đặt",
              },

            },
        }
    },
    mounted: function(){
      // console.log(':testimport: mounted props=', this.lang, this.uploadtitle, this.uploadsubtitle)
      this.countLoaded=0;
        this.maxCount=this.parent.parent.getMaxCountByCache('tests');
    },
     watch:{
        // lang: function(){
        //     console.log(':: lang',lang);
        // },
    },
    methods: {
      onRejectedOne (rejectedEntries) {
        console.log('::onRejectedOne ', rejectedEntries)
        if(rejectedEntries[0]) {

          // eslint-disable-next-line vue/no-mutating-props
          this.parent.notify_text='';
          for(let item of rejectedEntries){
            if(item.failedPropValidation == 'max-file-size') {
              // eslint-disable-next-line vue/no-mutating-props
              this.parent.notify_text += item.file.name + ': ' + this.message[this.lang].max_file_size
              // eslint-disable-next-line vue/no-mutating-props
              this.parent.notify = true;
            }else if(item.failedPropValidation == 'accept') {
              // eslint-disable-next-line vue/no-mutating-props
              this.parent.notify_text += item.file.name + ': ' + this.message[this.lang].accept
              // eslint-disable-next-line vue/no-mutating-props
              this.parent.notify = true;
            }
          }


        }
      },
        fileListChange(listFiles){
            // console.log(':: fileListChange', listFiles);
            this.listFiles = listFiles;
        },
        upload(){
            // console.log(':: upload', this.listFiles);
          // eslint-disable-next-line vue/no-mutating-props
          this.isLoading=true;
          // eslint-disable-next-line vue/no-mutating-props
          this.parent.isShowImport=false;
          // eslint-disable-next-line vue/no-mutating-props
          this.parent.isImportLoading=true;

          if(Object.keys(this.listFiles).length > 0){
            let item=this.listFiles.pop();
            this.parent.readFileFromDisk(item).then(data=> {
                // console.log(':: item=',item);
                let b64data = data.data.substr(data.data.indexOf('base64,') + 7);
                let b64data2 = atob(b64data);
                if(item.name.indexOf('.xls') !== -1){
                    let workbook = XLSX.read(b64data2, {type:"binary"});
                    // console.log(':: workbook=', workbook);
                    let first_sheet_name = workbook.SheetNames[0];
                    /* Get worksheet */
                    let worksheet = workbook.Sheets[first_sheet_name];
                    let test={DETAIL_TEXT:'', PROPERTY_VALUES:{}};
                    let test_blocks=[];
                    let item_blocks={};
                    let tests=[];
                    let questions=[];
                    let quest_id=0;
                    let answ_id=0;
                    let answers={};
                    let row_type='';
                    let last_value='';
                  let last_type='';
                  let last_weight='';
                  let last_block='';
                  let isEnd=false;
                    let emptyNums=0;
                    let columns=["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O"];
                    for(let j=1; j<10010; j++){
                        if(isEnd){
                            break;
                        }
                        for (let i in columns){
                            let col=columns[i];
                            let row=j;
                            let address_of_cell = col+row;
                            let cell = worksheet[address_of_cell];
                            /* Get the value */
                            let value = (cell ? cell.v : undefined);
                            // console.log(':'+address_of_cell+': value=|%s|', value);

                            if(col === 'A' && value && value.startsWith('#')){
                                //это комментарий
                                break;
                            }else if(col === 'A' && value==='TEST'){
                                if(test.NAME) {
                                    // test.PROPERTY_VALUES.questions = JSON.stringify(questions);
                                    // test.PROPERTY_VALUES.answers = JSON.stringify(answers);
                                  test.DETAIL_TEXT = JSON.stringify({questions:questions, answers:answers});
                                    //
                                    if(this.countLoaded < this.maxCount) {
                                        tests.push(test);
                                        this.countLoaded++;
                                    }else{
                                        isEnd=true;
                                        break;
                                    }
                                }
                                row_type='test';
                                test={DETAIL_TEXT:'', PROPERTY_VALUES:{}};
                                questions=[];
                                answers={};
                                emptyNums = 0;
                                break;
                            }else if(col === 'A' && value==='QUEST'){
                              row_type='qusetion';
                              quest_id ++;
                              emptyNums = 0;
                              break;
                            }else if(col === 'A' && value==='BLOCKS'){
                              row_type='blocks';
                              emptyNums = 0;
                              break;
                            }else if(col === 'A' && value==='ANSW'){
                                row_type='answers';
                                answ_id = 0;
                                answers[quest_id]=[];
                                break;
                            }
                            else if(col === 'B' && !value){
                                emptyNums ++;
                                if(emptyNums > 5 || row >10000) {
                                    isEnd = true;
                                    if(test.NAME) {
                                        // test.PROPERTY_VALUES.questions = JSON.stringify(questions);
                                        // test.PROPERTY_VALUES.answers = JSON.stringify(answers);
                                      test.DETAIL_TEXT = JSON.stringify({questions:questions, answers:answers});

                                      if(this.countLoaded < this.maxCount) {
                                            tests.push(test);
                                            this.countLoaded++;
                                        }else{
                                            isEnd=true;
                                            break;
                                        }
                                    }

                                }
                                break;
                            }
                            // console.log(':'+address_of_cell+': row_type=', row_type);
                            if(row_type === 'blocks'){
                              // console.log(':'+address_of_cell+': value=', value, test_blocks);
                              if(col === 'B'){
                                item_blocks ={name:value};
                              }else if(col === 'C'){
                                item_blocks.id = value;
                              }else if(col === 'D'){
                                item_blocks.count = value;
                              }else if(col === 'E'){
                                item_blocks.rand = value === true || value === 'Y';
                                test_blocks.push(JSON.parse(JSON.stringify(item_blocks)));
                              }
                            }
                            else if(row_type === 'test'){
                                if(col === 'B'){
                                    test.NAME = value;
                                }else if(col === 'C'){
                                    test.PROPERTY_VALUES.thematics = value;
                                }else if(col === 'D'){
                                    test.PROPERTY_VALUES.description = value;
                                }else if(col === 'E'){
                                    test.PROPERTY_VALUES.interval = value?parseInt(value):0;
                                }else if(col === 'F'){
                                    test.PROPERTY_VALUES.todisplay = value;
                                }else if(col === 'G'){
                                    let level=this.level_options[this.lang].find((element, index, array)=>{ if(element.text.toLowerCase() === value.toLowerCase()) return true; else return false }, this);
                                    level = level ? level : value;
                                    test.PROPERTY_VALUES.level = level.value;
                                }else if(col === 'H'){
                                    test.PROPERTY_VALUES.score = value;
                                }else if(col === 'I'){
                                    test.PROPERTY_VALUES.time = value;
                                }else if(col === 'J'){
                                    let type=this.typeTest_options[this.lang].find((element, index, array)=>{ if(element.text.toLowerCase() === value.toLowerCase()) return true; else return false }, this);
                                    type = type ? type : value;
                                    test.PROPERTY_VALUES.type = type.value;
                                }else if(col === 'K'){
                                  let show_results = this.parent.show_results[this.lang].find((element, index, array) => {
                                    if (element.text == value) { return true; } else { return false; }
                                  }, this);
                                  test.PROPERTY_VALUES.show_results = show_results?show_results.value:'0';
                                }else if(col === 'L'){
                                  let no_prev = this.parent.YesNo[this.lang].find((element, index, array) => {
                                    if (element.text == value) { return true; } else { return false; }
                                  }, this);
                                  test.PROPERTY_VALUES.no_prev = no_prev?no_prev.value:'N';
                                }else if(col === 'M') {
                                  test.DETAIL_PICTURE = value;
                                }else if(col === 'N') {
                                  test.PROPERTY_VALUES.flags = value;
                                }

                            }else if(row_type === 'qusetion') {
                                if (col === 'B' && value) {
                                    last_value=value;
                                }else if (col === 'C' && last_value) {
                                    value = value ? value : 'Выбор';
                                    let type = this.typeQuest_options[this.lang].find((element, index, array) => {
                                        if (element.text.toLowerCase() === value.toLowerCase()) return true; else return false
                                    }, this);
                                    if(!type){
                                        this.alertMessage += this.message[this.lang].no_type_quest+' ';
                                    }
                                    type = type ? type.value : value;
                                    // questions.push({value: quest_id, text: last_value, type: type});
                                    last_type=type;
                                }else if (col === 'D') {
                                  last_weight=value ? value : 0;
                                  // questions.push({value: quest_id, text: last_value, type: last_type, weight:last_weight});
                                  // break;
                                }else if (col === 'E') {
                                  last_block=value ? value : '';
                                  questions.push({value: quest_id, text: last_value, type: last_type, weight:last_weight, block:last_block});
                                  if(test_blocks && test_blocks.length>0) {
                                    test.PROPERTY_VALUES.blocks = JSON.stringify(test_blocks);
                                  }
                                  break;
                                }
                            }else if(row_type === 'answers') {
                                if (col === 'B'  && value) {
                                    answ_id ++;
                                    last_value=value;
                                }else if (col === 'C' && last_value && last_type) {
                                    let item={value: answ_id, text: last_value};
                                    if(last_type === 'qa') {
                                        let isRight = value && (value.toLowerCase() === 'правильный' || value.toLowerCase() === 'right' || value.toLowerCase() === 'correct' || value.toLowerCase() === 'true');
                                        item.right = isRight;
                                    }else if(last_type === 'eqw') {
                                        item.text2 = value;
                                    }
                                    answers[quest_id].push(item);
                                    break;
                                }
                            }
                        }

                    }
                    this.doSaveTest(tests);

                }else {

                    let json = decodeURIComponent(escape(atob(b64data2)));
                    let test = JSON.parse(json);
                    // console.log(':: test=', test);
                    //загрузим файлы теста
                    if (test.files) {
                        // console.log(':: test.files=', test.files);
                        let count = Object.keys(test.files).length;
                        for (let j in test.files) {
                            let item = test.files[j];
                            // console.log(':: item=', item);
                            this.parent.parent.saveFile(item).then(file_id => {
                                // console.log(':: ------ count=%i old_id=%i new_id=%i', count, item.ID, file_id);
                                if (test.PROPERTY_VALUES.answers) {
                                    test.PROPERTY_VALUES.answers = test.PROPERTY_VALUES.answers.replace('"file":' + item.ID, '"file":' + file_id);
                                }
                                count--;
                                if (count <= 0) {
                                    // выполнились все
                                    test.files = null;
                                    if(this.countLoaded < this.maxCount) {
                                        this.doSaveTest([test]);
                                        this.countLoaded++;
                                    }else{
                                        this.listFiles=[];
                                        this.upload();
                                    }
                                }

                            }, err => {
                                // console.log(err)
                            });

                        }
                    } else {
                        if(this.countLoaded < this.maxCount) {
                            this.doSaveTest([test]);
                            this.countLoaded++;
                        }else{
                            this.parent.parent.tarifAlert('tests').then(isOk=>{});
                            this.listFiles=[];
                            this.upload();
                        }
                    }
                }
              });
            }else{
              this.isLoading=false;
              this.$emit('importTests', true)
            }

        },
        doSaveTest(tests){
            // console.log(':: doSaveTest tests=', tests);
            let $this = this;
            if(tests && Object.keys(tests).length > 0) {
                let test = tests.pop();
              // console.log(':: doSaveTest test=', test);
                this.parent.parent.getTestByName(test.NAME).then(res=>{
                    // console.log('::getTestByName res=',res);

                    let method = 'entityItemAdd';
                    let id=0;
                    if(res.result && res.result[0] && res.result[0].ID){
                        id = res.result[0].ID;
                        method = 'entityItemUpdate';
                    }
                    let params = {
                        'ENTITY': 'uni_tests',
                        'PREVIEW_TEXT': $this.parent.parent.categoria,
                        'NAME': test.NAME,
                        // 'DETAIL_TEXT': test.DETAIL_TEXT,
                        // 'DATE_CREATE': dayjs().format('DD.MM.YYYY'),
                        'ACTIVE':$this.set_published?'Y':'N',
                        'DATE_ACTIVE_FROM':'',
                        'PROPERTY_VALUES': test.PROPERTY_VALUES
                    };
                    if(id){
                        params.ID=id;
                    }
                    if(JSON.stringify(params.PROPERTY_VALUES).length >63990) {
                      console.error('The number of questions exceeds the allowed number. The total volume should be less than 63941, and now ', JSON.stringify(params.PROPERTY_VALUES).length);
                      $this.maxSizePropsAlert=true;
                      return;
                    }
                  var url = this.parent.bx24Init({ AJAX_ACTION: method, params:params });//.then(params=> {
                  // $this.parent.bx24params.DETAIL_TEXT = test.DETAIL_TEXT;
                  $this.parent.bx24params.DETAIL_TEXT = $this.parent.utf8_to_b64(test.DETAIL_TEXT);
                  $this.parent.bx24params.DETAIL_PICTURE = test.DETAIL_PICTURE;
                  $this.parent.b24POST(url, this.parent.bx24params, function (res) {

                    if (res.result) {
                                let test_id = parseInt(res.result);
                                // if(id){
                                //     console.log('Test updated! id=', id);
                                // }else {
                                //     console.log('New test added! id=', test_id);
                                // }
                            }
                        // }
                        $this.doSaveTest(tests)
                    }, "json");

                });
            }else{
                $this.upload();
            }
        },
    },
    components: {
     // fileUpload
    }
}
</script>

<style scoped>


 </style>

