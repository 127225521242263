<template>
  <div class="q-pa-sm" style="margin-top: -10px">

    <div v-if="parent.isMobile" class="h4 q-ml-md" style="margin-top: -20px">
<!--      <q-btn flat round dense icon="west" color="positive" @click="$emit('goback', goback);"/>-->
      <q-btn color="positive" no-caps flat round icon="transit_enterexit" @click="$emit('goback', goback);"/>
      {{message[lang].ipr_title}}
    </div>
    <div v-else>
<!--      <a href="#" class="body-2" @click="$emit('goback', goback);" >-->
<!--        <q-img src="~assets/Back/arrow/left.svg"  style="height: 24px; width: 24px"/> {{message[lang].goback}}-->
<!--      </a>-->
      <q-btn color="positive" no-caps flat rounded icon="transit_enterexit" :label="message[lang].goback" @click="$emit('goback', goback);"/>
      <div class="h4 text-positive">
        {{message[lang].ipr_title}}
      </div>
    </div>
    <div class="column items-end positive h4" v-if="file_download_link">
      {{message[lang].download}}:<span class="text-primary"  v-html="file_download_link"/>
    </div>

    <q-card class="cat-card">
      <q-card-section v-if="checkRules('create')">
        <div class="row items-center justify-between q-my-md">
          <div class="col-2">
            <q-btn
              rounded
              :color="showInput?'positive':'primary'"
              :icon="showInput?'expand_less':'expand_more'"
              @click="showInput=!showInput"
              :label="!showInput?message[lang].add:message[lang].close"/>
          </div>
          <div class="col-7" v-if="showInput">
            <!-- Название ИПР-->
            <q-input
              v-model="iprName"
              outlined
              rounded
              :label="message[lang].ipr_name"
              @keyup.enter="addIpr('ipr')"
            >
              <template v-slot:append>
                <q-btn type="submit" :disable="!iprName || saving" round dense flat icon="add" @click="addIpr('ipr')" >
                  <q-tooltip class="bg-transparent text-positive" :offset="[5, 5]">
                    {{message[lang].add}}
                  </q-tooltip>
                </q-btn>
              </template>
            </q-input>
          </div>
          <div class="col-2 text-right">
            <q-spinner-hourglass color="secondary" size="md" v-if="saving"/>
          </div>
        </div>
      </q-card-section>

      <!-- Список ИПР -->
      <q-card-section>
        <div v-if="listIpr">
          <div v-for="(item,index) in listIpr" :key="index">
            <div v-if="checkRules('read', item)" class="row q-py-sm items-center" style="border-bottom: #eeeeee 1px solid">
               <!-- Показать ИПР-->
               <div class="col-1">
                <q-btn round dense flat icon="view_headline" color="positive" @click="expandIprRow(index)">
                  <q-tooltip class="bg-transparent text-primary" :offset="[5, 5]">
                    <span v-if="showInd[index]">{{message[lang].ipr_close}}</span><span v-else>{{message[lang].ipr_open}}</span>
                  </q-tooltip>
                  <q-badge rounded floating :color="isImPersona(item)==1?'warning':'primary'" v-if="isImPersona(item)">
                  </q-badge>
                </q-btn>
              </div>
              <div :class="checkRules('edit', item)?'col-5 cursor-pointer  q-py-xs':'col-5 q-py-xs'">
                <span class="h5 text-primary">{{item.name}}</span>
                <q-popup-edit v-model="listIpr[index].name" auto-save v-slot="scope" @update:model-value="saveIpr(index,null)" v-if="checkRules('edit', item)">
                  <q-input v-model="scope.value" dense autofocus counter @keyup.enter="scope.set">
                    <template v-slot:after>
                      <q-btn
                        flat dense color="warning" icon="cancel"
                        @click.stop.prevent="scope.cancel"
                      />

                      <q-btn
                        flat dense color="secondary" icon="check_circle"
                        @click.stop.prevent="scope.set"
                        :disable="scope.validate(scope.value) === false || scope.initialValue === scope.value"
                      />
                    </template>
                  </q-input>
                </q-popup-edit>
              </div>
              <div class="col-4 q-py-xs">
                <span v-if="item.person" class="h5 text-positive">{{item.person.name}}</span>
              </div>
              <div class="col-2 q-gutter-x-sm">
                <!-- Экспорт export1Ipr -->
                <q-btn round dense flat icon="file_download" color="positive" @click="export1Ipr(item)" :disable="!checkRules('read', item)">
                  <q-tooltip class="bg-transparent text-primary" :offset="[5, 5]">
                    {{message[lang].ipr_export}}
                  </q-tooltip>
                </q-btn>
                <!--  Удаление строки-->
                <q-btn round dense flat icon="clear" color="positive" @click="iprDelete(index)" :disable="!checkRules('edit', item)">
                  <q-tooltip class="bg-transparent text-warning" :offset="[5, 5]">
                    {{message[lang].deleteSome}}
                  </q-tooltip>
                </q-btn>
                <!-- Публикация -->
                <q-toggle
                  v-model="listIpr[index].published"
                  color="primary"
                  size="sm"
                  false-value="N"
                  true-value="Y"
                  @update:model-value="saveIpr(index)"
                  :disable="item.published!=='Y'&& noValidToPublish(item) || !checkRules('edit', item)"
                >
                  <q-tooltip v-if="item.published!=='Y'&& noValidToPublish(item) || !checkRules('edit', item)"
                              class="bg-primary h-tt" :offset="[5, 5]">
                      {{message[lang].no_valid_publish}}
                  </q-tooltip>
                  <q-tooltip v-else class="bg-transparent text-primary" :offset="[5, 5]">
                    {{message[lang].publish}}
                  </q-tooltip>
                </q-toggle>

              </div>

              <!-- Ред ИПР -->
              <div class="col-12 q-pa-md" v-if="showInd[index]" style="border-radius: 25px; border: #eeeeee 1px solid; box-shadow: 1px 1px 1px #dddddd;">
                <div class="row justify-between">
                  <!-- Добавить person -->
                  <div class="col-md-3 col-xs-12 text-center">
                    <q-btn :loading="isStudentsLoading" :disable="!checkRules('edit', item)" rounded flat color="positive" :icon="checkRules('edit', item)?'add':'person_outline'"
                           @click="addUser(index, 'person')" :label="message[lang].select_person"/>
                      <div>
                        <q-chip v-if="item.person" color="positive" text-color="accent"
                              :removable="checkRules('edit', item)"  @remove="delUser(index, 'person')" size="sm"  icon="account_circle">
                          {{item.person.name}}
                        </q-chip>
                      </div>
                  </div>
                  <!-- Добавить manager -->
                  <div class="col-md-3 col-xs-12 text-center">
                    <q-btn :loading="isStudentsLoading" :disable="!checkRules('edit', item)" rounded flat color="positive" :icon="checkRules('edit', item)?'add':'manage_accounts'"
                           @click="addUser(index, 'manager')" :label="message[lang].select_manager" />
                    <div>
                      <q-chip v-if="item.manager" color="positive" text-color="accent"
                                :removable="checkRules('edit', item)"  @remove="delUser(index, 'manager')" size="sm"  icon="account_circle">
                          {{item.manager.name}}
                        </q-chip>
                    </div>
                  </div>
                  <!-- Добавить mentor -->
                  <div class="col-md-3 col-xs-12 text-center">
                    <q-btn :loading="isStudentsLoading" :disable="!checkRules('edit', item)" rounded flat color="positive" :icon="checkRules('edit', item)?'add':'record_voice_over'"
                           @click="addUser(index, 'mentor')" :label="message[lang].select_mentor" />
                    <div>
                      <q-chip v-if="item.mentor" color="positive" text-color="accent"
                              :removable="checkRules('edit', item)"  @remove="delUser(index, 'mentor')" size="sm"  icon="account_circle">
                        {{item.mentor.name}}
                      </q-chip>
                    </div>
                  </div>

                  <div class="col-1 text-right">
                    <q-spinner-hourglass color="secondary" size="md" v-if="saving"/>
                  </div>
                </div>
                <!-- Добавить Файлы -->
                <div class="col-12 q-mt-md">
                  <q-expansion-item :default-opened="listIpr[index].files"
                    :label="message[lang].set_files"
                    header-class="bg-dark text-secondary">
                        <div class="row items-center">
                          <div class="col-2">
                           <streaming-file v-if="checkRules('edit', item)"
                                          @streamingSuccess="sendFileSuccess" @streamingFailed="sendFileFailed"
                                          v-bind:lang="lang"
                                          v-bind:parent="parent"
                                          v-bind:accept="'*'"
                                          v-bind:url="`sendfile?index=${index}`"
                                          v-bind:label="null"
                            />
                          </div>
                          <div v-if="listIpr[index].files" class="col-10 h4 positive h5 q-gutter-xs">
                            <q-chip color="positive" outline v-for="(item,index) in listIpr[index].files" :key="index" removable  @remove="listIpr[index].files.splice(index,1)" size="md" >
                              <a v-if="item.href" target="_blank" :href="item.href">{{item.name}}</a>
                            </q-chip>
                          </div>
                        </div>
                        <div v-if="file_error" class="text-warning q-my-sm">{{file_error}}</div>
                  </q-expansion-item>
                </div>
                <!--Текстовые блоки -->
                <div class="col-12 q-mt-md">
                  <q-expansion-item v-if="listIpr[index].text_blocks || mode!=='LK'" :default-opened="listIpr[index].text_blocks"
                    :label="message[lang].text_block" header-class="bg-dark text-secondary">
                    <!-- В режиме ЛК  -->
                    <div v-if="mode==='LK'" class="row items-center">
                      <div v-if="listIpr[index].text_blocks" style="width:100%">
                        <div class="row items-center q-pa-md" v-for="(b_item,b_index) in listIpr[index].text_blocks" :key="b_index">
                        <div class="col-4 text-accent">
                          {{b_item.name}}
                        </div>
                        <div class="col-8">
                          <div style="min-height: 50px">
                            <q-input
                              v-model="b_item.value"
                              borderless
                              type="textarea"
                              filled
                              autogrow
                              @update:model-value="saveIpr(index)"
                              debounce="500"
                              :readonly="b_item.ro=='Y'"
                            />
                          </div>
                        </div>
                      </div>
                      </div>
                    </div>
                    <!-- В режиме модератора  -->
                    <div v-else class="row items-center">
                      <div class="col-2">
                        <q-btn :loading="isCompsLoading" :disable="item.published==='Y'||item.on_appr==='Y'" rounded flat color="positive" icon="add"
                               @click="addTextBlock(listIpr[index])" :label="message[lang].add" v-if="checkRules('edit', listIpr[index])"/>
                      </div>
                      <div class="col-12" v-if="listIpr[index].text_blocks">
                        <div class="row items-center q-pa-md" v-for="(b_item,b_index) in listIpr[index].text_blocks" :key="b_index">
                          <div class="col-4">
                            <span class="cursor-pointer">{{b_item.name}}</span>
                            <q-popup-edit v-model="b_item.name" auto-save v-slot="scope" @update:model-value="saveIpr(index,null)" v-if="checkRules('edit', listIpr[index])">
                              <q-input v-model="scope.value" dense autofocus counter @keyup.enter="scope.set">
                                <template v-slot:after>
                                  <q-btn flat dense color="warning" icon="cancel" @click.stop.prevent="scope.cancel" />

                                  <q-btn flat dense color="secondary" icon="check_circle" @click.stop.prevent="scope.set"
                                         :disable="scope.validate(scope.value) === false || scope.initialValue === scope.value" />
                                </template>
                              </q-input>
                            </q-popup-edit>
                          </div>
                          <div class="col-6">
                            <div style="min-height: 50px">
                              <q-input
                                       v-model="b_item.value"
                                       borderless
                                       type="textarea"
                                       filled
                                       autogrow
                                       @update:model-value="saveIpr(index)"
                                       debounce="500"
                              />
                            </div>
                          </div>
                          <div class="col-2">
                            <q-toggle
                              v-model="b_item.ro"
                              color="primary"
                              size="sm"
                              false-value="N"
                              true-value="Y"
                              @update:model-value="saveIpr(index)"
                              :disable="listIpr[index].published==='Y'|| !checkRules('edit', listIpr[index])"
                              :label="message[lang].r_o"
                              class="text-positive"
                            >
<!--                              <q-tooltip  class="bg-transparent text-primary" :offset="[5, 5]">-->
<!--                                {{message[lang].r_o}}-->
<!--                              </q-tooltip>-->
                            </q-toggle>
                        </div>
                        </div>
                      </div>
                    </div>

                  </q-expansion-item>
                </div>

                <!-- Компетенции список -->
                <div class="col-12 q-mt-md">
                  <q-expansion-item default-opened
                    :label="message[lang].competencies" header-class="bg-dark text-secondary">

                    <!-- добавить комп -->
                    <div class="text-positive h4 q-my-md">
                      <q-btn padding="none" :loading="isCompsLoading" :disable="item.published==='Y'||item.on_appr==='Y'" rounded flat color="positive" icon-right="add"
                           @click="addComps(index)" :label="message[lang].ipr_comp" v-if="checkRules('edit', item)"/>
                      <div v-if="showCompNameInput[index]">
                        <q-input
                          v-model="CompNameInput"
                          outlined
                          rounded
                          :label="message[lang].comp_name"
                        >
                          <template v-slot:append>
                            <q-btn :disable="!CompNameInput" round dense flat icon="add" @click="add1Comp(index, CompNameInput); showCompNameInput[index]=false" >
                              <q-tooltip class="bg-transparent text-positive" :offset="[5, 5]">
                                {{message[lang].add}}
                              </q-tooltip>
                            </q-btn>
                          </template>
                        </q-input>
                      </div>
                    </div>

                    <div v-for="(comp, c_index) in item.comps" :key="c_index">
                      <div class="row items-center bg-info text-accent q-pa-sm" style="border-radius: 25px">
                          <div class="col-md-8 col-xs-12 text-secondary h4">
                            {{comp.name}}
                          </div>
                          <div class="col-md-3 col-xs-11">
                            <!-- Шкала оценок-->
                            <q-select
                              :readonly="item.published==='Y'||item.on_appr==='Y'"
                              v-model="comp.scale"
                              option-label="name"
                              map-options
                              :label="message[lang].selectscale"
                              :options="scale_opts"
                              @update:model-value="saveIpr(index, null, true, c_index)"
                              :disable="!checkRules('edit', item)"
                            />
                          </div>
                          <div class="col-1 text-right">
                            <q-btn :disable="item.published==='Y'||item.on_appr==='Y'" :loading="isCompsLoading" round flat color="warning" icon="clear" @click="delComp(index, c_index)" v-if="checkRules('edit', item)">
                              <q-tooltip class="bg-transparent text-warning" :offset="[5, 5]">
                                {{message[lang].delete}}
                              </q-tooltip>
                            </q-btn>
                          </div>
                        </div>

                      <!-- Индикаторы-->
                      <div class="q-ml-lg q-py-sm text-positive h4">
                          <q-btn rounded flat color="positive" icon-right="add" :label="message[lang].ipr_ind"
                                 @click="showIndNameInput[c_index]=!showIndNameInput[c_index]" v-if="checkRules('edit', item)"/>
                      </div>

                      <div class="q-ml-lg q-py-sm" v-if="showIndNameInput[c_index]">
                            <q-input
                              v-model="IndNameInput"
                              outlined
                              rounded
                              :label="message[lang].ind_name"
                            >
                              <template v-slot:append>
                                <q-btn :disable="!IndNameInput" round dense flat icon="add" @click="add1Ind(index,c_index, IndNameInput); showIndNameInput[c_index]=false" >
                                  <q-tooltip class="bg-transparent text-positive" :offset="[5, 5]">
                                    {{message[lang].add}}
                                  </q-tooltip>
                                </q-btn>
                              </template>
                            </q-input>
                      </div>
                      <!-- Список индикаторов  -->
                      <div class="row q-ml-lg q-py-sm" v-for="(indicator,ind) in comp.indicators" :key="ind" >
                        <q-card flat bordered class="cat-card bg-grey-1">
                          <q-card-section>
                            <div class="row items-center justify-between q-my-md " >
                              <div class="col-md-3 col-xs-12">
                                <q-btn round dense flat icon="clear" color="warning" @click="indDel(index, c_index, ind)" v-if="checkRules('edit', item)">
                                  <q-tooltip class="bg-transparent text-warning" :offset="[5, 5]">
                                    {{message[lang].deleteSome}}
                                  </q-tooltip>
                                </q-btn>
                                <span class="q-ml-md text-accent">{{indicator.value}}</span>

                              </div>
                              <!-- Текущий уровень  -->
                              <div class="col-md-3 col-xs-12">
                                <q-select v-if="comp.scale && comp.scale.indicators"
                                        :readonly="item.published==='Y'||item.on_appr==='Y'"
                                        v-model="indicator.current_sc"
                                        option-label="name"
                                        map-options
                                        :label="message[lang].current_sc"
                                        :options="comp.scale.indicators"
                                        @update:model-value="saveIpr(index, null)"
                                        :disable="!checkRules('edit', item)"
                              >
                                <q-tooltip v-if="indicator.current_sc" anchor="top right" class="bg-primary h-tt" v-html="showIndDescription(comp, indicator.current_sc.value)"/>
                              </q-select>
                              </div>
                              <!-- Целевой уровень  -->
                              <div class="col-md-3 col-xs-12">
                                <q-select v-if="comp.scale && comp.scale.indicators"
                                        :readonly="item.published==='Y'||item.on_appr==='Y'"
                                        v-model="indicator.target_sc"
                                        option-label="name"
                                        map-options
                                        :label="message[lang].target_sc"
                                        :options="comp.scale.indicators"
                                        @update:model-value="saveIpr(index, null)"
                                        :disable="!checkRules('edit', item)"
                              >
                                <q-tooltip v-if="indicator.target_sc" anchor="top right" class="bg-primary h-tt" v-html="showIndDescription(comp, indicator.target_sc.value)"/>
                              </q-select>
                              </div>
                            </div>
                            <!-- Развивающее Мероприятие-->
                            <div class="row items-center justify-between q-my-md " >
                            <div class="col-10 offset-2 q-px-xs text-left">
                              <!-- добавить РМ -->
                              <q-btn size="sm" rounded flat unelevated color="positive" icon-right="add"
                                     @click="addRM(indicator)" :label="message[lang].add_rm" v-if="checkRules('edit', item)"/>

                              <div v-if="indicator.rm" class="q-pa-xs q-ma-xs" style="border: #eeeeee 1px solid; border-radius: 15px ">
                                <div v-for="(rm,ind_rm) in indicator.rm" :key="ind_rm" >
                                  <div class="row items-center">
                                    <div class="col-md-3 col-xs-12">
                                      <div v-if="isRmCustom(indicator, ind_rm )">
                                        <q-input
                                          v-model="indicator.rm[ind_rm]"
                                          @update:model-value="saveIpr(index, null)"
                                          :hint="message[lang].rm_options.at(-1)"
                                          debounce="1000"
                                          />
                                      </div>
                                      <q-select v-else
                                        v-model="indicator.rm[ind_rm]"
                                        :options="message[lang].rm_options"
                                        :label="message[lang].rm_label"
                                        @update:model-value="selectRm(index,indicator)"
                                        :disable="!checkRules('edit', item)"
                                      />
                                    </div>
                                    <div class="col-md-3 col-xs-12 q-px-md">
                                      <!-- Статус -->
                                      <q-btn-dropdown size="sm" dense flat rounded color="primary" :label="showStatus(indicator, ind_rm)" :disable="!checkRules('status', item)">
                                        <q-list>
                                          <q-item clickable v-close-popup @click="statusRm(index, indicator, ind_rm, 'new')">
                                            <q-item-section>
                                              <q-item-label>{{message[lang].rm_new}}</q-item-label>
                                            </q-item-section>
                                          </q-item>

                                          <q-item clickable v-close-popup @click="statusRm(index, indicator, ind_rm, 'started')">
                                            <q-item-section>
                                              <q-item-label>{{message[lang].rm_started}}</q-item-label>
                                            </q-item-section>
                                          </q-item>

                                          <q-item clickable v-close-popup @click="statusRm(index, indicator, ind_rm, 'compleeted')">
                                            <q-item-section>
                                              <q-item-label>{{message[lang].rm_compleeted}}</q-item-label>
                                            </q-item-section>
                                          </q-item>
                                        </q-list>
                                      </q-btn-dropdown>
                                    </div>
                                    <div class="col-md-4 col-xs-10">
                                      <!-- Крайний срок  -->
                                      <q-input dense
                                               v-model="indicator.dln[ind_rm]"
                                               @update:model-value="saveIpr(index, null)"
                                               :hint="message[lang].rm_deadline"
                                               :readonly="!checkRules('edit', item)"
                                      >
                                        <template v-slot:prepend>
                                          <q-icon name="event" class="cursor-pointer">
                                            <q-popup-proxy v-if="checkRules('edit', item)" cover transition-show="scale" transition-hide="scale">
                                              <q-date
                                                v-model="indicator.dln[ind_rm]"
                                                mask="YYYY-MM-DD HH:mm"
                                                :locale="parent.appRegion === 'ru' ? parent.appLocale.ru : ''"
                                                @update:model-value="saveIpr(index, null)"
                                              >
                                                <div class="row items-center justify-end">
                                                  <q-btn v-close-popup :label="message[lang].em_close" color="primary" flat />
                                                </div>
                                              </q-date>
                                            </q-popup-proxy>
                                          </q-icon>
                                        </template>

                                        <template v-slot:append v-if="checkRules('edit', item)">
                                          <q-icon name="access_time" class="cursor-pointer">
                                            <q-popup-proxy cover transition-show="scale" transition-hide="scale">
                                              <q-time v-model="indicator.dln[ind_rm]" mask="YYYY-MM-DD HH:mm" format24h :locale="parent.appRegion === 'ru' ? parent.appLocale.ru : ''"
                                                      @update:model-value="saveIpr(index, null)"
                                              >
                                                <div class="row items-center justify-end">
                                                  <q-btn v-close-popup :label="message[lang].em_close" color="primary" flat />
                                                </div>
                                              </q-time>
                                            </q-popup-proxy>
                                          </q-icon>
                                        </template>
                                        <q-badge v-if="isRmdln(indicator.dln[ind_rm])"
                                                 rounded floating
                                                 color="warning"
                                                 :label="message[lang].rm_dln"/>
                                      </q-input>

                                    </div>
                                    <div class="col-2 text-right">
                                      <!-- Удаление РМ  -->
                                      <q-btn size="sm" round flat color="positive" icon="clear" @click="delRm(index, indicator, ind_rm)"/>
                                      <!-- Карточка РМ  -->
                                      <q-btn size="sm" round flat color="positive" icon="visibility" @click="viewCardRm(index, c_index, indicator, ind_rm)"/>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div class="q-pa-xs q-ma-xs">
                                <course-select @course_saved="course_saved" @courseView="courseView"
                                  v-bind:parent="parent"
                                  v-bind:lang="lang"
                                  v-bind:index="index"
                                  v-bind:c_index="c_index"
                                  v-bind:ind_index="ind"
                                  v-bind:courses="indicator.courses"
                                  v-bind:access="getRules(item)"
                                />
                              </div>

                          </div>
                          </div>
                        </q-card-section>
                      </q-card>
        <!--                <div class="col-12 q-my-sm">-->
        <!--                  <q-separator inset/>-->
        <!--                </div>-->


                      </div>
                      <!-- / Индикаторы-->

                    </div>
                  </q-expansion-item>
                </div>
                </div>


            </div>
          </div>


        </div>
      </q-card-section>

      <q-card-actions align="center" v-if="isMore">
          <q-btn :loading="isLoading" @click="getMoreIPR" size="lg" round flat color="accent" icon="keyboard_arrow_down" />
      </q-card-actions>
    </q-card>


    <q-dialog v-model="viewRMcard">
      <q-card style="max-width: 650px; background: #F2F6FF;">
        <q-card-section>
          <div>
            <div class="text-weight-bold q-my-md">{{message[lang].rm_rm}}</div>
            <q-separator/>
            <div class="row">
              <div class="col-4 text-positive">{{message[lang].rm_label}}</div>
              <div class="col-8 text-accent">{{objRMcard.rm}}</div>
              <div class="col-4 text-positive">{{message[lang].comp_name}}</div>
              <div class="col-8 text-accent">{{objRMcard.comp}}</div>
              <div class="col-4 text-positive">{{message[lang].add_ind}}</div>
              <div class="col-8 text-accent">{{objRMcard.ind}}</div>
              <div class="col-4 text-positive">{{message[lang].current_sc}}</div>
              <div class="col-8 text-accent">{{objRMcard.current_sc}}</div>
              <div class="col-4 text-positive">{{message[lang].target_sc}}</div>
              <div class="col-8 text-accent">{{objRMcard.target_sc}}</div>
              <div class="col-4 text-positive">{{message[lang].rm_deadline}}</div>
              <div class="col-8 text-accent">{{objRMcard.dln}}</div>
              <div class="col-4 text-positive">{{message[lang].rm_status}}</div>
              <div class="col-8 text-accent">{{objRMcard.status}}</div>
            </div>
          </div>
        </q-card-section>
        <q-card-section>
          <div>
            <Comments
                      v-bind:lang="lang"
                      v-bind:entity-id="objRMcard.hash"
                      v-bind:user_current="parent.user_current"
                      v-bind:parent="parent"
                      v-bind:mode="'list'"
            />
          </div>
        </q-card-section>
        <q-card-actions align="right">
          <q-btn flat :label="message[lang].selectcomps_finish" color="primary" v-close-popup />
        </q-card-actions>
      </q-card>
    </q-dialog>

  </div>
</template>

<script>
import dayjs from "dayjs";
import {messageObj} from "src/helpers/message";
import XLSX from "xlsx";
import { saveAs } from 'file-saver';
import courseSelect from './CourseSelect.vue';
import Comments from './Comments.vue';
import streamingFile from '/src/streamingFile';


export default {
  name: "IPR",
  props:{
    parent:Object,
    lang:String,
    back:String,
    uid:String,
    mode:String
  },
  data: function () {
    return {
      file_error:false,
      notifyed:{},
      objRMcard:'',
      viewRMcard:false,
      isMore:true,
      perPage:20,
      lastItemId:0,
      file_download_link:'',
      showInput:false,
      saving:false,
      isLoading:false,
      iprName:'',
      listIpr:'',
      showInd:[],
      loading:[],
      isStudentsLoading:false,
      competencies:null,
      scores:null,
      showCompNameInput:[],
      CompNameInput:'',
      showIndNameInput:[],
      IndNameInput:'',
      iprRules:'',
      message: messageObj,
    }
  },
  mounted: function(){
    let $this=this;
    $this.parent.isLoadingModule=false;
    console.log(':: IPR mounted ');
    this.isLoading=true;
    this.parent.appOptionGet('ipr_set').then((data) => {
      if(this.parent.isJSON(data.DETAIL_TEXT)) {
        this.iprRules = JSON.parse(data.DETAIL_TEXT);
        console.log(':: iprSettings mounted ipr=', this.ipr);
      }
      if(!this.iprRules || Object.keys(this.iprRules).length==0){
        this.iprRules={
            create:["M"],
            edit:["M","N"],
            read:["M","N","U"],
            status:["M","N","U"]
        };
      }
      this.parent.competenceGetList('competence').then(res=> {
        this.competencies = res;
        this.parent.competenceGetList('score').then(res=> {
          this.scores = res;
          this.parent.competenceGetList('ipr', '', this.uid, 99999999, this.perPage, this.uid).then(res => {
            this.listIpr = res.list;
            this.lastItemId = res.lastItemId;
            console.log(':: listIpr =', this.listIpr);
            this.isLoading = false;
            this.initIndexes();
          });
        });
      });
    });

  },
  computed: {
    scale_opts(){
      return this.scores.filter(el=>{return el.published === 'Y'})
    },

  },
  watch: {

  },

  methods: {
    addTextBlock(item){
      item.text_blocks = item.text_blocks?item.text_blocks:[];
      item.text_blocks.push({name:this.message[this.lang].text_block_name, value:'', ro:'N'});
    },
    sendFileSuccess(response) {
      console.log(':: sendFileSuccess response=', response)
      this.file_error = false;
      if(response && this.parent.isJSON(response)){
        let res = JSON.parse(response);
        if(res.error){
          this.sendFileFailed(response)
        }else {
          console.log('Файл успешно загружен res=', res);
          let index=res.index;
          let ipr=this.listIpr[index];
          ipr.files = ipr.files?ipr.files:[];
          if(!ipr.files.find(el=>{return el.name === res.name})){
            ipr.files.push(res);
          }
          console.log('ipr=', ipr);
          this.saveIpr(index, null);
        }
      }else{
        this.file_error = this.message[this.lang].upload_err;
      }
    },
    sendFileFailed(response){
      console.error('Ошибка загрузки файла response=', response);
      this.file_error = this.message[this.lang].upload_err;
    },
    isRmCustom(indicator, ind_rm){
      // console.log('::isRmCustom ind_rm=',ind_rm, indicator)
      return indicator.rm && indicator.rm[ind_rm] &&
        ( this.message[this.lang].rm_options.indexOf(indicator.rm[ind_rm]) === -1 ||
          this.message[this.lang].rm_options.at(-1) === indicator.rm[ind_rm]
        );
    },
    isImPersona(iprItem){
      let ret = 0;
      if(iprItem) {
        if (iprItem.person && iprItem.person.id == this.parent.user_current.ID)
          ret = 1;
        else if (iprItem.own_id == this.parent.user_current.ID)
          ret = 2;
      }
      return  ret;
    },
    noValidToPublish(item){
      let ret = !item.person || !item.manager || !item.mentor || !item.comps || Object.keys(item.comps).length == 0 ||
        !item.comps[0].indicators || Object.keys(item.comps[0].indicators).length == 0;
      // console.log('::isValidToPublish ret=', ret, item)
      return ret;
    },
    getMoreIPR(){
      this.isLoading = true;
      this.parent.competenceGetList('ipr', false, this.uid, this.lastItemId, this.perPage).then(res => {
        this.listIpr = this.listIpr.concat(res.list);
        // console.log('::getMoreIPR listIpr=',this.listIpr)
        this.lastItemId = res.lastItemId;
        this.isMore = res.total > 0;// - this.perPage > 0;
        this.isLoading = false;
        this.initIndexes();
      });
    },
    isRmdln(deadline){
      if(!deadline) return false;
      let dln = dayjs(deadline).unix();
      let now = dayjs().unix();
      // console.log('*** ',now,'>',dln, now>dln);
      return now>dln;
    },
    courseView(arg){
      // console.log('::goCourse arg=',arg);
      this.$emit('courseView', arg);
    },
    course_saved(arg){
      // console.log(':: course_saved arg=',arg);
      this.saveIpr(arg.index,null, arg.c_index, arg.courses, arg.ind_index)
    },
    getRules(item){
      let W = this.checkRules('edit',item)?'W':'';
      let R = W?'':this.checkRules('read',item)?'R':'';
      return R+W;
    },
    checkRules(action, iprItem){
      // console.log('::checkRules isModer=', this.parent.isModer,action, iprItem, this.iprRules)
      if(!this.iprRules[action]) return false;
      // Если проверяется опция создания, то iprItem отсутствует
      if( (action == 'create' || this.isImPersona(iprItem)) && this.iprRules[action].indexOf("U")>=0){
        return true;
      }
      // Если персона в ИПР (тот для кого ИПР)
      let isU = iprItem && iprItem.person ? iprItem.person.id == this.parent.user_current.ID : false;
      if (isU && this.iprRules[action].indexOf("U")>=0){
        return true;
      }
      //Если наставник в ИПР
      let isN = iprItem && iprItem.mentor ? iprItem.mentor.id == this.parent.user_current.ID : false;
      if(isN && this.iprRules[action].indexOf("N")>=0){
        return true;
      }
      //Если модератор или руководитель в ИПР
      let isModer = this.parent.isModer;
      let isManager = iprItem && iprItem.manager ? iprItem.manager.id == this.parent.user_current.ID : false;
      let isM = isModer || isManager;
      if(isM && this.iprRules[action].indexOf("M")>=0){
        return true;
      }
      return false;
    },
    showIndDescription(comp, value){
      let ret='';
      let ind=comp.scale.indicators.find(el=>{return el.value==value});
      if(ind) ret = ind.desc;
      return ret;
    },
    expandIprRow(index){
      this.showInd[index]=!this.showInd[index];
      for(let i in this.showInd){
        if(i!=index){
          this.showInd[i]=false;
        }
      }
    },
    showStatus(indicator, ind_rm){
      let ret=this.message[this.lang].rm_status;
      if(indicator.status && indicator.status[ind_rm]){
        ret = indicator.status[ind_rm];
        return this.message[this.lang]['rm_'+ret];
      }
      return ret;
    },
    statusRm(index, indicator, ind_rm, status){
      indicator.status=indicator.status?indicator.status:{};
      indicator.status[ind_rm]=status;
      // console.log('::statusRm indicator=',indicator);
      this.saveIpr(index, null);
    },
    delRm(index, indicator, ind_rm){
      let $this=this;
      $this.parent.title_confirm = this.message[this.lang].remove;
      $this.parent.message_confirm = this.message[this.lang].remove +' "'+indicator.rm[ind_rm]+'"';
      $this.parent.confirm=true;
      $this.parent.do_confirm = ()=> {
        indicator.rm.splice(ind_rm, 1);
        this.saveIpr(index, null);
        $this.parent.confirm=false;
      };
    },
    selectRm(index, indicator){
      if(indicator.rm){
        indicator.rm = indicator.rm.filter(Boolean);
        console.log(':---:selectRm indicator.rm= ',indicator.rm)
      }
      this.saveIpr(index, null);
    },
    addRM(indicator){
      if(!indicator.rm){
        indicator.rm=[];
      }
      if(!indicator.status){
        indicator.status={};
      }
      if(!indicator.dln || typeof(indicator.dln)!='object' ){
        indicator.dln={};
      }
      indicator.rm.push('');
      indicator.status[indicator.rm.length-1]='new';
      indicator.dln[indicator.rm.length-1]='';
    },
    viewCardRm(index, c_index, indicator, ind_rm){
      let format = this.parent.appRegion  == 'ru' ? 'DD.MM.YYYY HH:mm' : 'YYYY-MM-DD HH:mm';
      this.objRMcard={
        rm:indicator.rm[ind_rm],
        comp:this.listIpr[index].comps[c_index].name,
        ind:indicator.value,
        current_sc:indicator.current_sc?indicator.current_sc.name:"---",
        target_sc:indicator.target_sc?indicator.target_sc.name:"---",
        dln:indicator.dln && indicator.dln[ind_rm] ? dayjs(indicator.dln[ind_rm]).format(format) : '',
        status: indicator.status && indicator.status[ind_rm]?this.message[this.lang]['rm_'+indicator.status[ind_rm]]:'',
        hash: this.parent.myEx(this.listIpr[index].comps[c_index].name+indicator.rm[ind_rm]+indicator.value)
      };
      console.log('::viewCardRm ',this.objRMcard)
      this.viewRMcard=true;
    },
    addComps(index){
      // console.log(':: collapseList ',this.show);
      let $this = this;
      // eslint-disable-next-line vue/no-mutating-props
      this.parent.modal_list=[];
      $this.parent.model=null;
      $this.parent.modal_list=[ {value: '0', text:$this.message[$this.lang].hand_made, id: '0'}];
      for(let item of this.competencies) {
        if(item.published==='Y') {
          $this.parent.modal_list.push(
            {value: item.id, text: item.name, name:item.name, id: item.id}
          );
        }
      }

      $this.parent.model=null;
      $this.parent.modal_obj=index;
      $this.parent.modal_flt='';
      $this.parent.modal_list_single=false;
      $this.parent.modal_list_label = $this.message[$this.lang].selectcomps_label;
      $this.parent.modal_title = $this.message[$this.lang].selectcomps_title;
      $this.parent.modal_message = $this.message[$this.lang].selectcomp_message;
      $this.parent.modal_ok = $this.message[$this.lang].selectcomps_add;
      $this.parent.modal_cancel = $this.message[$this.lang].selectcomps_finish;
      $this.parent.modal_filterFn = null;
      $this.parent.okModal =  ()=> {
        let index=$this.parent.modal_obj;

        console.log('::okModal competencies=', $this.competencies);

        for(let i in $this.parent.modal_list_selected ) {
          let comp_id = parseInt($this.parent.modal_list_selected[i]);
          let itemComp = $this.competencies.find(element => {return element.id == comp_id});
          console.log(':: comp_id=',comp_id, itemComp)

          if (itemComp && !$this.listIpr[index].comps.find(element => {return element.id == itemComp.id})) {
            if(itemComp.indicators){
              for(let i in itemComp.indicators){
                itemComp.indicators[i].dln = {};
              }
            }
            $this.listIpr[index].comps.push(itemComp);
          }
          if(comp_id > 0) {
            $this.saveIpr(index, null)
          }else{
            $this.showCompNameInput[index]=true;
          }
        }
        $this.parent.hideModal();
      };
      this.parent.showModal();
    },
    indDel(index, c_index, i_index){
      let $this = this;
      $this.parent.modal_title=$this.message[$this.lang].attention;
      $this.parent.modal_message=$this.message[$this.lang].del_ind;
      $this.parent.modal_obj='';
      $this.parent.modal_ok = $this.message[$this.lang].delete;
      $this.parent.modal_filterFn = null;
      $this.parent.okModal =  ()=>{
        $this.listIpr[index].comps[c_index].indicators.splice(i_index,1);
        $this.saveIpr(index, null);
        $this.parent.hideModal();
      };
      BX24.scrollParentWindow (0);
      this.parent.showModal();
    },
    add1Ind(index,c_index, IndNameInput){
      let itemInd={
        value:IndNameInput,
        rm:'',
        status:{},
        dln:{}
      };
      this.listIpr[index].comps[c_index].indicators.push(itemInd);
      this.saveIpr(index, null);
    },
    add1Comp(index, CompNameInput){
      let itemComp={
        name:CompNameInput,
        indicators:[],
        published:'N',
        id:''
      };
      this.listIpr[index].comps.push(itemComp);
      this.saveIpr(index, null);
    },
    delComp(index, c_index){
      // console.log('::delComp ',this.listIpr[index].comps[c_index] )
      let $this = this;
      $this.parent.modal_title=$this.message[$this.lang].attention;
      $this.parent.modal_message=$this.message[$this.lang].del_competence+' "'+this.listIpr[index].comps[c_index].name+'"';
      $this.parent.modal_obj='';
      $this.parent.modal_list=null;
      $this.parent.modal_ok = $this.message[$this.lang].delete;
      $this.parent.modal_filterFn = null;
      $this.parent.okModal =  ()=>{
        $this.listIpr[index].comps.splice(c_index,1);
        $this.saveIpr(index, null);
        $this.parent.hideModal();
      };
      BX24.scrollParentWindow (0);
      this.parent.showModal();
    },
    delUser(index, who){
      this.listIpr[index][who]='';
      this.saveIpr(index, null);
    },
    addUser(index, who){
      // console.log(':: collapseList ',this.show);
      let $this = this;
      // eslint-disable-next-line vue/no-mutating-props
      this.parent.modal_list=[];
      this.isStudentsLoading=true;
      $this.parent.model=null;
      this.parent.getUsersExtra('activeOnly').then(users=>{
        $this.isStudentsLoading=false;
        $this.parent.modal_list=[];
        for(let i in users) {
          let item = users[i];
          $this.parent.modal_list.push(
            {value: item.id, text: item.name, name:item.name, id: item.id}
          );
        }
        $this.parent.model=null;
        $this.parent.modal_obj={index:index, who:who};
        $this.parent.modal_flt='';
        $this.parent.modal_list_single = true;
        $this.parent.modal_list_label = $this.message[$this.lang].selectusers_label;
        $this.parent.modal_title = $this.message[$this.lang].selectusers_title;
        $this.parent.modal_message = $this.message[$this.lang].selectuser_message;
        $this.parent.modal_ok = $this.message[$this.lang].selectusers_add;
        $this.parent.modal_cancel = $this.message[$this.lang].selectusers_finish;

        //Поиск юзера по порталу
        $this.parent.modal_filterFn= function (needle){
          return new Promise(resolve => {
            let filter ={
              "%PROPERTY_last_name":`%${needle}%`,
            };
            $this.parent.getUsersExtra('activeOnly', filter).then(users=>{
              let list = [];
              if(users && users.length>0) {
                for (let i in users) {
                  let item = users[i];
                  list.push({value: item.id, text: item.name, name:item.name, id: item.id});
                }
              }else{
                list.push({text: $this.parent.message[$this.lang].not_found, value:0, disable:true});
              }
              console.log(':: list=',list)
              resolve(list);
            })
          });
        };

        $this.parent.okModal =  ()=> {
          let index=$this.parent.modal_obj.index;
          let who=$this.parent.modal_obj.who;
          // console.log('::okModal index=',index,$this.listIpr[index])
          console.log('::okModal modal_list_selected=',$this.parent.modal_list_selected)
          let user_id = parseInt($this.parent.modal_list_selected[0]);
          let itemUser = $this.parent.modal_list.find((element, index, array) => {
            if (element.id == user_id) return true; else return false
          }, $this);

          $this.parent.getSubAccounts([itemUser]).then(res=>{
            if(res && res.length>0){
                $this.parent.modal_list_label = $this.message[$this.lang].sub_add;
                $this.parent.model=null;
                $this.parent.modal_filterFn= null;
                $this.parent.okModal = ()=>{
                  let user_id = parseInt($this.parent.modal_list_selected[0]);
                  let itemUser = res.find(el => { return el.id == user_id });
                  $this.listIpr[index][who]=itemUser;
                  $this.saveIpr(index, null)
                  $this.parent.hideModal();
                }
                $this.parent.modal_list=[];
                for(let i in res) {
                  let item = res[i];
                  $this.parent.modal_list.push(
                    {value: item.value, text: item.text, name:item.name, id: item.id}
                  );
                }
                // $this.parent.showModal();
                $this.parent.$refs.modal_list.refresh();
                $this.parent.$refs.modal_list.showPopup();
            }else{
              $this.listIpr[index][who]=itemUser;
              $this.saveIpr(index, null)
              $this.parent.hideModal();
            }
          });
          // $this.listIpr[index][who]=itemUser;
          // $this.saveIpr(index, null)

          // $this.parent.hideModal();
        };
        this.parent.showModal();

      });
    },
    initIndexes(){
      this.showInd = Array(this.listIpr.length).fill(false);
      this.loading = Array(this.listIpr.length).fill(false);
      this.showIndNameInput = Array(this.listIpr.length).fill(false);
      this.showCompNameInput = Array(this.listIpr.length).fill(false);
    },
    iprDelete(index){
      let $this=this;
      $this.parent.do_confirm = ()=>{
        console.log(':: delIpr id=',this.listIpr[index].id)
        this.saving = true;
        this.parent.competenceDelete(this.listIpr[index].id).then(res=>{
          console.log(':: delIpr res=',res)
          this.listIpr.splice(index,1);
          this.saving = false;
          this.initIndexes();
          $this.parent.confirm=false;
        })
      };
      $this.parent.title_confirm = this.message[this.lang].remove;
      $this.parent.message_confirm = this.message[this.lang].remove +' "'+this.listIpr[index].name+'"';
      $this.parent.confirm=true;
    },
    addIpr(type){
      if(!this.listIpr){
        this.listIpr=[];
      }
      this.saving = true;
      let new_item={name:this.iprName, published:'N'}
      new_item.own_id=this.parent.user_current.ID; //автор
      new_item.person=''; //Персона (для кого)
      new_item.comps=[]; //компетенции
      new_item.manager=''; //руководитель
      new_item.mentor=''; //наставник
      new_item.status='new'; //inproc, completed
      this.parent.competenceSave('ipr', "-|"+this.uid+"|-", new_item).then(res=>{
        new_item.id=res.result;
        this.listIpr.splice(0, 0, new_item)
        this.iprName = '';
        this.saving = false;
        this.initIndexes();
      });
    },
    /**
     * Проверяет, что все РМ имеют статус выполнено - "compleeted"
     */
    isAllRMCompleted(ipr){
      let ret=true;
      if(ipr.comps && ipr.comps.length>0){
        for(let item of ipr.comps){
          // console.log(':-: comp=',item)
          //Индикаторы а в них РМ
          if(item.indicators && item.indicators.length>0){
            for(let ind of item.indicators){
              // console.log(':--: ind=',ind)
              if(ind.status && ind.status.length>0){
                for(let s in ind.status){
                  let status = ind.status[s];
                  // console.log(':---: status=',status)
                  if(status!='compleeted'){
                    return false; //есть не завершенное РМ
                  }
                }
              }
              //Курсы как РМ
              if(ind.courses && ind.courses.length>0){
                for(let course of ind.courses){
                  // console.log(':--: course=',course.status, course)
                  if(course.status!='compleeted'){
                    return false; //есть не завершенный курс
                  }
                }
              }
            }
          }

        }
      }else{
        ret = false;
      }
      return ret;
    },
    saveIpr(index, resetSacle, c_index, courses, ind_index){
      let ipr = this.listIpr[index];
      let publish = ipr.published === 'Y';
      console.log(':!!!!:saveIpr publish, ipr=',publish, ipr, c_index, courses)
      if(resetSacle){
        if(ipr.comps && ipr.comps[c_index] && ipr.comps[c_index].indicators) {
          for (let i in ipr.comps[c_index].indicators) {
            ipr.comps[c_index].indicators[i].target_sc = null;
          }
        }
      }
      if(courses && ipr.comps && ipr.comps[c_index] && ipr.comps[c_index].indicators){
        ipr.comps[c_index].indicators[ind_index].courses = courses;
      }

      let name=ipr.name;
      // Если ИПР опубликован или на согласовании в поле NAME запишем id участников
      // для поиска в ЛК
      // юзер может быть как наставник(mentor), руководитель(manager) так и как сотрудник(persona)
      let json_name={person:ipr.person.id, mentor:ipr.mentor.id, manager:ipr.manager.id,}
      // name = JSON.stringify(json_name)
      // |сотрудник|наставник|руководитель|автор ипр|
      name= "|"+ipr.person.id+"|"+ipr.mentor.id+"|"+ipr.manager.id+"|"+ipr.own_id+"|";

      if(publish){
        this.personaNotify(ipr.person.id);
      }

      //
      this.saving = true;
      // console.log('::saveIpr name=',name, ipr)
      this.parent.competenceSave('ipr', name, ipr, ipr.id, publish).then(res=>{
        // console.log('::saveIpr res=',res)
        this.saving = false;

        if(this.isAllRMCompleted(ipr)){
          console.log(':!!!:saveIpr ИПР выполнен')
          //Баллы за выполненный ИПР
          this.parent.savePoints('P', ipr.id, null, ipr.person.id);
        }

        this.$emit('iprChange', publish);
      });
    },
    personaNotify(uid){
      console.log('::personaNotify ', uid, this.notifyed)
      // Уведомления
      if(!this.notifyed || !this.notifyed[uid]) {
        this.parent.isNoteSet([uid], "ipr").then(checks => {
          if (Object.keys(checks).length > 0) {
            let message = this.message[this.lang].ipr_note
            // let arBatch2 = {};
            // for (let uid of checks) {
            //   arBatch2['note_' + uid] = ['im.notify', {to: uid, message: message, type: 'SYSTEM'}];
            // }
            // this.parent.callBatch(arBatch2);
            this.parent.notifyLK(uid, message);
            console.log('::personaNotify done')
            if(!this.notifyed){
              this.notifyed={uid:true};
            }else {
              this.notifyed[uid] = true;
            }
          }
        });
      }
    },
    export1Ipr(iprItem) {
      // console.log(':: getFileLinkPureB64 navigator.userAgent=', navigator.userAgent)
      let $this=this;
      let person = iprItem.person ? `-${iprItem.person.id}-${iprItem.person.name}`:'';
      let fname = `ipr${person}.xlsx`;
      // Изменить курсор на ожидание
      document.body.style.cursor = 'wait';
      this.iprToXls(iprItem).then(wbout => {
        // if (navigator.userAgent.search(/BitrixDesktop/g) >= 0) {
        //   $this.parent.getFileLinkPureB64(fname, wbout).then(link => {
        //     $this.file_download_link = link;
        //   });
        // } else {
          saveAs(new Blob([$this.parent.s2ab(wbout)],
            {type: "application/octet-stream"}), fname);
        // }
        // Вернуть курсор в нормальное состояние
        document.body.style.cursor = 'auto';
      }, err => {
        console.error(err);
        document.body.style.cursor = 'auto';
        $this.parent.notify_title = this.message[this.lang].attention;
        $this.parent.notify_text = this.message[this.lang].alert_message;
        $this.parent.notify_warning = true;
        $this.parent.notify = true;
      });
    },
    iprToXls(iprItem){
      console.log('::export1Ipr ',iprItem);
      let $this=this;
      let format = this.parent.appRegion  == 'ru' ? 'DD.MM.YYYY HH:mm' : 'YYYY-MM-DD HH:mm';
      let fileType='binary';
      // if(navigator.userAgent.search(/BitrixDesktop/g)>=0){
      //   fileType='base64'
      // }
      return new Promise(function(resolve, reject) {
        let forXlsx = [];

        let header = [
          $this.message[$this.lang].selectcomps_label, //A
          $this.message[$this.lang].add_ind,           //B
          $this.message[$this.lang].current_sc,        //C
          $this.message[$this.lang].description,       //D
          $this.message[$this.lang].target_sc,         //E
          $this.message[$this.lang].description,       //F
          $this.message[$this.lang].rm_label,          //G
          $this.message[$this.lang].rm_status,         //H
          $this.message[$this.lang].rm_deadline,       //I
        ];
        let wscols = Array(9).fill({wch: 50});

        let row = {
          A: $this.message[$this.lang].select_person,
          B: iprItem.person?iprItem.person.name:'',
        };
        forXlsx.push(row);
        row = {
          A: $this.message[$this.lang].select_mentor,
          B: iprItem.mentor?iprItem.mentor.name:'',
        };
        forXlsx.push(row);
        row = {
          A: $this.message[$this.lang].select_manager,
          B: iprItem.manager?iprItem.manager.name:'',
        };
        forXlsx.push(row);
        forXlsx.push({A:''});

        //Компетенции
        if(iprItem.comps && iprItem.comps.length>0){
          for(let item of iprItem.comps){
            row = {
              A: item.name,
              B:'',
              C:'',
              D:'',
              E:'',
              F:'',
              G:'',
              H:'',
              I:''
            };
            forXlsx.push(row);
            //
            console.log('::item.indicators=',item.indicators, item.indicators.length)
            if(!!item.indicators && item.indicators.length>0){

              for(let j in item.indicators){
                let indicator = item.indicators[j];
                console.log('::indicator= ',indicator)
                row={
                  A:'',
                  B: indicator.value
                };

                if(indicator.current_sc){
                  row.C = `${indicator.current_sc.name}(${indicator.current_sc.value})`;
                  row.D = indicator.current_sc.desc;
                }else{
                  row.C = '';
                  row.D = '';
                }

                if(indicator.target_sc){
                  row.E = `${indicator.target_sc.name}(${indicator.target_sc.value})`;
                  row.F = indicator.target_sc.desc;
                }else{
                  row.E = '';
                  row.F = '';
                }
                forXlsx.push(row);
                //--
                if(indicator.rm && indicator.rm.length>0) {
                  for (let i in indicator.rm) {
                    row = {
                      A: '',B:'',C:'',D:'',E:'',F:'',
                      G: indicator.rm[i],
                      H: indicator.status && indicator.status[i] ? $this.message[$this.lang]['rm_'+indicator.status[i]] : '',
                      I: indicator.dln && indicator.dln[i] ? dayjs(indicator.dln[i]).format(format) : ''
                    };
                    forXlsx.push(row);
                    //

                  }
                }
              }

            }
          }
          let now = dayjs().format("DD.MM.YY HH:mm");
          let sheetName = $this.message[$this.lang].ipr_title;
          let title = iprItem.name;
          let Head = [];
          Head.push([now]);
          Head.push([title]);
          Head.push([[]]);
          Head.push(header);
          Head.push([[]]);

          let ws = XLSX.utils.aoa_to_sheet(Head);
          ws['!cols'] = wscols;

          XLSX.utils.sheet_add_json(ws, forXlsx, {
            skipHeader: true,
            origin: 4
          });


          let wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, sheetName);
          let wbout = XLSX.write(wb, {bookType: 'xlsx', type: fileType});

          resolve(wbout);

        }else{
          console.error(':: no data')
          reject('empty')
        }


      });

    },


  },
  components: {
    courseSelect,
    Comments,
    streamingFile
  }
}
</script>

<style scoped>

</style>
