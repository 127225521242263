<template>
  <div style="padding-left: 15px; padding-bottom: 100px; padding-right: 15px">
    <div class="row items-center justify-between q-mb-md" style="margin-top: -10px">
      <div class="col-5 text-left q-mb-2">
        <a href="#" class="body-2" @click="got_back">
<!--          <q-img src="~assets/Back/arrow/left.svg" style="height: 24px; width: 24px" />-->
          <q-btn color="positive" no-caps flat round icon="transit_enterexit" @click="$emit('goback', goback);"/>
          {{ message[lang].goback }}
        </a>
        <div class="h1">
          {{ message[lang].title }}
        </div>
      </div>
      <div v-if="!showForm" class="col-4 text-right q-gutter-md" style="padding-right: 10px">
        <!-- <q-btn padding="xs" size="lg" flat round color="secondary" icon="add_circle_outline" @click="toggleForm();showForm = true;"><q-tooltip class="bg-primary h-tt">{{message[lang].add}}</q-tooltip></q-btn> -->
        <q-btn v-if="isChecked || doChecked" :loading="exportStarted" padding="xs" size="lg" flat round color="positive" icon="summarize" @click="reportSomeAssessment()"><q-tooltip class="bg-primary h-tt">{{message[lang].report}}</q-tooltip></q-btn>
        <q-btn v-if="isChecked || doChecked" :loading="exportStarted" padding="xs" size="lg" flat round color="positive" icon="file_download" @click="exportSomeAssessment()"><q-tooltip class="bg-primary h-tt">{{message[lang].export}}</q-tooltip></q-btn>
        <q-btn v-if="isChecked || doChecked" padding="xs" size="lg" flat round color="positive" icon="visibility" @click="accCheckedItems()"><q-tooltip class="bg-primary h-tt">{{message[lang].acc1}}</q-tooltip></q-btn>
        <q-btn v-if="isChecked || doChecked" padding="xs" size="lg" flat round color="positive" icon="clear" @click="delSomeAssessment(null)"><q-tooltip class="bg-primary h-tt">{{message[lang].deleteSome}}</q-tooltip></q-btn>
      </div>
    </div>
    <!-- Форма добавления - компетенции - результаты -->
    <div v-if="showForm" class="q-gutter-y-sm">
      <!--      <q-card>-->
      <q-tabs v-model="tab" dense class="text-positive" active-color="primary" indicator-color="primary" align="left" narrow-indicator>
        <q-tab name="assessment" :label="message[lang].assessment" />
        <q-tab name="competences" :label="message[lang].competences" />
        <q-tab name="chart" :label="message[lang].chart" @click="initTable" :disable="!assessment.experts || assessment.experts.length==0"/>
      </q-tabs>

      <q-separator />

      <q-tab-panels v-model="tab" style="background: #f2f6ff">
        <!-- Настройки -->
        <q-tab-panel name="assessment">
          <!-- <div class="text-h6">{{ message[lang].assessment }}</div> -->
          <div class="row">
            <div class="col-12 q-gutter-y-sm">

              <div class="row">
                <div class="col-6 q-gutter-y-sm">
                  <!--  Название      -->
                  <div class="row items-center">
                    <div class="col-12 text-positive h4">{{ message[lang].name }} <span class="text-warning" style="marging-top: -10px">*</span></div>
                    <div class="col-12">
                      <q-input
                        :label="message[lang].plc1"
                        v-model="assessment.name"
                        borderless
                        style="padding-left: 10px; border-radius: 25px !important;"
                        class="body-0 bg-negative"
                        :rules="[(val) => val.length > 0 || message[lang].requaried_field]"
                        @update:model-value="isNoFormValid"
                        debounce="500"
                      />
                    </div>
                  </div>
                  <!--  ОПисание      -->
                  <div class="row items-center">
                    <div class="col-12 h4  positive h4">{{ message[lang].description }}</div>
                    <div class="col-12">
                      <q-input
                        :label="message[lang].plc1"
                        v-model="assessment.description"
                        borderless
                        type="textarea"
                        style="padding-left: 10px; border-radius: 25px !important;"
                        class="body-0 bg-negative"
                        :rules="[(val) => val.length > 0 || message[lang].requaried_field]"
                        @update:model-value="isNoFormValid"
                        debounce="500"
                      />
                    </div>
                  </div>
                  <div class="row items-center">
                    <div class="col-12 h4 positive h5 text-right">
                      <!--Опубликовать в личном кабинете оцениваемого-->
                      <q-toggle
                        v-model="assessment.flags.acc"
                        false-value="N"
                        true-value="Y"
                        color="primary"
                        :label="message[lang].acc"
                        left-label
                      />
                      <!-- Скрывать данные оценивающих-->
                      <q-toggle
                        v-model="assessment.flags.hide_fio"
                        false-value="N"
                        true-value="Y"
                        color="primary"
                        :label="message[lang].hide_fio"
                        left-label
                      />
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="text-center">
                    <q-date range v-model="startFinish" :title="message[lang].start_finish" :subtitle="showTitleDate" :locale="parent.appRegion === 'ru' ? parent.appLocale.ru : ''" mask="YYYY-MM-DD" />
                  </div>
                </div>
              </div>

<!--              <div class="row items-center q-gutter-y-md">-->
                <div class="row q-gutter-y-md q-pa-md q-my-md" v-for="(elem, ind) in assessment.list" :key="ind" color="secondary" text-color="negative" style="border: #dddddd solid 1px; border-radius: 25px;">
                  <!-- Оцениваемый       -->
                  <div class="row">
                    <div class="col-12 text-positive h4">
                      {{ message[lang].add_persona }}
                    </div>
                  </div>

                  <div class="fit row content-center">
                    <div><q-btn class="q-mr-sm" elevated rounded color="primary" :label="message[lang].new" @click="personaAdd(ind)" /></div>
                    <div><q-btn :disable="isDepartmentsLoading" elevated rounded color="primary" :label="message[lang].departments" @click="departmentsAdd(ind)" /></div>
                  </div>
                  <div class="row items-center">
                    <div class="q-gutter-xs q-mt-sm" v-if="elem.persona && Object.keys(elem.persona).length > 0">
                      <q-chip v-for="(item, index) in elem.persona" :key="index" color="primary" text-color="negative" removable @remove="delPersona(index, ind)" size="md" icon="account_circle">
                        {{ item.name }}
                      </q-chip>
                    </div>
                  </div>
                  <!--  Флаги      -->
<!--                  <div v-if="assessment.flags.acc" class="row items-center" style="width:100%">-->
<!--                    <div class="col-12 positive h4 h4">{{ message[lang].comment }}</div>-->
<!--                    <div class="col-6">-->
<!--                      <q-input-->
<!--                        v-model="assessment.flags.com"-->
<!--                        borderless-->
<!--                        autogrow-->
<!--                        type="textarea"-->
<!--                        style="padding-left: 10px; border-radius: 25px !important; background-color: white"-->
<!--                        class="body-0"-->
<!--                      />-->
<!--                    </div>-->
<!--                  </div>-->

                  <q-separator inset />

                  <!-- Список оценщиков       -->
                  <div class="fit row">
                    <div class="col-12 text-positive h4 text-left">
                      {{ message[lang].add_experts }}
                    </div>
                  </div>

                  <div class="fit row content-center">
                    <div><q-btn class="q-mr-sm" :disable="!elem.persona || Object.keys(elem.persona).length == 0" elevated rounded color="secondary" :label="message[lang].new" @click="expertsAdd(ind)" /></div>
                    <div><q-btn class="q-mr-sm" :loading="isCoworkersLoading" :disable="!elem.persona || Object.keys(elem.persona).length == 0" elevated rounded color="secondary" :label="message[lang].coworkers" @click="coworkersAdd(ind)" /></div>
                    <div><q-btn class="q-mr-sm" :loading="isSlavesLoading" :disable="!elem.persona || Object.keys(elem.persona).length == 0" elevated rounded color="secondary" :label="message[lang].slaves" @click="slavesAdd(ind)" /></div>
                    <div><q-btn :loading="isChiefLoading" :disable="!elem.persona || Object.keys(elem.persona).length == 0" elevated rounded color="secondary" :label="message[lang].chief" @click="chiefAdd(ind)" /></div>
                  </div>

                  <div class="fit row items-center">
                    <div class="col-12 q-gutter-xs q-mt-sm" v-if="elem.experts && Object.keys(elem.experts).length > 0">
                      <q-chip v-for="(item, index) in elem.experts" :key="index" :color="item.color" rounded removable @remove="delExpert(index, ind)" size="md" icon="account_circle">
                        {{ item.name }}
                      </q-chip>
                    </div>
                  </div>

                  <div v-if="ind>0" class="fit row justify-end">
                    <q-btn icon="delete" rounded flat @click="delFromList(ind)" color="warning" :label="message[lang].remove" />
                  </div>

                </div>

                <!-- Кнопка добавления следующей оценки -->
                <div class="row items-center">
                  <q-btn icon="add" rounded flat @click="addList()" color="primary" :label="message[lang].add_section"/>
                </div>
<!--              </div>-->

            </div>

          </div>

          <div class="q-py-md" v-if="assessment.list && assessment.list[0].persona && assessment.list[0].persona.length>0">
            <div class="text-positive  h4 q-my-md">{{message[lang].common_list}}</div>
            <q-list  bordered separator padding style="border-radius: 25px">
                <q-item  v-ripple>
                  <q-item-section>
                    <div class="row">
                      <div class="col-6 text-positive  h4">{{message[lang].personas}}</div>
                      <div class="col-6 text-positive  h4">{{message[lang].experts}}</div>
                    </div>
                  </q-item-section>
                </q-item>
              <q-separator/>
                <div v-for="(item,index) in assessment.list" :key="index">
                <q-item  v-ripple>
                  <q-item-section>
                    <div class="row">
                      <div class="col-6" style="border-right: #ddd 1px solid">
                        <div class="row">
                          <div class="col-10 text-positive h4 offset-1" v-for="(persona,p_index) in item.persona" :key="p_index">
                            {{persona.name}}
                          </div>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="row">
                          <div class="col-10 positive h4 offset-1" v-for="(expert,p_index) in item.experts" :key="p_index">
                            {{expert.name}}
                          </div>
                        </div>
                      </div>
                    </div>
                  </q-item-section>
                </q-item>
                <q-separator inset/>
              </div>
            </q-list>
          </div>

          <div class="text-right q-my-lg q-gutter-x-md">
            <div class="text-center text-warning vertical-middle" v-if="alert_message">{{ alert_message }}</div>

            <!-- Кнопки опубликовать и черновик -->
            <q-btn
              :loading="isLoading"
              :disable="!!alert_message"
              elevated
              rounded
              color="primary"
              :label="message[lang].publish"
              @click="
                assessment.active = 'Y';
                assessmentCreate();
              "
            />
            <q-btn
              :disable="!isDraftValid"
              elevated
              rounded
              color="primary"
              :label="message[lang].draft"
              @click="
                assessment.active = 'N';
                assessmentCreate();
              "
            />

            <q-btn :disabled="isLoading" elevated rounded color="warning" :label="message[lang].reset" @click="toggleForm" />
          </div>
        </q-tab-panel>
        <!--компетенции-->
        <q-tab-panel name="competences">
          <q-expansion-item
            ref="comp_ref"
            icon="edit"
            :label="message[lang].comps_add"
            header-class="bg-secondary text-negative"
            expand-icon-class="text-negative"
            class="shadow-1 overflow-hidden"
            style="border-radius: 25px"
          >
            <cats-edit
              @competences_saved="competences_saved"
              v-if="parent && showComps"
              v-bind:title="''"
              v-bind:title_cat="message[lang].competences"
              v-bind:title_subcat="message[lang].scores"
              v-bind:pCompetence="assessment.competences[competenceIndex]"
              v-bind:lang="lang"
              v-bind:parent="parent"
              v-bind:thisparent="this"
            ></cats-edit>
          </q-expansion-item>
          <!-- Таблица -->
          <div class="q-py-md" v-if="assessment.competences && assessment.competences.length>0">
            <div class="text-positive h4 q-my-md">{{message[lang].comps_added}}</div>
            <q-list  bordered separator padding style="border-radius: 25px">
              <div v-for="(item,index) in assessment.competences" :key="index">
                <q-item  v-ripple>
                  <q-item-section>
                    <q-item-label class="text-positive  h4 q-my-sm">{{item.name}}</q-item-label>
                    <q-separator spaced inset="item" />

                    <div class="row">
                      <div class="col-10 offset-1" v-for="(subcat,subcat_index) in item.subcats" :key="subcat_index">
<!--                        {{subcat_index}}-->
                        {{showCatText(item, subcat_index)}}
                        <div class="row" v-for="score in subcat" :key="score.value">
                          <div class="col-6 offset-6">{{score.text}}</div>
                        </div>
                        <q-separator spaced inset="item" />
                      </div>
                    </div>
                  </q-item-section>
                  <q-item-section side top>
                    <q-item-label caption>
                      <q-btn round flat size="sm" color="positive" icon="edit" @click="editComp(index)"/>
                      <q-btn round flat size="sm" color="positive" icon="clear" @click="delComp(index)"/>
                    </q-item-label>
                  </q-item-section>
                </q-item>
                <q-separator spaced inset color="secondary" />
              </div>

            </q-list>
          </div>
        </q-tab-panel>

        <!-- Результаты -->
        <q-tab-panel name="chart">
          <div class="q-gutter-y-sm">
            <div class="text-positive h3">"{{ assessment.name }}"</div>
            <div v-html="assessment.description"></div>

            <div class="row items-center">
              <div class="col-12 text-positive h4">{{ message[lang].persona }}:</div>
              <div class="col-6">
                <q-avatar v-if="assessment.persona[0] && assessment.persona[0].photo">
                  <q-img :src="assessment.persona[0].photo" />
                </q-avatar>
                <q-avatar v-else color="grey" text-color="white" icon="perm_identity" />
                <a v-if="assessment.persona[0]" class="text-primary q-ml-md" :href="'https://' + parent.portal + '/company/personal/user/' + assessment.persona[0].id + '/'" target="_blank">
                  {{ assessment.persona[0].name }}
                </a>
                <a v-else class="text-primary q-ml-md" :href="'https://' + parent.portal + '/company/personal/user/' + assessment.persona.id + '/'" target="_blank">
                  {{ assessment.persona.name }}
                </a>
              </div>

            </div>

            <div class="row items-center justify-between">
              <div class="col-3 h4">
                {{ message[lang].scores }}
              </div>
              <div class="col-2 h4 text-right">

                <q-btn-dropdown v-if="listForDelete.length>1" elevated rounded color="warning" :label="message[lang].clear_scores">
                  <q-list>
                    <q-item v-for="(item,index) in listForDelete" :key="index" clickable v-close-popup @click="clearScores(item)">
                      <q-item-section>
                        <q-item-label>{{item.label}}</q-item-label>
                      </q-item-section>
                    </q-item>
                  </q-list>
                </q-btn-dropdown>

              </div>
            </div>

            <div class="q-pa-md">
              <q-table v-if="columns && columns.length>0"
                       flat
                       :title="message[lang].scores"
                       :rows="rows"
                       :columns="columns"
                       row-key="num"
                       separator="cell"
                       v-model:pagination="pagination"
                       :rows-per-page-label="message[lang].rows_per_page"
              >
                <template v-slot:pagination="scope">
                  <q-btn
                    v-if="scope.pagesNumber > 2"
                    icon="first_page"
                    color="grey-8"
                    round
                    dense
                    flat
                    :disable="scope.isFirstPage"
                    @click="scope.firstPage"
                  />

                  <q-btn
                    icon="chevron_left"
                    color="grey-8"
                    round
                    dense
                    flat
                    :disable="scope.isFirstPage"
                    @click="scope.prevPage"
                  />

                  <q-btn
                    icon="chevron_right"
                    color="grey-8"
                    round
                    dense
                    flat
                    :disable="scope.isLastPage"
                    @click="scope.nextPage"
                  />

                  <q-btn
                    v-if="scope.pagesNumber > 2"
                    icon="last_page"
                    color="grey-8"
                    round
                    dense
                    flat
                    :disable="scope.isLastPage"
                    @click="scope.lastPage"
                  />
                </template>
              </q-table>
            </div>

            <div class="text-positive h5">{{ message[lang].average1 }} - {{ message[lang].average_ }}</div>

            <div class="q-gutter-sm" v-if="legend" style="border: #eee solid 0.5px; border-radius: 5px">
              <div class="h5 mt-3">{{ message[lang].legend }}:</div>
              <div class="row positive h4" v-for="item in legend" :key="item" style="border-bottom: #eee solid 0.5px">
                <div class="col-12">{{ item }}</div>
              </div>
            </div>

            <!-- Выбор компетенции для диаграммы -->
            <div class="row items-center q-my-lg">
              <div class="col-2">
                  <q-btn-dropdown rounded color="primary" :label="message[lang].competencies">
                    <q-list>
                      <q-item v-for="(item,index) in itemCompNames(assessment)" :key="index" clickable v-close-popup @click="initChart(index, item)">
                        <q-item-section>
                          <q-item-label>{{item}}</q-item-label>
                        </q-item-section>
                      </q-item>
                    </q-list>
                  </q-btn-dropdown>
              </div>
              <div class="col-8 text-center positive h4">
                <span v-if="assessment.competences[chartIndex]">{{assessment.competences[chartIndex].name}}</span>
              </div>
              <div class="offset-2 col-8 text-center q-mt-md">
                <apexcharts height="550" type="radar" :options="chartOptions" :series="series" />
              </div>
            </div>

            <!--Комментарии-->
            <div class="row justify-center">
              <div class="col-8 text-left">
                <Comments v-if="parent"
                          v-bind:lang="lang"
                          v-bind:entity-id="`ass_${assessment.id}`"
                          v-bind:user_current="user_current"
                          v-bind:parent="parent"
                          v-bind:mode="'list'"
                />
              </div>
            </div>

          </div>
        </q-tab-panel>
      </q-tab-panels>
      <!-- </q-card>-->
    </div>
    <!-- Отчет   -->
    <div v-else-if="showReport" class="m-2">
      <div class="text-right q-ma-md q-gutter-x-md">
        <q-btn flat round icon="close" text-color="positive" @click="showReport = false">
          <q-tooltip class="bg-primary h-tt">{{ message[lang].close_report }}</q-tooltip>
        </q-btn>
      </div>
      <div class="text-left q-pa-md bg-negative q-gutter-y-lg" style="border-radius: 25px">
        <div v-for="(item,index) in allReport" :key="index">
          <q-table flat :title="item.title" :rows="item.rows" :columns="item.columns" row-key="name" :pagination="initialPagination" :rows-per-page-label="message[lang].rows_per_page">
            <template #body-cell="props">
              <q-td :props="props" :class="props.row.class?props.row.class:'bg-negative'">
                {{ props.value }}
              </q-td>
            </template>
          </q-table>
          <q-separator inset color="positive"/>
        </div>


      </div>
    </div>
    <!-- Список  -->
    <div v-else class="g-mr-md">
      <assessments-list
        v-if="assListGroup && assListGroup.length>0"
        :isLoading="isLoading"
        :isShowMore="isShowMore"
        :lang="lang"
        :checkIndex="checkIndex"
        :assListGroup="assListGroup"
        :parent="parent"
        :expanded="expanded"
        :isChecked="isChecked"
        @doCheckAll="doCheckAll"
        @editAssessmentGroup="editAssessmentGroup"
        @editAssessment="editAssessment"
        @updateFlags="updateFlags"
        @delSomeAssessment="delSomeAssessment"
        @thisChecked="thisChecked"
        @doCheckGroup="doCheckGroup"
        @loadAssessmentsMore="loadAssessmentsMore"
      />
    </div>
  </div>
</template>

<script>
import AssessmentsList from 'src/components/AssessmentsList';
import {messageObj} from './helpers/message';
import dayjs from 'dayjs';
import CatsEdit from './CatsEdit.vue';
import VueApexCharts from 'vue3-apexcharts'; //https://apexcharts.com/docs/
import XLSX from 'xlsx';
import {saveAs} from 'file-saver';
import {ref} from 'vue';
import Comments from './Comments.vue'

export default {
  props: {
    parent: Object,
    lang: String,
    back: String,
    user_current:Object,
  },
  // setup() {
  //   return {
  //     expanded: ref(false),
  //   };
  // },
  data() {
    return {
      departments:[],
      allReport:[],
      pagination: ref({
        sortBy: 'desc',
        descending: false,
        page: 1,
        rowsPerPage: 50
        // rowsNumber: xx if getting data from a server
      }),
      chartIndex:0,
      showComps:true,
      competenceIndex:0,
      listCompetence:[],
      listForDelete:[],
      doChecked:false,
      isShowMore: false,
      last_id: 0,
      expanded: [],
      assessmentsArray: [],
      assListGroup: [],
      isCoworkersLoading: false,
      isSlavesLoading: false,
      isChiefLoading: false,
      wbout: '',
      file_download_link: '',
      initialPagination: {
        sortBy: 'desc',
        descending: false,
        page: 1,
        rowsPerPage: 20,
      },
      exportStarted: false,
      columns: [],
      rows: [],
      checkAll: false,
      checkIndex: [],
      startFinish: {from: '', to: ''},
      tab: 'assessment',
      maxCount: 3,
      tabActiveName: 'assessment',
      goback: '',
      showReport: false,
      report: [],
      report_fields: [],
      legend: false,
      isLoadingSome: false,
      isLoading: false,
      isLoadingXLS: false,
      selected: '',
      editIndex: '',
      alert_message: '',
      assessmentList: [],
      // languages: lange,
      showForm: false,
      // DATE_START:'',
      // DATE_FINISH:'',
      tofinish: '',
      fromstart: '',
      tostart: '',
      table_fields: [],
      table_items: [],
      assessment: {
        name: '',
        date_start: '',
        date_finish: '',
        description: '',
        persona: [],
        experts: [],
        list: [{persona: [], experts: []}],
        competences: [],
        flags:{acc:'N', com:'', hide_fio:'Y'}
      },
      series: [],
      chartOptions: {
        chart: {
          height: 550,
          type: 'radar',
          dropShadow: {
            enabled: true,
            blur: 1,
            left: 1,
            top: 1,
          },
        },
        title: {text: ''},
        stroke: {
          width: 2,
        },
        fill: {
          opacity: 0.1,
        },
        markers: {
          size: 0,
        },
        xaxis: {
          categories: [],
        },
      },
      message: messageObj,
    };
  },
  mounted: function () {
    let $this=this;
    $this.parent.isLoadingModule=false;
    console.log(':: meth360 mounted this.back=', this.back);
    // console.log('lang', this.lang);
    this.goback = this.back ? this.back : 'coursesList';
    let today = dayjs().format('YYYY-MM-DD');
    let yestoday = dayjs().subtract(1, 'day').format('YYYY-MM-DD');
    this.tofinish = new Date();
    this.tostart = new Date(yestoday);
    this.maxCount = this.parent.getMaxCountByTarif('meth360180');
    this.isNoFormValid();
    this.parent.getDepartments().then((departments) => {
      this.departments = departments;
      console.log('departments', this.departments);
      this.loadAssessments().then(() => {
        this.createGroupList();
      });
    });
    // this.createGroupList(); //запускается слишком рано
  },
  watch: {
    startFinish: function () {
      this.assessment.date_start = this.startFinish && this.startFinish.from ? dayjs(this.startFinish.from).format('YYYY-MM-DD') + ' 00:00:00' : '';
      this.assessment.date_finish = this.startFinish && this.startFinish.to ? dayjs(this.startFinish.to).format('YYYY-MM-DD') + ' 23:59:59' : '';
      this.isNoFormValid();
    },
  },
  computed: {
    pagesNumber() {
      return Math.ceil(this.report.length / this.pagination.rowsPerPage);
    },
    isChecked() {
      // console.log('::isChecked this.assListGroup=',this.assListGroup)
      let ret = false;
      if (Object.keys(this.assListGroup).length > 0) {
        for (let i in this.assListGroup) {
          if (this.assListGroup[i].checked) {
            ret = true;
            break;
          }
        }
      }
      return ret;
    },
    showTitleDate() {
      let ret = '- - -';
      let date_format = this.parent.appRegion == 'ru' ? 'DD.MM.YYYY' : 'YYYY-MM-DD';
      if (this.startFinish && this.startFinish.from && !this.startFinish.to) {
        ret = dayjs(this.startFinish.from).format(date_format) + ' - ?';
      } else if (this.startFinish && this.startFinish.from && this.startFinish.to) {
        ret = dayjs(this.startFinish.from).format(date_format) + ' - ' + dayjs(this.startFinish.to).format(date_format);
      }
      return ret;
    },
    nameState() {
      return this.assessment && this.assessment.name && String(this.assessment.name).trim().length > 3;
    },
    isDraftValid(){
      let isPersonaAdded = this.assessment.list && this.assessment.list[0].persona.length>0;
      return this.nameState && isPersonaAdded;
    }
  },
  methods: {
    showCatText(item, subcat_index){
      // console.log('::showCatText ',subcat_index,item)
      return item.cats.find(el=>{return el.value==subcat_index}).text;
    },
    editComp(index){
      this.competenceIndex=index;
      this.showComps=false;
      this.$nextTick(()=>{
        this.showComps=true;
        this.$refs.comp_ref.show();
      });
    },
    delComp(index){
      let $this = this;
      $this.parent.modal_list_single = false;
      $this.parent.modal_list = false;
      $this.parent.modal_title=$this.message[$this.lang].attention;
      $this.parent.modal_message=$this.message[$this.lang].del_competence +
      $this.assessment.competences[index].name;
      $this.parent.modal_obj='';
      $this.parent.modal_ok = $this.message[$this.lang].del;
      $this.parent.modal_filterFn = null;
      $this.parent.okModal =  ()=>{
        $this.assessment.competences.splice(index,1);
        $this.parent.hideModal();
      };
      BX24.scrollParentWindow(0);
      this.parent.showModal();
    },
    doCheckGroup(index){
      if(this.assListGroup && this.assListGroup.length>0) {
        for (let i in this.assListGroup[index]) {
          this.assListGroup[index][i].checked = !this.assListGroup[index][i].checked;
        }
        this.doChecked = true;
      }
    },
    thisChecked(el,ind){
      console.log('::thisChecked el,ind=',el,ind, this.assListGroup)
      let isBreak = false;
      this.doChecked=false;
      if (Object.keys(this.assListGroup).length > 0) {
        for (let group in this.assListGroup) {
          for (let i in this.assListGroup[group]) {
            let item = this.assListGroup[group][i];
            if (item.checked) {
              this.doChecked = true;
              isBreak=true;
              break;
            }
          }
          if(isBreak){
            break;
          }
        }
        // this.expanded = this.expanded.map((item) => !item);
        // for (let ind = 0; ind < this.assListGroup.length - 1; ind++) this.expanded[ind] = this.doChecked;
        // console.log('::this.expanded= ',this.expanded)
      }
    },
    delFromList(ind) {
      this.assessment.list.splice(ind, 1);
    },

    createGroupList() {
      // console.log(':***:createGroupList assessmentList=',[...this.assessmentList]);
      //сортировка по группе, на выходе массив где в начале идут элементы с группой и по порядку
      // затем без группы
      this.assListGroup=[];
      if(this.assessmentList.length==0) return;
      let lastGroup = -1;
      let subList=[];
      for(let item of this.assessmentList){
        if(item.group){
          if(item.group != lastGroup){
            if(subList.length > 0) {
              this.assListGroup.push([...subList]);
            }

            subList=[];
            lastGroup = item.group;
          }

          subList.push(item);
        }
      }
      if(subList.length > 0) {
        this.assListGroup.push([...subList]);
      }

      // console.log(':***:createGroupList assListGroup=', [...this.assListGroup])
    },

    // createGroupListOld() {
    //   console.log(':***:createGroupList assessmentList=',[...this.assessmentList]);
    //   //сортировка по группе, на выходе массив где в начале идут элементы с группой и по порядку
    //   // затем без группы
    //   this.assListGroup=[];
    //   if(this.assessmentList.length==0) return;
    //
    //   //клонируем assessmentList
    //   let assessmentList = [...this.assessmentList];
    //
    //
    //   //здесь раскидываются элементы с группой влево (начало массива), без группы вправо (конец массива)
    //   let arrLR = [];
    //   assessmentList.forEach((elem) => {
    //     if (elem.group) arrLR.unshift(elem);
    //     else arrLR.push(elem);
    //   });
    //   // console.log(':1:createGroupList arrLR=',arrLR.length,Object.keys(arrLR).length, arrLR)
    //
    //   // ищем первый не групповой элемент
    //   let arrGroup = [];
    //   // if(arrLR.length<2){
    //   //   arrGroup = arrLR;
    //   //   console.log(':2:createGroupList arrGroup=',arrGroup)
    //   // }else {
    //   let ind = arrLR.findIndex((elem) => !elem.group);
    //   //в этом месте идет разделение на два массива, один только с группами другой без
    //   arrGroup = arrLR.splice(0, ind);
    //   // console.log(':3:createGroupList arrGroup=',ind ,arrGroup)
    //   // }
    //
    //   // сортировка по группе по убыванию
    //   arrGroup.sort((a, b) => {
    //     if(parseInt(a.group) - parseInt(b.group) >0) return -1;
    //     if(parseInt(a.group) - parseInt(b.group) <0) return 1;
    //     return 0;
    //   });
    //
    //   //соединяем обратно отсортированный групповой массив и обычный без групп
    //   assessmentList = [].concat(arrGroup, arrLR);
    //   //перебор ^^^ этого массива и группировка элементов по группе
    //   // на выходе массив типа [ [ {group:a}, {group:a} ], [ {group:b} ], {nongroup:2}, {nongroup:3} ]
    //   // console.log(':4:createGroupList assessmentList=',this.assessmentList ,arrGroup, arrLR)
    //   let arr = assessmentList;
    //   let notepad = [arr[0]];
    //   // console.log(':0:notepad=',arr.length,notepad, arr)
    //   if(arr.length>1) {
    //     //проверка на совпадение групп соседних оценок, если да группировка
    //     for (let ind = 1; ind < arr.length; ind++) {
    //       if (arr[ind].group) {
    //         if (arr[ind - 1].group == arr[ind].group){
    //           notepad.splice(0,0,arr[ind]);
    //           // notepad.push(arr[ind]);
    //         }else{
    //           // console.log(':1:notepad=',notepad)
    //           this.assListGroup.splice(0,0,notepad);
    //           // this.assListGroup.push(notepad);
    //           notepad = [];
    //           notepad.push(arr[ind]);
    //           if (!arr[ind + 1] || !arr[ind + 1].group){
    //             // this.assListGroup.push(notepad);
    //             this.assListGroup.splice(0,0,notepad);
    //           }
    //         }
    //       } else{
    //         // console.log(':2:notepad=',notepad)
    //         this.assListGroup.splice(0,0,notepad);
    //         // this.assListGroup.push(arr[ind]);
    //       }
    //     }
    //     if(notepad.length>1){
    //       // console.log(':3:notepad=',notepad)
    //       this.assListGroup.splice(0,0,notepad);
    //       // this.assListGroup.push(notepad);
    //     }
    //   }else{
    //     // console.log(':4:notepad=',notepad)
    //     this.assListGroup.splice(0,0,notepad);
    //     // this.assListGroup.push(arr[0]);
    //     // console.log(':5:createGroupList arr[0]=',arr[0] ,this.assListGroup)
    //   }
    //   for (let ind = 0; ind < this.assListGroup.length - 1; ind++) this.expanded[ind] = false;
    //   console.log(':***:createGroupList assListGroup=', [...this.assListGroup])
    // },

    addList() {
      let ind = this.assessment.list.length - 1;
      // console.log('CLICK addList');
      if (this.assessment.list.length === 0) {
        this.assessment.list.push({persona: [], experts: []});
      }
      if (this.assessment.list[ind].persona && this.assessment.list[ind].experts) {
        // console.log('good');
        this.assessment.list.push({persona: [], experts: []});
      }
      // console.log('assessment from addList', this.assessment);
    },
    //     Добавить в имя оценки префикс с группой ###
    //  создав новый список на основе (отсортированного assessmentList по группам)
    // Выводить новый список через v-for с условием Array.isArray
    slavesAdd(ind) {
      console.log('::slavesAdd ind,assessment=>', ind, this.assessment);
      let $this = this;
      this.isSlavesLoading=true;
      if (!this.assessment.experts) this.assessment.experts = [];

      let allDeps = [];
      if ($this.assessment.list) {
        this.assessment.list[ind].persona.forEach((elem) => {
          if (!allDeps.find((item) => elem.deps.includes(item))) allDeps.push(...elem.deps);
        });
      } else {
        this.assessment.persona.forEach((elem) => {
          if (!allDeps.find((item) => elem.deps.includes(item))) allDeps.push(...elem.deps);
        });
      }
      console.log(':+++:allDeps=', allDeps);
      for (let dep of allDeps) {
        BX24.callMethod('department.get', {ID: dep}, function (res) {
          let arrRes = res.data();
          arrRes.forEach((elem) => {
            $this.assessment.list[ind].persona.forEach((man) => {
              if (elem.UF_HEAD == man.id) {//проверяем начальник ли он отделов  в которых состоит
                console.log(':+++:slavesAdd elem=',elem.ID, elem);
                //Теперь нужно вытащить все подотделы этого отдела и добавить их в assessment.experts
                let allSubDeps = $this.getChildren($this.departments, elem.ID);
                console.log(':++++++: allSubDeps=',allSubDeps);
                //получаем юзеров этого отдела
                $this.parent.getUsers(true, [...elem.ID, ...allSubDeps.map(elem => parseInt(elem.value))], null).then((users) => {
                    for (let i in users) {
                      let user = users[i];
                      let foundUser = $this.assessment.experts.find((elem) => elem.id == user.ID);
                      if (!foundUser) {
                        let itemUser = {
                          id: user.ID,
                          name: user.NAME + ' ' + user.LAST_NAME,
                          deps: user.UF_DEPARTMENT,
                          color: 'primary',
                        };
                        if ($this.assessment.list) {
                          if (!$this.assessment.list[ind].experts.find((item) => {return item.id == itemUser.id})) {
                            if (!$this.assessment.list[ind].persona.find((item) => {return item.id == itemUser.id}))
                              $this.assessment.list[ind].experts.push(itemUser);
                          }
                        } else {
                          if (!$this.assessment.persona.find((item) => {return item.id == itemUser.id}))
                            $this.assessment.experts.push(itemUser);
                        }
                      }
                    }
                    $this.parent.getSubAccounts($this.assessment.experts).then(res=>{
                      $this.assessment.experts = $this.assessment.experts.concat(res);
                      console.log(':: assessment.experts=',$this.assessment.experts);
                      $this.isNoFormValid();
                      $this.isSlavesLoading=false;
                    });


                  },
                  (err) => {
                    console.log(err);
                  }
                );
              }
            });
          });
        });
      }
    },
    /**
     * Рекурсивно находит и возвращает детей узла в структуре дерева.
     *
     * @param {Array} tree - Структура дерева для поиска.
     * @param {number} parentId - ID родительского узла.
     * @return {Array} Массив дочерних узлов.
     */
    getChildren(tree, parentId) {
      let result = [];
      function findChildren(parentId) {
          tree.forEach(node => {
              if (node.parent == parentId) {
                  result.push(node);
                  findChildren(node.value); // рекурсивно ищем детей текущего узла
              }
          });
      }

      findChildren(parentId);
      return result;
    },
    chiefAdd(ind) {
      let $this = this;
      this.isChiefLoading = true;
      if (!this.assessment.experts) {
        this.assessment.experts = [];
      }
      let allDeps = [];
      if ($this.assessment.list) {
        this.assessment.list[ind].persona.forEach((elem) => {
          if (
            !allDeps.find((item) => {
              elem.deps.includes(item);
            })
          ) {
            allDeps.push(...elem.deps);
          }
        });
      } else {
        this.assessment.persona.forEach((elem) => {
          if (
            !allDeps.find((item) => {
              elem.deps.includes(item);
            })
          ) {
            allDeps.push(...elem.deps);
          }
        });
      }

      for (let dep of allDeps) {
        BX24.callMethod('department.get', {ID: dep}, function (res) {
          let arrRes = res.data(); //записываем все департаменты
          //в каждом департаменте берем id главы и по нему получаем юзера
          // arrRes.forEach(

            // (dep) => {
              // $this.parent.getUsers(true, null, [dep.UF_HEAD]).then((users) => {
              $this.parent.getUsers(true, null, arrRes.map(el => el.UF_HEAD)).then((users) => {
                if(users[0]) {
                  let user = users[0];
                  let itemUser = {
                    id: user.ID,
                    name: user.NAME + ' ' + user.LAST_NAME,
                    deps: user.UF_DEPARTMENT,
                    color: 'secondary',
                  };
                  if ($this.assessment.list) {
                    if (!$this.assessment.list[ind].persona.find((item) => item.id == itemUser.id)) {
                      if (!$this.assessment.list[ind].experts.find((item) => item.id == itemUser.id)) {
                        $this.assessment.list[ind].experts.push(itemUser);
                      }
                    }
                  } else {
                    if (!$this.assessment.persona.find((item) => item.id == itemUser.id)) {
                      $this.assessment.experts.push(itemUser);
                    }
                  }
                }
                $this.isChiefLoading = false;
              });
            },
            (err) => {
              console.log(err);
            });
        // });
      }
    },
    departmentsAdd(ind) {
      let $this = this;
      $this.parent.modal_list_selected = '';
      $this.parent.modal_list = [];
      for (let i in this.departments) {
        let dep = departments[i];
        $this.parent.modal_list.push({text: dep.text, value: dep.value});
      }
        $this.parent.modal_flt = '';
        $this.parent.model = null;
        $this.parent.modal_list_single = false;
        $this.parent.modal_list_label = $this.message[$this.lang].add_departments;
        $this.parent.modal_title = $this.message[$this.lang].selectdepartments_title;
        $this.parent.modal_message = $this.message[$this.lang].selectdepartments_message;
        $this.parent.modal_ok = $this.message[$this.lang].selectdepartments_add;
        $this.parent.modal_cancel = $this.message[$this.lang].selectdepartments_finish;
        $this.parent.modal_filterFn = null;
        $this.parent.okModal = () => {
          console.log('modal_list_selected', $this.parent.modal_list_selected);
          // let users_id = $this.parent.modal_list_selected.map((elem) => Number(elem));
          let allDeps = $this.parent.modal_list_selected.map((elem) => Number(elem));

          this.parent.getUsers(true, allDeps).then(
            (users) => {
              console.table(users);
              // let items = [];
              let itemUsers = [];
              for (let i in users) {
                let user = users[i];

                itemUsers.push({id: user.ID, name: user.NAME + ' ' + user.LAST_NAME, deps: user.UF_DEPARTMENT, color: 'secondary'});
              }
              console.log('$this.assessment.list::', $this.assessment.list);
              if ($this.assessment.list[ind].persona.length > 0) {
                itemUsers.forEach((user) => {
                  if (!$this.assessment.list[ind].persona.find((item) => item.id == user.id)) {
                    $this.assessment.list[ind].persona.push(user);
                  }
                });
              } else if ($this.assessment.list) {
                $this.assessment.list[ind].persona.push(...itemUsers);
              } else {
                itemUsers.forEach((user) => {
                  if (!$this.assessment.persona.find((item) => item.id == user.id)) {
                    $this.assessment.persona.push(user);
                  }
                });
              }

              // console.log('COW assessment.experts::', this.assessment.experts);
            },
            (err) => {
              console.log(err);
            }
          );

          // $this.isNoFormValid();
          // $this.parent.hideModal();
          $this.parent.getSubAccounts($this.assessment.persona,null,departments).then(res=>{
            $this.assessment.persona = $this.assessment.persona.concat(res);
            console.log(':: assessment.persona=',$this.assessment.persona);
            $this.isNoFormValid();
            $this.parent.hideModal();
          });
        };
        this.parent.showModal();
    },

    coworkersAdd(ind) {
      let $this = this;
      this.isCoworkersLoading = true;
      // console.log('cowAdd assessment user', this.assessment);
      if (!this.assessment.experts) {
        this.assessment.experts = [];
      }
      let allDeps = [];
      if ($this.assessment.list) {
        this.assessment.list[ind].persona.forEach((elem) => {
          console.log('elem', elem);
          if (
            !allDeps.find((item) => {
              elem.deps && elem.deps.includes(item);
            })
          ) {
            allDeps.push(...elem.deps);
          }
        });

        // allDeps = this.assessment.list[ind].persona.deps;
      } else {
        this.assessment.persona.forEach((elem) => {
          if (
            !allDeps.find((item) => {
              elem.deps && elem.deps.includes(item);
            })
          ) {
            allDeps.push(...elem.deps);
          }
        });
        // allDeps = this.assessment.persona.deps;
      }
      // $this.getAllSubDeps($this.parent.modal_list, parseInt($this.parent.modal_list_selected), allDeps);
      // console.log('::=== allDeps === ', allDeps);
      // setTimeout(() => {
      this.parent.getUsers(true, allDeps).then(
        (users) => {
          console.table(users);
          let items = [];
          for (let i in users) {
            let user = users[i];

            let itemUser = {id: user.ID, name: user.NAME + ' ' + user.LAST_NAME, deps: user.UF_DEPARTMENT, color: 'info'};
            // console.log('$this.assessment.list::', $this.assessment.list);
            if ($this.assessment.list) {
              if (!$this.assessment.list[ind].experts.find((item) => item.id == itemUser.id)) {
                if (!$this.assessment.list[ind].persona.find((item) => item.id == itemUser.id)) {
                  $this.assessment.list[ind].experts.push(itemUser);
                }
              }
            } else {
              if (!$this.assessment.persona.find((item) => item.id == itemUser.id)) {
                $this.assessment.experts.push(itemUser);
              }
            }
          }
          $this.parent.getSubAccounts($this.assessment.experts).then(res=>{
            $this.assessment.experts = $this.assessment.experts.concat(res);
            console.log(':: assessment.experts=',$this.assessment.experts);
            $this.isNoFormValid();
            $this.isCoworkersLoading = false;
          });
          // console.log('COW assessment.experts::', this.assessment.experts);
        },
        (err) => {
          console.log(err);
        }
      );
      // }, 1000);
    },

    // getAllSubDeps(list, parent, ret) {
    //   parent = parseInt(parent);
    //   let items = list.filter((item) => parseInt(item.parent) === parent);
    //   // console.log('::getAllSubDeps items=',parent,':> ', items);
    //   while (items && Object.keys(items).length > 0) {
    //     let item = items.pop();
    //     let parent_id = parseInt(item.value);
    //     ret.push(parent_id);
    //     this.getAllSubDeps(list, parent_id, ret);
    //   }
    // },
    getAllSubDeps(list, parent, ret){
      // console.log('::getAllSubDeps parent=', parent);
      let items = list.filter(item => parseInt(item.parent) == parent);
      // console.log(':0: items=', JSON.stringify(items));
      let nn=0;
      while(items && Object.keys(items).length>0 && ++nn<1000){
        let item = items.pop();
        let dep = parseInt(item.value);
        // let parent = parseInt(item.parent)
        // console.log(':1: push ',dep)
        ret.push(dep);
        this.getAllSubDeps(list, dep, ret)
      }
    },
    doCheckAll() {
      // this.expanded = this.expanded.map((item) => !item);
      // this.expanded = !this.expanded;
      this.checkAll = !this.checkAll;
      for(let i in this.assListGroup){
        if(Array.isArray(this.assListGroup[i])) {
          for (let j in this.assListGroup[i]) {
            this.assListGroup[i][j].checked = this.checkAll;
          }
        }else{
          this.assListGroup[i].checked = this.checkAll;
        }
      }
      this.doChecked = this.checkAll;
      this.expanded = this.expanded.map((item) => this.checkAll);
      this.showReport=true;
      this.$nextTick(()=>{
        this.showReport=false;
      })
      // console.log('::doCheckAll ',this.checkAll, this.assListGroup);

    },
    initTable() {
      // console.log(':???:initTable assessment=', this.assessment);

      this.columns = [
        {
          name: 'num',
          required: true,
          label: '№',
          align: 'left',
          field: (row) => row.num,
          format: (val) => `${val}`,
          sortable: false,
        },
        {
          name: 'competence',
          label: this.message[this.lang].competence,
          field: 'competence',
        },
      ];
      for (let expert of this.assessment.experts) {
        this.columns.push({
          name: 'expert_id_' + expert.id,
          label: expert.name,
          field: 'expert_id_' + expert.id,
        });
      }
      this.columns.push({
        name: 'average',
        label: this.message[this.lang].average,
        field: 'average',
      });
      this.columns.push({
        name: 'average1',
        label: this.message[this.lang].average1,
        field: 'average1',
      });
      // console.log(':: columns= ', this.columns);
      // console.log(':: assessment= ', this.assessment);
      // console.log(':: assessment.scores= ', this.assessment.scores);

      // Строки
      this.rows = [];
      if(this.assessment.scores && Object.keys(this.assessment.scores).length>0) {
        if (this.assessment.scores[0].hasOwnProperty('ind_c')) {

          for (let ind_c in this.assessment.competences) {
            let competence = this.assessment.competences[ind_c]
            // console.log(':: competence= ', competence);
            let rowItem = {num: '', competence: competence.name, average: '', average1: ''};
            this.rows.push(rowItem);

            // let scoreItem = this.assessment.scores[num];
            // console.log(':: scoreItem= ', scoreItem);
            // for(let j in competence.subcats) {
            //   console.log(':: competence.subcats= ',j, competence.subcats[j]);
            // }
            //
            for (let ind_i in competence.cats) {
              let indicator = competence.cats[ind_i];
              // console.log(':: indicator= ', indicator);
              rowItem = {num: parseInt(ind_i) + 1, competence: indicator.text};
              let scoreItem = this.assessment.scores.find(el=>{
                // console.log(`${el.ind_c}==${ind_c}?=${el.ind_c==ind_c} &&  ${el.ind_i}==${ind_i}?=${el.ind_i==ind_i}`);
                return el.ind_c==ind_c && el.ind_i==ind_i
              });
              // console.log(':: scoreItem= ', scoreItem);
              let summa = 0, nn = 0; // Сумма оценок по строке
              let summa1 = 0, nn1 = 0; // Сумма оценок по строке без оцениваемого
              if(scoreItem) {
                for (let expert of this.assessment.experts) {
                  let score = 0;
                  let exp_id = parseInt(expert.id);
                  // Найдем оценку
                  if (scoreItem.selected && scoreItem.selected.hasOwnProperty(exp_id)) {
                    let value = parseInt(scoreItem.selected[exp_id]) * 10;
                    console.log(`:: persona.id=${this.assessment.persona.id} exp_id=${exp_id} value=${value} `);
                    let label = ''
                    // if(competence.subcats[indicator.text][scoreItem.ind_i]) {
                      // label = competence.subcats[indicator.text][scoreItem.ind_i].text;
                      label = competence.subcats[indicator.text][parseInt(scoreItem.selected[exp_id])].text;
                    // }
                    // console.log(':: label=',label ,indicator.text, parseInt(scoreItem.selected[exp_id]),  competence.subcats[indicator.text])

                    score = label + ' (' + value + ')';
                    summa += value;
                    nn++;
                    if (parseInt(exp_id) !== parseInt(this.assessment.persona.id)) {
                      summa1 += value;
                      nn1++;
                    }
                  }
                  rowItem['expert_id_' + expert.id] = score;
                }
                console.log(`:: summa1=${summa1}  nn1=${nn1} `, summa1 / nn1, (summa1 / nn1).toFixed(1));
              }else{
                console.error(':: score not found ', ind_c, ind_i);
              }

              rowItem['average'] = nn > 0 ? (summa / nn).toFixed(1) : 0;
              rowItem['average1'] = nn1 > 0 ? (summa1 / nn1).toFixed(1) : 0;
              this.rows.push(rowItem);
            }
            rowItem = {num: '', competence: '', average: '', average1: ''};
            this.rows.push(rowItem);

          }
        }//устаревшее убрать в 2024
        else {
          for (let num in this.assessment.scores) {
            let competence = this.assessment.scores[num];
            console.log(':: competence= ', competence);
            let rowItem = {num: '', competence: competence.name, average: '', average1: ''};
            this.rows.push(rowItem);

            for (let ind in competence.indicators) {
              let indicator = competence.indicators[ind];
              // console.log(':: indicator= ', indicator);
              rowItem = {num: parseInt(ind) + 1, competence: indicator.competence};
              let summa = 0, nn = 0; // Сумма оценок по строке
              let summa1 = 0, nn1 = 0; // Сумма оценок по строке без оцениваемого
              for (let expert of this.assessment.experts) {
                let score = 0;
                let exp_id = parseInt(expert.id);
                if (indicator.selected && indicator.selected[exp_id]) {
                  let value = parseInt(indicator.selected[exp_id].value.index) * 10;
                  console.log(`:: exp_id=${exp_id} value=${value} `);
                  score = indicator.selected[exp_id].label + ' (' + value + ')';
                  summa += value;
                  nn++;
                  if (parseInt(exp_id) !== parseInt(this.assessment.persona.id)) {
                    summa1 += value;
                    nn1++;
                  }
                }
                rowItem['expert_id_' + expert.id] = score;
              }
              console.log(`:: summa=${summa}  nn=${nn} `, summa / nn, (summa / nn).toFixed(1));
              rowItem['average'] = nn > 0 ? (summa / nn).toFixed(1) : 0;
              rowItem['average1'] = nn1 > 0 ? (summa1 / nn1).toFixed(1) : 0;
              this.rows.push(rowItem);
            }
            rowItem = {num: '', competence: '', average: '', average1: ''};
            this.rows.push(rowItem);
          }
        }// 2024
      }
      console.log(':: rows ', this.rows);

      this.initChart();
    },
    itemCompNames(item){
      let ret=[];
      for(let i in item.competences){
        ret.push(item.competences[i].name);
      }
      ret.push(this.message[this.lang].integral_chart);
      return ret;
    },
    initChart(iComp=0, item_name) {
      let isIntegral = item_name == this.message[this.lang].integral_chart;
      if(isIntegral){
        this.initIntegralChart();
      }else {
        this.chartIndex = iComp
        // let iComp=this.chartIndex?this.chartIndex:0;
        console.log('::initChart this.chartIndex=', this.chartIndex, this.assessment);
        this.tabActiveName = 'chart';
        let name = this.assessment.persona[0] ? this.assessment.persona[0].name : this.assessment.persona.name;
        this.chartOptions.title.text = this.message[this.lang].chart_title + ' ' + name;
        //
        this.legend = [];
        let showLegend = false;
        this.chartOptions.xaxis.categories = [];
        this.series = [];
        this.table_items = [];
        // this.table_fields = [{key: 'user', label: this.message[this.lang].user}];
        let n = 1;
        for (let cat in this.assessment.competences[iComp].subcats) {
          this.legend.push(n + '. ' + cat);
          if (String(cat).trim().length > 8) {
            cat = n //+ '. ' + cat.substring(0, 8) + '..';
            showLegend = true;
          } else {
            cat = n //+ '. ' + cat;
          }
          this.chartOptions.xaxis.categories.push(cat);
          // this.table_fields.push({key:''+n, label:cat});
          n++;
        }
        if (!showLegend) {
          this.legend = false;
        }
        console.log(':2:initChart scores=', this.assessment.scores);
        //
        let userScores = {}, userText = {};
        if (this.assessment.scores && Object.keys(this.assessment.scores).length > 0) {
          if (this.assessment.scores[0].hasOwnProperty('ind_c')) {
            // console.log(':: new scores')
            for (let item of this.assessment.scores.filter(el => {
              return el.ind_c == iComp
            })) {
              console.log(':: item=', item);
              let ind_i = item.ind_i;
              for (let uid in item.selected) {
                userScores[uid] = userScores[uid] ? userScores[uid] : [];
                userText[uid] = userText[uid] ? userText[uid] : [];

                userScores[uid].push(item.selected[uid] * 10);
                userText[uid].push(this.assessment.competences[iComp].cats[ind_i].text);
              }
            }
          }//устаревшее убрать в 2024
          else {
            for (let iId in this.assessment.scores[iComp].indicators) {
              let indicator = this.assessment.scores[iComp].indicators[iId];
              for (let uid in indicator.selected) {
                userScores[uid] = userScores[uid] ? userScores[uid] : [];
                userText[uid] = userText[uid] ? userText[uid] : [];

                userScores[uid].push(indicator.selected[uid].value.index * 10);
                userText[uid].push(indicator.selected[uid].value.text);
              }
            }
          }
          // /2024
        }
        console.log(':3:initChart userScores=', userScores);
        console.log(':4:initChart userText=', userText);

        for (let expert of this.assessment.experts) {
          this.series.push({
            name: expert.name,
            data: userScores[expert.id] ? userScores[expert.id] : [],
            scores_text: userText[expert.id] ? userText[expert.id] : [],
          });
        }
        console.log(':6:initChart series=', this.series);

        this.chartOptions.markers = {
          size: 4,
          hover: {
            size: 8,
          },
        };

        if (this.assessment.competences[iComp].cats && this.assessment.competences[iComp].cats[0]) {
          let item = this.assessment.competences[iComp].subcats[this.assessment.competences[iComp].cats[0].value];
          let tickAmount = Object.keys(item).length;

          this.chartOptions.yaxis = {
            tickAmount: tickAmount,
          };
        }

        console.log('::initChart chartOptions=', this.chartOptions);
      }
    },

    initIntegralChart(){
      console.log('::initIntegralChart assessment=',this.assessment);
      this.tabActiveName = 'chart';
      let name = this.assessment.persona[0] ? this.assessment.persona[0].name : this.assessment.persona.name;
      this.chartOptions.title.text = this.message[this.lang].chart_title + ' ' + name;
      //
      this.legend = [];
      let showLegend = false;
      this.chartOptions.xaxis.categories = [];
      this.series = [];
      this.table_items = [];
      let n = 1;
      for (let comp of this.assessment.competences) {
        let cat = comp.name;
        this.legend.push(n + '. ' + cat);
        if (String(cat).trim().length > 8) {
          cat = n //+ '. ' + cat.substring(0, 8) + '..';
          showLegend = true;
        } else {
          cat = n //+ '. ' + cat;
        }
        this.chartOptions.xaxis.categories.push(cat);
        // this.table_fields.push({key:''+n, label:cat});
        n++;
      }
      console.log(':: legend=',this.legend)
      console.log(':: chartOptions.xaxis.categories=',this.chartOptions.xaxis.categories)
      if (!showLegend) {
        this.legend = false;
      }
      let userScores = {}, userScoresN = {}, userText = {};
      if (this.assessment.scores && Object.keys(this.assessment.scores).length > 0) {
        if (this.assessment.scores[0].hasOwnProperty('ind_c')) {
          // console.log(':: new scores')
          for(let iComp in this.assessment.competences) {
            let compName=this.assessment.competences[iComp].name;
            console.log(':: ===== iComp=', iComp, compName);

            let userScoresIcomp = {};
            for (let item of this.assessment.scores.filter(el => {
              return el.ind_c == iComp
            })) {
              console.log(':: item=', item);
              let ind_i = item.ind_i;
              for (let uid in item.selected) {
                userScoresIcomp[uid] = userScoresIcomp[uid] ? userScoresIcomp[uid] : [];
                userScoresIcomp[uid].push(item.selected[uid] * 10)

                userText[uid] = userText[uid] ? userText[uid] : [];
                if(!userText[uid].find(el=>el==compName)){
                  userText[uid].push(compName);
                }
              }
            }// for 2
            console.log(':: userScoresIcomp=', userScoresIcomp);
            for(let uid in userScoresIcomp){
              if(Object.keys(userScoresIcomp[uid]).length>0){
                let sum = userScoresIcomp[uid].reduce((a,b)=>a+b,0);
                userScores[uid] = userScores[uid] ? userScores[uid] : [];
                userScores[uid].push((sum/Object.keys(userScoresIcomp[uid]).length).toFixed(1));
              }
            }


          }//for 1
        }
      }

      console.log(':3:initChart userScores=', userScores);
      console.log(':3:initChart userScoresN=', userScoresN);
      console.log(':4:initChart userText=', userText);


      for (let expert of this.assessment.experts) {
        this.series.push({
          name: expert.name,
          data: userScores[expert.id] ? userScores[expert.id] : [],
          scores_text: userText[expert.id] ? userText[expert.id] : [],
        });
      }
      console.log(':6:initChart series=', this.series);
      this.chartOptions.markers = {
        size: 4,
        hover: {
          size: 8,
        },
      };
      this.chartOptions.yaxis = {
        tickAmount: this.assessment.competences.length,
      };
      console.log('::initChart =', this.chartOptions);
    },
    updateFlags(index, index1){
      console.log('::updateFlags assListGroup=',index, index1, this.assListGroup[index]);
      let itemAss = this.assListGroup[index][index1];
      console.log('::updateFlags itemAss=',itemAss);
      this.update1Assessment(itemAss);
      if(itemAss.flags.acc === 'Y' ) {
        let message = this.message[this.lang].res360_published;
        let user_id = itemAss.persona.id;
        // BX24.callMethod('im.notify', {to: user_id, message: message, type:'SYSTEM' });
        this.parent.notifyLK(user_id, message, this.user_current);
        // Уведомления
        // this.parent.isNoteSet([user_id], "e360").then(checks => {
        //   if (Object.keys(checks).length > 0) {
        //     let arBatch2 = {};
        //     for (let uid of checks) {
        //       arBatch2['note_' + uid] = ['im.notify', {to: uid, message: message, type: 'SYSTEM'}];
        //     }
        //     this.parent.callBatch(arBatch2);
        //   }
        // });

      }
    },
    delSomeAssessment(index, index1, item) {
      console.log('::delSomeAssessment index,index1, item=',index,index1, item);

      // index = index !== null ? index : Object.keys(this.checkIndex).length - 1;
      let $this = this;
      $this.parent.modal_title = item ? this.message[this.lang].del_some_title : this.message[this.lang].del_many_title;
      let name = '';
      if(item) {
       name = item.name+'. ';
       name += item.persona[0] ? item.persona[0].name : item.persona.name;
      }
      $this.parent.modal_message = item ? this.message[this.lang].del_some_mess + name + '"?' : this.message[this.lang].del_many_mess;
      $this.parent.modal_obj = '';
      $this.parent.modal_list = null;
      $this.parent.modal_ok = this.message[this.lang].remove;
      $this.parent.modal_filterFn = null;
      $this.parent.okModal = () => {
        $this.parent.hideModal();
        $this.isLoadingSome = true;
        if(item){
          $this.delAssessmentSilent(index, index1, item);
        }else{
          $this.delCheckedItems();
        }
      };
      BX24.scrollParentWindow(0);
      $this.parent.showModal();
    },
    delCheckedItems(){
      // console.log('::delCheckedItems this.assessmentList=', this.assessmentList)
      let $this=this;
      let arBatch={};
      for(let i in this.assessmentList){
        let itemAss = this.assessmentList[i];
        console.log('::delCheckedItems itemAss=', itemAss)
        if(itemAss.checked) {
          arBatch[delete +'_' + itemAss.id] = ['entityItemDel',
            {
              ENTITY: 'uni_meth360180',
              ID: itemAss.id,
            },
          ];
        }
      }
      console.log('::delCheckedItems arBatch=',arBatch);
      if(Object.keys(arBatch).length>0) {
        $this.parent.callBatchIB(arBatch).then(result => {
          console.log('::delCheckedItems result=',result);
          $this.isLoadingSome = false;
          $this.loadAssessments().then(() => $this.createGroupList());
        });
      }else{
        console.log('::delCheckedItems no checked items');
        this.isLoadingSome = false;
      }
    },
    delAssessmentSilent(index, index1, item) {
      console.log('::delAssessmentSilent assListGroup=',index, index1, this.assListGroup[index]);
      let itemAss = this.assListGroup[index][index1];
      let $this = this;
      let params = {
        ENTITY: 'uni_meth360180',
        ID: itemAss.id,
      }

      // BX24.callMethod(
      //   'entity.item.delete',
      //   {
      //     ENTITY: 'uni_meth360180',
      //     ID: itemAss.id,
      //   },
      //   (resp) => {
      let url =  $this.parent.bx24Init({ AJAX_ACTION: 'entityItemDel', params:params });//.then(params=> {
      $this.parent.POST(url, $this.parent.bx24params, function (res) {
        console.log('::delAssessmentSilent resp=', res);
        $this.isLoadingSome = false;
        $this.assListGroup[index].splice(index1,1);
        if(Object.keys($this.assListGroup[index]).length == 0){
          $this.assListGroup.splice(index,1);
        }
      });
    },
    accCheckedItems(){
      // console.log('::delCheckedItems this.assessmentList=', this.assessmentList)
      let $this=this;
      let arBatch={};
      for(let i in this.assessmentList){
        let itemAss = this.assessmentList[i];
        console.log('::accCheckedItems itemAss=', itemAss)
        if(itemAss.checked && itemAss.active === 'Y') {
          itemAss.flags = itemAss.flags ? itemAss.flags : {acc:'N', com:'', hide_fio:'Y'};
          // itemAss.flags.acc = itemAss.flags.acc === 'N' ? itemAss.flags.acc = 'Y' : 'N';
          arBatch[delete +'_' + itemAss.id] = ['entityItemUpdate',
            {
              ENTITY: 'uni_meth360180',
              ID: itemAss.id,
              PROPERTY_VALUES: {
                persona: JSON.stringify(itemAss.persona),
                experts: JSON.stringify(itemAss.experts),
                flags: JSON.stringify(itemAss.flags),
              },
            },
          ];
        }
      }
      if(Object.keys(arBatch).length>0) {
        $this.parent.callBatchIB(arBatch).then((result)=> {
          console.log('::accCheckedItems result=',result);
          $this.isLoadingSome = false;
          // $this.loadAssessments().then(() => $this.createGroupList());
        });
      }else{
        console.log('::accCheckedItems no checked items');
        this.isLoadingSome = false;
      }
    },
    exportSomeAssessment() {
      // console.log(':exportSomeAssessment assListGroup=',this.assListGroup);
        for (let group in this.assListGroup) {
          for (let i in this.assListGroup[group]) {
            let item = this.assListGroup[group][i];
            if (item.checked) {
              console.log('::exportSomeAssessment item= ', item)
              let [columns, rows] = this.bildTableOne(item);
              this.prepareXlsFromTable(item.name, columns, rows).then(wbout => {
                let fname = `m360(${item.name}_${item.id}).xlsx`;
                console.log('::exportSomeAssessment saveAs fname= ', fname)
                saveAs(new Blob([this.parent.s2ab(wbout)], {type: 'application/octet-stream'}), fname);
              });

            }
          }
        }
    },
    reportSomeAssessment() {
      this.allReport=[];

      for (let group in this.assListGroup) {
        for (let i in this.assListGroup[group]) {
          let item = this.assListGroup[group][i];
          if (item.checked) {
            console.log('::exportSomeAssessment item= ', item)
            let [columns, rows] = this.bildTableOne(item);
            this.allReport.push({title:item.name, columns:columns, rows:rows});
          }
        }
      }
      this.showReport = true;

      // if (!this.exportStarted) {
      //   this.exportStarted = true;
      //   // this.exportAssessments(null, 'report', null);
      //   this.exportChecked('report');
      // }
    },
    showAssName(name){
      let ret = name;
      let arName=name.split('###');
      if(arName.length===2){
        ret = arName[1];
      }
      return ret;
    },
    getComments(entityId){
      let $this = this;
      let list=[];
      let nn=0;
      let params = {
        ENTITY: 'uni_comments',
        SORT: {ID: 'ASC'},
        FILTER: {'NAME': ''+entityId },
        LIMIT: 1000
      };

      console.log('::getComments for NAME=', entityId);
      return new Promise(resolve=> {
        // BX24.callMethod('entity.item.get', {
        //   ENTITY: 'uni_comments',
        //   SORT: {ID: 'ASC'},
        //   FILTER: {'NAME': ''+entityId },
        // }, function(result){
        //   if(result.error()){
        //     console.log(':: getComments '+result.error())
        //     resolve(list);
        //   }else{
        let url =  $this.parent.bx24Init({ AJAX_ACTION: 'entityItemGet', params:params });//.then(params=> {
        $this.parent.POST(url, $this.parent.bx24params, function (res) {

          let data = res.result;
            // console.log(`:${nn++}:getComments data=`, data)
            for(let i in data){
              // console.log('::getComments obj=', data[i].DETAIL_TEXT)
              try {
                let obj = JSON.parse(data[i].DETAIL_TEXT);
                obj.id = data[i].ID;
                list.push(obj);
              }catch (e) {
                $this.comments_id.push(data[i].ID);
                console.error(e);
              }
            }

            // if(result.more() && nn++<100)
            //   result.next();
            // else{
              // console.log('::getComments list=', list)
              resolve(list);
            // }
          // }
        }, "json");
      });
    },
    bildTableOne(item){
      let name = item.name;
      let date_start = item.date_start;// item.DATE_ACTIVE_FROM ? dayjs(item.DATE_ACTIVE_FROM).format('DD.MM.YYYY') : '';
      let date_finish = item.date_finish;// item.DATE_ACTIVE_TO ? dayjs(item.DATE_ACTIVE_TO).format('DD.MM.YYYY') : '';
      let persona = item.persona;// this.parent.isJSON(item.PROPERTY_VALUES.persona) ? JSON.parse(item.PROPERTY_VALUES.persona) : '';
      let experts = item.experts;// this.parent.isJSON(item.PROPERTY_VALUES.experts) ? JSON.parse(item.PROPERTY_VALUES.experts) : '';
      let scores = item.scores;// this.parent.isJSON(item.PROPERTY_VALUES.scores) ? JSON.parse(item.PROPERTY_VALUES.scores) : [];
      // let flags = item. item.PROPERTY_VALUES.flags ? JSON.parse(item.PROPERTY_VALUES.flags) : [];
      let competences = item.competences;// $this.parent.isJSON(item.DETAIL_TEXT) ? JSON.parse(item.DETAIL_TEXT) : {};
      // console.log(':***:competences=', competences);
      //колонки
      let columns = [
        {
          name: 'num',
          required: true,
          label: '№',
          align: 'left',
          field: (row) => row.num,
          format: (val) => `${val}`,
          sortable: false,
        },
        {
          name: 'competence',
          label: this.message[this.lang].comp_name+'/'+this.message[this.lang].comp_name,
          field: 'competence',
        },
      ];
      for (let expert of experts) {
        columns.push({
          name: 'expert_id_' + expert.id,
          label: expert.name,
          field: 'expert_id_' + expert.id,
        });
      }
      columns.push({
        name: 'average',
        label: this.message[this.lang].average,
        field: 'average',
      });
      columns.push({
        name: 'average1',
        label: this.message[this.lang].average1,
        field: 'average1',
      });
      // строки
      let rows = [];
      for (let ind_c in competences) {
        let competence = competences[ind_c]
        // console.log(':: competence= ', competence);
        //Компетенция
        let rowItem = {num: '', competence: competence.name, average: '', average1: ''};
        rows.push(rowItem);
        //Индикаторы
        for (let ind_i in competence.cats) {
          let indicator = competence.cats[ind_i];
          // console.log(':: indicator= ', indicator);
          rowItem = {num: parseInt(ind_i) + 1, competence: indicator.text};
          let scoreItem = scores.find(el=>{
            return el.ind_c==ind_c && el.ind_i==ind_i
          });
          // console.log(':: scoreItem= ', scoreItem);
          let summa = 0, nn = 0; // Сумма оценок по строке
          let summa1 = 0, nn1 = 0; // Сумма оценок по строке без оцениваемого
          if(scoreItem) {
            for (let expert of experts) {
              let score = 0;
              let exp_id = parseInt(expert.id);
              // Найдем оценку
              if (scoreItem.selected && scoreItem.selected.hasOwnProperty(exp_id)) {
                let value = parseInt(scoreItem.selected[exp_id]) * 10;
                // console.log(`:: persona.id=${persona.id} exp_id=${exp_id} value=${value} `);
                let label = ''
                // if(competence.subcats[indicator.text][scoreItem.ind_i]) {
                // label = competence.subcats[indicator.text][scoreItem.ind_i].text;
                try {
                  // console.log(':1: ',scoreItem)
                  // console.log(':2: ',indicator.text)
                  // console.log(':3: ',competence.subcats)
                  label = competence.subcats[indicator.text][parseInt(scoreItem.selected[exp_id])].text;
                }catch (e){
                  console.error(e);
                }
                // }
                // console.log(':: label=',label ,indicator.text, parseInt(scoreItem.selected[exp_id]),  competence.subcats[indicator.text])

                score = label + ' (' + value + ')';
                summa += value;
                nn++;
                if (parseInt(exp_id) !== parseInt(persona.id)) {
                  summa1 += value;
                  nn1++;
                }
              }
              rowItem['expert_id_' + expert.id] = score;
            }
            // console.log(`:: summa1=${summa1}  nn1=${nn1} `, summa1 / nn1, (summa1 / nn1).toFixed(1));
          }else{
            console.error(':: score not found ', ind_c, ind_i);
          }

          rowItem['average'] = nn > 0 ? (summa / nn).toFixed(1) : 0;
          rowItem['average1'] = nn1 > 0 ? (summa1 / nn1).toFixed(1) : 0;
          rows.push(rowItem);
        }
        rowItem = {num: '', competence: '', average: '', average1: ''};
        rows.push(rowItem);

      }
      // console.log('::columns=',columns );
      // console.log('::rows=',rows );
      return [columns, rows];
    },
    prepareXlsFromTable(title, columns, rows){
      let sheetNum=1;
      let $this=this;
      let fileType='binary';
      // if(navigator.userAgent.search(/BitrixDesktop/g)>=0){
      //   fileType='base64' //для десктопного приложения
      // }
      return new Promise(function(resolve, reject) {
        let wb = XLSX.utils.book_new();
        if (columns && rows) {

          let header = [];
          let wscols = [];

          for (let col in columns) {
            let column = columns[col];
            header.push(column.label);
            wscols.push({wch: 20});
          }

          let forXlsx = [];
          for (let i in rows) {
            let item = rows[i];
            // console.log(':-5-: item=',item);
            let oneRow={}
            for (let col in columns) {
              let column = columns[col];
              oneRow[column.name]=item[column.name]
            }

            forXlsx.push(oneRow);
          }

          let now = dayjs().format("DD.MM.YY HH:mm");
          let sheetName = 'sheet';
          let Head = [];
          Head.push(['', title, now]);
          Head.push([[]]);
          Head.push(header);

          let ws = XLSX.utils.aoa_to_sheet(Head);
          ws['!cols'] = wscols;

          XLSX.utils.sheet_add_json(ws, forXlsx, {
            skipHeader: true,
            origin: 4
          });

          XLSX.utils.book_append_sheet(wb, ws, sheetName);
        }
        let wbout = XLSX.write(wb, {bookType: 'xlsx', type: fileType});

        resolve(wbout);
      });
    },
    itemToExportLIst(item, exportList){
      let $this=this;
      exportList = exportList ? exportList : [];
      console.log(':***:itemToExportLIst',item);

      let name = this.showAssName(item.NAME);
      let date_start = item.DATE_ACTIVE_FROM ? dayjs(item.DATE_ACTIVE_FROM).format('DD.MM.YYYY') : '';
      let date_finish = item.DATE_ACTIVE_TO ? dayjs(item.DATE_ACTIVE_TO).format('DD.MM.YYYY') : '';
      let persona = this.parent.isJSON(item.PROPERTY_VALUES.persona) ? JSON.parse(item.PROPERTY_VALUES.persona) : '';
      let experts = this.parent.isJSON(item.PROPERTY_VALUES.experts) ? JSON.parse(item.PROPERTY_VALUES.experts) : '';
      let scores = this.parent.isJSON(item.PROPERTY_VALUES.scores) ? JSON.parse(item.PROPERTY_VALUES.scores) : [];
      let flags = item.PROPERTY_VALUES.flags ? JSON.parse(item.PROPERTY_VALUES.flags) : [];
      let competences =  $this.parent.isJSON(item.DETAIL_TEXT) ? JSON.parse(item.DETAIL_TEXT) : {};
      console.log(':***:competences=', competences);
      let isAnswered=false;

      if (experts && persona) {
        // console.log('::loadAssessment ', experts, persona);
        // console.log('::loadAssessment scores=', scores);

        exportList.push({
          C0: name,
          C1: date_start,
          C2: date_finish,
          class:'bg-positive',
          id:item.ID
        });
        exportList.push({
          C0: $this.message[$this.lang].persona,
          C1: persona.name,
        });

        let strExperts='';
        for(let i in experts){
          let exp = experts[i];
          strExperts += exp.name
          strExperts += i<experts.length-1?', ':'.';
        }

        exportList.push({
          C0: $this.message[$this.lang].experts,
          C1: strExperts,
        });

        exportList.push({
          C0:'',
          C1:'',
          C2:'',
        });
        exportList.push({
          C0:$this.message[$this.lang].rep_competence,
          C1:$this.message[$this.lang].rep_indicators,
          C2:$this.message[$this.lang].rep_scores,
          class:'bg-positive'
        });


        // let firstUid = true;
        // let header = {C0: assessment.name};
        // let legend = [];
        // let average = {};
        // let count = 0;
        // for (let j in scores) {
        //   count++;
        //   let score = scores[j];
        //
        //   console.log(":: score=", score)
        //
        //   exportList.push({
        //     C0: score.name
        //   });
        //
        //   let oneRow = {};
        //   // let n = 1;
        //   for (let i in score.indicators) {
        //     let indicator = score.indicators[i];
        //     exportList.push({
        //       C0: indicator.competence
        //     });
        //     let avg = 0, nn = 0;
        //     for (let uid in indicator.selected) {
        //       let expert = experts.find(element => {
        //         return element.id == uid});
        //       if (expert) {
        //         let value = indicator.selected[uid].value.index * 10;
        //         avg += value;
        //         nn++;
        //         exportList.push({
        //           C0: '',
        //           C1: expert.name,
        //           C2: value + ' (' + indicator.selected[uid].value.text + ')'
        //         });
        //         isAnswered=true;
        //       }
        //     }
        //     avg = (avg / nn).toFixed(1)
        //     exportList.push({
        //       C0: '',
        //       C1: $this.message[$this.lang].average,
        //       C2: avg,
        //       class:'bg-positive'
        //     });
        //   }
        //
        //   exportList.push({C0: ''});
        // }
      }
      return isAnswered;
    },
    exportChecked(modeReport){
      let $this=this;
      let exportList = [];
      let arBatch={};
      for(let i in $this.assessmentList){
        let itemAss = $this.assessmentList[i];
        // console.log('::exportChecked itemAss=', itemAss)
        if(itemAss.checked) {
          arBatch['get_' + itemAss.id] = ['entityItemGet',
            {
              ENTITY: 'uni_meth360180',
              SORT: {ID: 'ASC'},
              FILTER: {ID: parseInt(itemAss.id)},
            },
          ];
        }
      }
      if(Object.keys(arBatch).length>0) {
        $this.parent.callBatchIB(arBatch).then((res)=> {
          console.log('::exportChecked result=', res);
          for(let i in res.result){
            let items = result[i].answer.result;
            for(let ii in items){
              let item = items[ii];
              // console.log(':***:item =', item);
              // $this.itemToExportLIst(item, exportList);
              let [name, columns, row] = $this.bildTableOne(item);
              if (!modeReport) {
                $this.prepareXlsFromTable(title, columns, rows).then(wbout => {
                  let fname = `m360(${ii}).xlsx`;
                  saveAs(new Blob([$this.parent.s2ab(wbout)], {type: 'application/octet-stream'}), fname);
                });
              }

            }//for item
          }
          // console.log(':end:exportSomeAssessment exportList=', exportList);

          $this.exportStarted = false;
          $this.isLoadingSome = false;
          $this.checkAll = false;
          // this.checkIndex = Array(Object.keys(this.assessmentList).length).fill(false);
          $this.report = [];
          if (exportList && Object.keys(exportList).length > 0) {
            if (!modeReport) {
              // let Head = [];
              // Head.push([[]]);
              // for(let i in exportList){
              //   delete exportList[i].class
              // }
              // console.log('::exportChecked exportList=',exportList)
              //
              // let ws = XLSX.utils.aoa_to_sheet(Head);
              // let wscols = [{wch: 40}, {wch: 40}, {wch: 40}, {wch: 40}, {wch: 40}, {wch: 40}, {wch: 40}, {wch: 40}, {wch: 40}, {wch: 40}, {wch: 40}, {wch: 40}, {wch: 40}, {wch: 40}, {wch: 40}, {wch: 40}];
              // ws['!cols'] = wscols;
              //
              // XLSX.utils.sheet_add_json(ws, exportList, {
              //   skipHeader: true,
              //   origin: 0,
              // });
              //
              // let fname = 'meth360180.xlsx';
              // let wb = XLSX.utils.book_new();
              // XLSX.utils.book_append_sheet(wb, ws, '360-180');
              // let wbout = XLSX.write(wb, {bookType: 'xlsx', type: 'binary'});
              // saveAs(new Blob([$this.parent.s2ab(wbout)], {type: 'application/octet-stream'}), fname);
            } else {
              //this.report = exportList;
              let maxcol = 0;
              for (let i in exportList) {
                let lenrow = Object.keys(exportList[i]).length;
                maxcol = maxcol < lenrow ? lenrow : maxcol;
              }

              // let emptyRow=0;
              for (let i = 0; i < Object.keys(exportList).length; i++) {
                let rowItem = exportList[i];

                $this.report.push(rowItem);
              }


              for (let i = 0; i < maxcol; i++) {
                let name = 'C' + i;
                let item = {name: name, field: name, label: ' ', align: 'left'};
                $this.report_fields.push(item);
              }
              // console.log('::report ', $this.report_fields, $this.report)

              //сформируем xlxs для экспорта отчета
              let Head = [];
              Head.push([[]]);

              let ws = XLSX.utils.aoa_to_sheet(Head);
              let wscols = [{wch: 40}, {wch: 40}, {wch: 40}, {wch: 40}, {wch: 40}, {wch: 40}, {wch: 40}, {wch: 40}, {wch: 40}, {wch: 40}, {wch: 40}, {wch: 40}, {wch: 40}, {wch: 40}, {wch: 40}, {wch: 40}];
              ws['!cols'] = wscols;
              let forXLS = [...$this.report];

              for (let i in forXLS) {
                if (forXLS[i]._rowVariant) {
                  delete forXLS[i]._rowVariant;
                }
              }

              // console.log('::forXLS ', forXLS)

              XLSX.utils.sheet_add_json(ws, forXLS, {
                skipHeader: true,
                origin: 0,
              });

              let wb = XLSX.utils.book_new();
              XLSX.utils.book_append_sheet(wb, ws, '360-180');
              $this.wbout = XLSX.write(wb, {bookType: 'xlsx', type: 'binary'});

              //  /export

              $this.showReport = true;
            }
          } else {
            console.log(':4: exportList is empty');
          }

          $this.exportStarted = false;
        });
      }else{
        console.log('::exportChecked no checked items');
        $this.exportStarted = false;
      }
    },


    saveWbout(fname) {
      // if (navigator.userAgent.search(/BitrixDesktop/g) >= 0) {
      //   this.getFileLink(fname, btoa(this.wbout));
      //   this.wbout = '';
      // } else {
        saveAs(new Blob([this.parent.s2ab(this.wbout)], {type: 'application/octet-stream'}), fname);
      // }
    },
    getFileLink(name, file) {
      let $this = this;
      let ext = name.split('.').at(-1);
      let random_file_name = Math.floor(Math.random() * 10000) + '.' + ext;
      this.isLoadingXLS = true;
      //поиск хранилища группы
      BX24.callMethod(
        'disk.storage.getlist',
        {
          filter: {
            ENTITY_TYPE: 'common',
          },
        },
        function (result) {
          $this.isLoadingXLS = false;
          if (result.error()) {
            console.error(result.error());
          } else {
            let res = result.data();
            // console.log('::disk.storage.getlist result=', res);
            $this.isLoadingXLS = true;
            BX24.callMethod(
              'disk.storage.uploadfile',
              {
                id: res[0].ID,
                data: {
                  NAME: random_file_name,
                },
                fileContent: [name, file],
                generateUniqueName: true,
              },
              function (result) {
                $this.isLoadingXLS = false;
                if (result.error()) console.error(result.error());
                else {
                  // console.log('::disk.storage.uploadfile result=', result.data());
                  let res = result.data();
                  $this.file_download_link = res.DOWNLOAD_URL;
                }
              }
            );
          }
        }
      );
    },
    loadAssessment(id) {
      const $this = this;
      return new Promise(function (resolve, reject) {
        let params = {
          ENTITY: 'uni_meth360180',
          SORT: {ID: 'DESC'},
          FILTER: {ID: parseInt(id)},
        };

        // BX24.callMethod(
        //   'entity.item.get',
        //   {
        //     ENTITY: 'uni_meth360180',
        //     SORT: {ID: 'DESC'},
        //     FILTER: {ID: parseInt(id)},
        //   },
        //   function (result) {
        //     if (!result.error()) {
        let url =  $this.parent.bx24Init({ AJAX_ACTION: 'entityItemGet', params:params });//.then(params=> {
        $this.parent.POST(url, $this.parent.bx24params, function (res) {

          let data = res.result;
              if (data && Object.keys(data).length > 0) {
                resolve(data[0]);
              } else {
                reject('no such assessment for id ' + id);
              }
            // } else {
            //   reject(result.error());
            // }
          }, "json");
      });
    },
    toggleForm() {
      this.initForm();
      this.showForm = !this.showForm;
      this.parent.tarifAlert('meth360180').then((isOk) => {
        if (!isOk) {
          console.error(':: Tests Exceeded the tariff limit');
        }
      });
    },
    delAssessment(item, index) {
      let $this = this;
      $this.parent.modal_title = this.message[this.lang].del_ass_title;
      $this.parent.modal_message = this.message[this.lang].del_ass_mess + ' "' + item.name + '"?';
      $this.parent.modal_obj = '';
      $this.parent.modal_ok = this.message[this.lang].remove;
      $this.parent.modal_list = false;
      $this.parent.modal_filterFn = null;
      // eslint-disable-next-line vue/no-mutating-props
      this.parent.okModal = () => {
        $this.parent.hideModal();
        let params = {
          ENTITY: 'uni_meth360180',
          ID: item.id,
        }
        $this.isLoading = true;
        // BX24.callMethod(
        //   'entity.item.delete',
        //   {
        //     ENTITY: 'uni_meth360180',
        //     ID: item.id,
        //   },
        //   (resp) => {
        let url =  $this.parent.bx24Init({ AJAX_ACTION: 'entityItemDel', params:params });//.then(params=> {
        $this.parent.POST(url, $this.parent.bx24params, function (res) {

          $this.isLoading = false;
          $this.assessmentList.splice(index, 1);
        }, "json");
      };
      BX24.scrollParentWindow(0);
      this.parent.showModal();
    },
    clearDates() {
      this.DATE_START = '';
      this.DATE_FINISH = '';
    },
    isCompetencesValid(list) {
      if(!list || list.length==0) return -1;
      for(let item of list){
        let res = this.isCompetenceItemValid(item);
        if(res<0){
          return res;
        }
      }
      return true;
    },
    isCompetenceItemValid(item) {
      let ret = 1;
      if(!item){
        ret = -4
      }else {
        if (!item.cats || Object.keys(item.cats).length == 0) {
          ret = -1;
        } else if (!item.subcats || Object.keys(item.subcats).length == 0) {
          ret = -2;
        } else {
          for (let i in item.cats) {
            let item_cat = item.cats[i];
            if (!item.subcats[item_cat.value] || Object.keys(item.subcats[item_cat.value]).length == 0) {
              ret = -3;
              break;
            }
          }
        }
      }
      // console.log('::isCompetencesValid ', ret, item);
      return ret;
    },
    isNoFormValid() {
      this.alert_message = '';
      let a = this.assessment; //this.assessment.competences
      // console.log('::isNoFormValid assessment=',this.assessment);
      let message = '';
      if(!a.name){
        message = ' ' + this.message[this.lang].name;
      }
      if ((!a.persona || Object.keys(a.persona).length == 0) &&
              (!a.list || (!a.list[0].persona || a.list[0].persona.length==0)) ) {
        message += message ? ', ' : ' ';
        message += this.message[this.lang].alert_persona;
      }
      if (!a.experts || Object.keys(a.experts).length == 0 &&
        (!a.list || (!a.list[0].experts || a.list[0].experts.length==0)) ) {
        message += message ? ', ' : ' ';
        message += this.message[this.lang].alert_experts;
      }
      if (!this.startFinish || !this.startFinish.from || !this.startFinish.to) {
        message += message ? ', ' : ' ';
        message += this.message[this.lang].alert_dates;
      }

      let isComp = this.isCompetencesValid(a.competences);
      if (isComp === -1) {
        message += message ? ', ' : ' ';
        message += this.message[this.lang].alert_competences;
      } else if (isComp === -2) {
        message += message ? ', ' : ' ';
        message += this.message[this.lang].alert_scores;
      } else if (isComp === -3) {
        message += message ? ', ' : ' ';
        message += this.message[this.lang].alert_scores;
      }

      if (message && this.tabActiveName === 'assessment') {
        this.alert_message = this.message[this.lang].alert_message + ':' + message;
        this.assessment.active = 'N';
        // this.showForm = false;
        // this.$nextTick(() => {
        //   this.showForm = true;
        // });
      }
      // console.log('::isNoFormValid message=',!!message, message);
      return !!message;

      // console.log(':: is active=',this.assessment.active);
    },

    editAssessment(item, index) {
      console.log('::editAssessment item=', item);

      this.assessment = item;
      if (Array.isArray(item.persona)) {
        this.assessment.list = [{persona: item.persona, experts: item.experts}];
      } else {
        this.assessment.list = [{persona: [item.persona], experts: item.experts}];
      }

      //Список для удаления оценок
      this.listForDelete=[{value:-1, label: this.message[this.lang].all}];
      for(let expert of item.experts){
        this.listForDelete.push({value: expert.id, label: expert.name});
      }

      this.startFinish.from = item.date_start ? item.date_start : '';
      this.startFinish.to = item.date_finish ? item.date_finish : '';
      this.showForm = true;
      this.editIndex = index;
      this.isNoFormValid();
    },
    editAssessmentGroup(item, index) {
      console.log('::editAssessment item=', item);
      this.assessment = item[0];
      this.assessment.list = [];
      item.forEach((elem) => {
        if (Array.isArray(item.persona)) {
          this.assessment.list.push({persona: elem.persona, experts: elem.experts, id: elem.id});
        } else {
          this.assessment.list.push({persona: [elem.persona], experts: elem.experts, id: elem.id});
        }
      });

      this.startFinish.from = item[0].date_start ? item[0].date_start : '';
      this.startFinish.to = item[0].date_finish ? item[0].date_finish : '';
      this.showForm = true;
      this.editIndex = index;
    },
    competences_saved(oneComp) {
      console.log('::competences_saved oneComp=',oneComp, this.assessment)

      if(this.assessment) {
        this.assessment.competences = this.assessment.competences ? this.assessment.competences : [];
        let foundIndex = this.assessment.competences.findIndex(el => {
          return oneComp.id == el.id
        });
        console.log('::competences_saved ', oneComp.id, foundIndex, oneComp);
        if (foundIndex >= 0) {
          this.assessment.competences[foundIndex] = oneComp;
        } else {
          oneComp.id = Math.floor(Math.random() * (999 - 1)) + 1;
          this.assessment.competences.push(oneComp)
        }
        console.log('::assessment.competences= ', this.assessment.competences);
        // this.assessment.competences = list;
      }
      this.isNoFormValid();
    },
    customFormatter(date) {
      let ret = '';
      let format = this.lang == 'ru' ? 'DD.MM.YYYY' : 'DD-MM-YYYY';
      if (!date) {
        ret = dayjs().format(format);
      } else {
        ret = dayjs(date).format(format); // HH:mm:ss');
      }
      return ret;
    },
    update1Assessment(item){
      let $this = this;
      console.log('::update1Assessment item=',item);
      // let method = 'entity.item.update';
      let params = {
        ID: item.id,
        ENTITY: 'uni_meth360180',
        PROPERTY_VALUES: {
          persona: JSON.stringify(item.persona),
          experts: JSON.stringify(item.experts),
          flags: JSON.stringify(item.flags),
        },
      };
      // BX24.callMethod(method, params, function (res) {
      let url =  $this.parent.bx24Init({ AJAX_ACTION: 'entityItemUpdate', params:params });//.then(params=> {
      $this.parent.POST(url, $this.parent.bx24params, function (res) {
        console.log(res);
      });
    },
    assessmentCreate(evt) {
      console.log(':1:assessmentCreate ', this.assessment);
      // console.log('::assessmentCreate this.assListGroup=', this.assListGroup);
      if (evt) evt.preventDefault();
      let unicId = this.assessment.group; //1000000;
      if(!unicId && this.assListGroup && this.assListGroup[0] && this.assListGroup[0][0] && this.assListGroup[0][0].id){
        unicId = parseInt(this.assListGroup[0][0].group) + 1;
      }if(!unicId){
        unicId = 1000000;
      }
      // console.log('::assessmentCreate unicId,json=', unicId, JSON.stringify(this.assessment.competences));
      let $this = this;
      this.isLoading = true;
      if (this.assessment.list) {
        // console.log(':2:assessmentCreate list=', this.assessment.list);
          let message = this.message[this.lang].you_ass_360;
          let nn=1;
          let arBatch={};
          let personas_id=[];
          for (let elem of this.assessment.list) {
            for (let i in elem.persona) {
              let man=elem.persona[i];
              let params = {
                ENTITY: 'uni_meth360180',
                NAME: `${unicId}###${this.assessment.name}`,
                PREVIEW_TEXT: this.assessment.description,
                DETAIL_TEXT: JSON.stringify(this.assessment.competences),
                DATE_ACTIVE_FROM: dayjs(this.assessment.date_start).format('YYYY-MM-DD'),
                DATE_ACTIVE_TO: dayjs(this.assessment.date_finish).format('YYYY-MM-DD'),
                ACTIVE: this.assessment.active,
                PROPERTY_VALUES: {
                  persona: JSON.stringify(man),
                  experts: JSON.stringify(elem.experts),
                  flags: JSON.stringify(this.assessment.flags),
                },
              };
              let method = 'entityItemAdd';
              if (this.assessment && parseInt(this.assessment.id) > 0) {
                params.ID = parseInt(this.assessment.id);
                method = 'entityItemUpdate';
              }
              arBatch['ass_'+unicId+'_'+nn] = [method, params];
              // Уведомление в ЛК
              if(this.assessment.active==='Y') {
                personas_id.push(man.id);
                this.parent.notifyLK(man.id, message, this.parent.user_current);

                for (let exp of elem.experts){
                  personas_id.push(exp.id);
                  this.parent.notifyLK(exp.id, message, this.parent.user_current);
                }
              }

              nn++;
            }
          }
        // console.log('::assessmentCreate arBatch=', arBatch)
          this.parent.callBatchIB(arBatch).then((result)=> {
            console.log('::assessmentCreate callBatch ok')
            $this.isLoading = false;
            $this.showForm = false;

            // Уведомления
            // $this.parent.isNoteSet(personas_id, "e360").then(checks => {
            //   if (Object.keys(checks).length > 0) {
            //     let arBatch2 = {};
            //     for (let uid of checks) {
            //       arBatch2['note_' + uid] = ['im.notify', {to: uid, message: message, type: 'SYSTEM'}];
            //     }
            //     $this.parent.callBatch(arBatch2);
            //   }
            // });

            $this.loadAssessments().then(() => {
              $this.createGroupList();
              $this.initForm();
            });
          });
      } else {
        console.log(':3:assessmentCreate no list=');
        this.assessment.list = [{persona: this.assessment.persona, experts: this.assessment.experts}];
        let method = 'entityItemAdd';
        let params = {
          ENTITY: 'uni_meth360180',
          NAME: `${unicId}###${this.assessment.name}`, //`${Math.floor(Math.random() * 100000)}###${this.assessment.name}`,
          PREVIEW_TEXT: this.assessment.description,
          DETAIL_TEXT: JSON.stringify(this.assessment.competences),
          DATE_ACTIVE_FROM: dayjs(this.assessment.date_start).format('YYYY-MM-DD'),
          DATE_ACTIVE_TO: dayjs(this.assessment.date_finish).format('YYYY-MM-DD'),
          ACTIVE: this.assessment.active,
          PROPERTY_VALUES: {
            persona: JSON.stringify(this.assessment.persona),
            experts: JSON.stringify(this.assessment.experts),
            flags: JSON.stringify(this.assessment.flags),
          },
        };
        if (this.assessment && parseInt(this.assessment.id) > 0) {
          params.ID = parseInt(this.assessment.id);
          method = 'entityItemUpdate';
        }
        // BX24.callMethod(method, params, function (res) {
        //   //console.log(res);
        //   $this.isLoading = false;
        //   if (res.answer.error) {
        //     // console.error(res.answer.error_description);
        //     $this.msg = res.answer.error_description;
        //   } else {
        let url =  $this.parent.bx24Init({ AJAX_ACTION: 'entityItemDel', params:params });//.then(params=> {
        $this.parent.POST(url, $this.parent.bx24params, function (res) {
          $this.isLoading = false;
          if (res.result) {
              let id = 0;
              //
              if (params.ID) {
                id = params.ID;
                $this.assListGroup[$this.editIndex] = $this.assessment;
                // console.log('The assessment saved! '+id);
                // $this.msg = $this.message[$this.lang].lesson_saved;
              } else {
                id = res.result;
                $this.assessment.id = id;
                $this.assListGroup.splice(0, 0, $this.assessment);
                // console.log('New assessment added! ',id,$this.assessmentList);
              }
            }
            $this.showForm = false;
            $this.initForm();
          // }
        }, "json");
      }
    },
    initForm() {
      this.assessment = {
        id: '',
        name: '',
        date_start: '',
        date_finish: '',
        description: '',
        persona: [],
        experts: [],
        competences: [],
        list: [{persona: [], experts: []}],
        flags:{acc:'N', com:'',hide_fio:'Y'}
      };
    },
    delPersona(index, ind) {
      if (this.assessment.list) {
        this.assessment.list[ind].persona.splice(index, 1);
      } else {
        this.assessment.persona.splice(index, 1);
      }
      this.isNoFormValid();
    },
    personaAdd(ind) {
      let $this = this;
      $this.parent.modal_list_selected = '';
      this.parent.getUsersExtra('activeOnly').then((users) => {
        $this.parent.modal_list = [];
        for (let i in users) {
          let item = users[i];
          // console.log(':+++++: item=',item)
          $this.parent.modal_list.push({value: item.id, text: item.name, name:item.name, id: item.id, deps: item.deps});
        }
        $this.parent.modal_flt = '';
        $this.parent.model = null;
        $this.parent.modal_list_single = false;
        $this.parent.modal_list_label = $this.message[$this.lang].add_persona;
        $this.parent.modal_title = $this.message[$this.lang].selectusers_title;
        $this.parent.modal_message = $this.message[$this.lang].selectuser_message;
        $this.parent.modal_ok = $this.message[$this.lang].selectusers_add;
        $this.parent.modal_cancel = $this.message[$this.lang].selectusers_finish;

        //Поиск юзера по порталу
        $this.parent.modal_filterFn= function (needle){
          return new Promise(resolve => {
            let filter ={
              "%PROPERTY_last_name":`%${needle}%`,
            };
            $this.parent.getUsersExtra('activeOnly', filter).then(users=>{
              let list = [];
              if(users && users.length>0) {
                for (let i in users) {
                  let item = users[i];
                  list.push({value: item.id, text: item.name, name:item.name, id: item.id, deps: item.deps});
                }
              }else{
                list.push({text: $this.parent.message[$this.lang].not_found, value:0, disable:true});
              }
              console.log(':: list=',list)
              resolve(list);
            })
          });
        };

        $this.parent.okModal = () => {
          console.log('$this.parent.modal_list_selected', $this.parent.modal_list_selected);
          let users_id = $this.parent.modal_list_selected.map((elem) => Number(elem));
          // console.log(user_id);
          let itemUsers = [];
          $this.parent.modal_list.find((element, index, array) => {
            users_id.forEach((userId) => {
              if (element.id == userId) {
                itemUsers.push(element);
              }
            });
          }, $this);
          // let itemUser = users.find((element, index, array) => {
          //   if (element.id == user_id) return true;
          //   else return false;
          // },

          if ($this.assessment.list[ind].persona.length > 0) {
            itemUsers.forEach((user) => {
              if (!$this.assessment.list[ind].persona.find((item) => item.id == user.id)) {
                $this.assessment.list[ind].persona.push(user);
              }
            });
            $this.parent.getSubAccounts($this.assessment.list[ind].persona).then(res=>{
              $this.assessment.list[ind].persona = $this.assessment.list[ind].persona.concat(res);
              console.log(':1: persona=',$this.assessment.list[ind].persona);
            });

          } else if ($this.assessment.list) {
            $this.assessment.list[ind].persona.push(...itemUsers);
            $this.parent.getSubAccounts($this.assessment.list[ind].persona).then(res=>{
              $this.assessment.list[ind].persona = $this.assessment.list[ind].persona.concat(res);
              console.log(':2: persona=',$this.assessment.list[ind].persona);
            });

          } else {
            itemUsers.forEach((user) => {
              if (!$this.assessment.persona.find((item) => item.id == user.id)) {
                $this.assessment.persona.push(user);
              }
            });
          }
          //{id:user.id, name: user.name}
          $this.isNoFormValid();
          $this.parent.hideModal();

        };
        this.parent.showModal();
      });
    },
    delExpert(index, ind) {
      if (this.assessment.list) {
        this.assessment.list[ind].experts.splice(index, 1);
      } else {
        this.assessment.experts.splice(index, 1);
      }
      this.isNoFormValid();
    },
    expertsAdd(ind) {
      let $this = this;
      if (!$this.assessment.experts) {
        $this.assessment.experts = [];
      }
      $this.parent.modal_list_selected = '';
      this.parent.getUsersExtra('activeOnly').then((users) => {
        $this.parent.modal_list = [];
        for (let i in users) {
          let item = users[i];
          $this.parent.modal_list.push({value: item.id, text: item.name, name:item.name, id: item.id});
        }
        $this.parent.modal_flt = '';
        $this.parent.model = null;
        $this.parent.modal_list_single = false;
        $this.parent.modal_list_label = $this.message[$this.lang].add_experts;
        $this.parent.modal_title = $this.message[$this.lang].selectusers_title;
        $this.parent.modal_message = $this.message[$this.lang].selectusers_message;
        $this.parent.modal_ok = $this.message[$this.lang].selectusers_add;
        $this.parent.modal_cancel = $this.message[$this.lang].selectusers_finish;

        //Поиск юзера по порталу
        $this.parent.modal_filterFn= function (needle){
          return new Promise(resolve => {
            let filter ={
              "%PROPERTY_last_name":`%${needle}%`,
            };
            $this.parent.getUsersExtra('activeOnly', filter).then(users=>{
              let list = [];
              if(users && users.length>0) {
                for (let i in users) {
                  let item = users[i];
                  list.push({value: item.id, text: item.name, name:item.name, id: item.id});
                }
              }else{
                list.push({text: $this.parent.message[$this.lang].not_found, value:0, disable:true});
              }
              console.log(':: list=',list)
              resolve(list);
            })
          });
        };

        $this.parent.okModal = () => {
          // console.log(':: okModal ', $this.parent.modal_list_selected);
          for (let i in $this.parent.modal_list_selected) {
            let user_id = parseInt($this.parent.modal_list_selected[i]);
            let itemUser = $this.parent.modal_list.find((element, index, array) => {
              if (element.id == user_id) return true;
              else return false;
            }, $this);
            if ($this.assessment.list) {
              if (
                !$this.assessment.list[ind].experts.find((element, index, array) => {
                  if (element.id == itemUser.id) return true;
                  else return false;
                }, $this)
              ) {
                $this.assessment.list[ind].experts.push(itemUser);
              }
              $this.parent.getSubAccounts($this.assessment.list[ind].experts).then(res=>{
                $this.assessment.list[ind].experts = $this.assessment.list[ind].experts.concat(res);
                console.log(':2: experts=',$this.assessment.list[ind].experts);
              });
            } else {
              if (
                !$this.assessment.experts.find((element, index, array) => {
                  if (element.id == itemUser.id) return true;
                  else return false;
                }, $this)
              ) {
                $this.assessment.experts.push(itemUser);
              }
            }
          }
          $this.isNoFormValid();
          $this.parent.hideModal();

        };
        this.parent.showModal();
      });
    },
    loadAssessmentsMore() {
      let last_id = this.assessmentList[this.assessmentList.length - 1].id;
      // console.log('::loadAssessments last_id, this.last_id =', last_id, this.last_id);
      this.loadAssessments(this.last_id).then(() => {
        this.createGroupList();
      });
    },
    loadAssessments(last_id) {
      console.log('::loadAssessments last_id=', last_id, this.parent.search);
      const $this = this;

      if(!last_id || $this.parent.search){
        $this.assessmentList = [];
        $this.assListGroup = [];
        $this.listForDelete = [{value:-1, label:this.message[this.lang].all}];
      }

      last_id = last_id ? parseInt(last_id) : 999999999;

      return new Promise((resolve) => {
        // $this.checkIndex = $this.checkIndex ? $this.checkIndex : [];
        // $this.assessmentList = $this.assessmentList ? $this.assessmentList : [];
        $this.isLoading = true;
        let filter =  {'<ID': last_id};
        if($this.parent.search) {
          filter = {
            '1': {
              LOGIC: "AND",
              '%PROPERTY_persona': `%${$this.parent.search}%`
              // '%PROPERTY_experts': `%${$this.parent.search}%`
            }
          };
          console.log(':*search*:loadAssessments filter=', filter);
        }

        let params = {
          ENTITY: 'uni_meth360180',
          SORT: {ID: 'DESC'},
          FILTER: filter,
        }

        let url =  $this.parent.bx24Init({ AJAX_ACTION: 'entityItemGet', params:params });//.then(params=> {
        $this.parent.POST(url, $this.parent.bx24params, function (res) {
          $this.isLoading = false;
          let arResult = res.result;
              if (arResult && arResult.length > 0) {
                $this.isShowMore = true;
                console.log(':loadAssessments arResult=', arResult);
                for (let i in arResult) {
                  // if(i>3) break; для теста подгрузки страниц
                  let item = arResult[i];
                  $this.last_id = item.ID;
                  let isQuota = $this.maxCount > Object.keys($this.assessmentList).length;
                  //
                  if (isQuota) {
                    let flags = item.PROPERTY_VALUES.flags ? JSON.parse(item.PROPERTY_VALUES.flags) : {
                      acc: 'N',
                      com: '',
                      hide_fio:'Y'
                    };
                    if(!flags.hide_fio){
                      flags.hide_fio = 'Y';
                    }
                    let scores = [];
                    try{
                      scores = $this.parent.isJSON(item.PROPERTY_VALUES.scores) ? JSON.parse(item.PROPERTY_VALUES.scores) : [];
                    }catch (e) {
                      console.error(e);
                    }
                    let experts = $this.parent.isJSON(item.PROPERTY_VALUES.experts) ? JSON.parse(item.PROPERTY_VALUES.experts) : [];
                    let persona = $this.parent.isJSON(item.PROPERTY_VALUES.persona) ? JSON.parse(item.PROPERTY_VALUES.persona) : [];
                    let competences = '';
                    try {
                      // console.log(`::${item.ID} ${item.NAME} isJSON=`, $this.parent.isJSON(item.DETAIL_TEXT))
                      if(item.DETAIL_TEXT) {
                        competences = JSON.parse(item.DETAIL_TEXT);
                      }
                    } catch (e) {
                      console.error(e);
                      console.log(`:bad json:${item.ID} ${item.NAME}`)
                    }
                    if (item.NAME.split('###').length == 1) {
                      $this.assessmentList.push({
                        dateCreate: item.DATE_CREATE,
                        id: item.ID,
                        name: item.NAME,
                        description: item.PREVIEW_TEXT,
                        date_start: item.DATE_ACTIVE_FROM,
                        date_finish: item.DATE_ACTIVE_TO,
                        persona: persona,
                        experts: experts,
                        scores: scores,
                        competences: competences,
                        active: item.ACTIVE,
                        checked:false,
                        flags: flags
                      });
                    } else {
                      $this.assessmentList.push({
                        id: item.ID,
                        name: item.NAME.split('###')[1],
                        group: item.NAME.split('###')[0],
                        description: item.PREVIEW_TEXT,
                        date_start: item.DATE_ACTIVE_FROM,
                        date_finish: item.DATE_ACTIVE_TO,
                        persona: persona,
                        experts: experts,
                        scores: scores,
                        competences: competences,
                        active: item.ACTIVE,
                        checked:false,
                        flags: flags
                      });
                    }
                    //Список для удаления оценок
                    for(let expert of experts){
                      if(!$this.listForDelete.find(el=>{return expert.id==el.value})) {
                        $this.listForDelete.push({value: expert.id, label: expert.name});
                      }
                    }
                    // for(let i in scores.indicators) {
                    //   let ind=scores.indicators[i];
                    //   for(let uid in ind.selected){
                    //     if(!$this.listForDelete.find(el=>{return uid==el.value})){
                    //       let label = experts.find(el=>{return el.id==uid});
                    //       if(label) {
                    //         $this.listForDelete.push({value: uid, label: label.name});
                    //       }
                    //     }
                    //   }
                    // }

                  } else break;
                }
                // console.log(':loadAssessments checkIndex=', $this.checkIndex);
              } else $this.isShowMore = false;

            resolve($this.assessmentList);
          },"json");
      });
    },
    clearScores(item) {
      // console.log('::clearScores ', this.assessment.scores);
      let $this = this;
      $this.parent.modal_list = false;
      $this.parent.modal_title = this.message[this.lang].clrscores_title;
      $this.parent.modal_message = this.message[this.lang].clrscores_message+' "'+item.label+'"';
      $this.parent.modal_params = {};
      $this.parent.modal_filterFn = null;
      $this.parent.okModal = () => {
        if(item.value<0){
          $this.assessment.scores=[];
          // for(let j in $this.assessment.scores) {
          //   $this.assessment.scores[j].indicators={}
          // }
        }else{
          // delete $this.assessment.scores[item.value];
          for(let j in $this.assessment.scores) {
            for (let i in $this.assessment.scores[j]) {
              delete $this.assessment.scores[j].selected[item.value]
            }
          }
          let findIndex = this.listForDelete.findIndex(el=>{return el.value == item.value});
          if(findIndex>=0){
            this.listForDelete.splice(findIndex,1);
          }
        }
        console.log('this.assessment.scores=',$this.assessment.scores);

        $this.parent.assessmentUpdate(this.assessment).then(
          (res) => {
            // console.log(res);
          },
          (err) => console.error(err)
        );
        $this.initTable();
        $this.parent.hideModal();
      };
      this.parent.showModal();
    },
    got_back() {
      if (this.showForm) {
        this.showForm = false;
      } else if (this.showReport) {
        this.showReport = false;
      } else {
        this.$emit('goback', this.goback);
      }
    },
  },

  components: {
    // eslint-disable-next-line vue/no-unused-components
    CatsEdit,
    apexcharts: VueApexCharts,
    AssessmentsList,
    Comments
  },
};
</script>

<style scoped>
.q-table thead {
  background-color: #f5f2f2;
}
</style>
