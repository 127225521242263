<template>
  <div style="padding-left: 15px; padding-bottom: 100px; margin-top: 3px">
    <div v-if="parent.isMobile" class="row justify-between items-end text-left" >
      <div class="col-12">
        <div class="q-gutter-x-sm">
          <q-btn color="positive" no-caps flat round icon="transit_enterexit" @click="$emit('goback', goback);"/>
          <q-btn unelevated color="primary" >
            {{message[lang].title}} <span v-if="parent.showSysInfo=='Y' && (parent.isModer || parent.isAdmin)"> - {{lesson.ID}}</span>
          </q-btn>
          <q-img  style="cursor: pointer; height: 24px; width: 19px;"  src="img/card/cours/secondary/calendar.svg"/> {{parent.formatDate(lesson)}}
          <span class="h5 text-positive">{{pointsShow}}</span>
        </div>
      </div>
    </div>

    <div v-else v-bind:class="[{'margin-top-30' : parent.isAdmin||parent.isModer, 'margin-top_15' : !parent.isAdmin&&!parent.isModer }]">
      <div class="row items-justify items-center text-left">
        <div class="col-12 text-left q-pl-md">
          <q-btn color="positive" no-caps flat rounded icon="transit_enterexit" :label="message[lang].goback" @click="$emit('goback', goback);"/>
          <div class="q-my-sm">
            <q-btn unelevated color="secondary" >
              {{message[lang].title}}
              <span v-if="parent.showSysInfo=='Y' && (parent.isModer || parent.isAdmin)"> {{lesson.ID}}</span>
            </q-btn>
            {{message[lang].published}}&ensp;
            <q-img  style="cursor: pointer; height: 24px; width: 19px;"  src="img/card/cours/secondary/calendar.svg"/>
            {{parent.formatDate(lesson)}}&nbsp;
            <span class="h5 text-positive">{{pointsShow}}</span>
          </div>
        </div>
    </div>
      <div class="text-right q-pr-md text-positive">
        {{message[lang].direct_link}}:
        <input style="border: none; background: none; border-radius: 15px"
               class="text-positive"
               v-on:focus="$event.target.select()"
               :ref="'clone'+lesson.ID"
               readonly
               :value="parent.appURL+'?lesson='+lesson.ID"/>

        <q-btn flat round color="positive" icon="content_copy"
               @click="copy(lesson.ID)">
        </q-btn>

      </div>

    </div>

  <div class="h3 text-accent q-mb-md">
    {{lesson.NAME}}
  </div>

  <div v-if="lesson.STUFF && isNaN(parseInt(lesson.STUFF))" class="h4 text-accent q-mb-md">
    <q-input
          v-model="lesson.STUFF"
          type="textarea"
          borderless
          readonly
          class="h4"
          style="width: 100%"
        />
  </div>

    <!-- Навигация по курсу -->
    <div v-if="nav" class="q-mb-md">
      <div class="row justify-end q-my-md" v-if="!lessonItem.tree">
        <div class="col-5 text-right">
          <q-btn-dropdown flat no-caps rounded color="primary" :label="message[lang].course_navigation">
            <q-list>
              <q-item v-for="element in nav" :key="element.id"  v-close-popup :clickable="!element.dsbld" @click="viewItem(element)" :disable="element.stage === 'D'">
                <q-item-section>
                  <q-item-label>
                    <span v-if="!element.dsbld">{{message[lang][element.type]}}:{{parent.showSysInfo=='Y'?element.id:''}}</span>
                    <span :class="element.dsbld?'text-positive':'text-accent'"> {{element.name}}</span>
                  </q-item-label>
                </q-item-section>
              </q-item>
            </q-list>
          </q-btn-dropdown>
        </div>
      </div>
      <!-- Кнопки завершения/перехода   -->
      <div class="row justify-between">
        <div class="col-md-4 col-xs-12 text-left">
          <q-btn unelevated rounded no-caps :disable="!isNavFinished && lessonItem.alw_next==2" @click="completeTheCourse" color="warning" :label="message[lang].complete_the_course"/>
        </div>
        <div class="col-md-4 col-xs-12 text-left" v-if="!lessonItem.tree">
          <q-btn unelevated rounded no-caps :disable="!isLastNav || lessonItem.alw_next==2 && !isModuleCompleted" @click="getNextNav" color="primary" :label="message[lang].get_next"/>
        </div>
        <div class="col-md-4 col-xs-12 text-left">
          <q-btn unelevated rounded no-caps :disable="isModuleCompleted"
            @click="moduleComplete" :color="isModuleCompleted?'secondary':'warning'" :label="message[lang][isModuleCompleted?'module_completed':'module_complete']"/>
        </div>
      </div>
    </div>
    <!-- Для самостоятельного урока в программе  -->
    <div v-else-if="lessonItem.program_chedule_id">
      <div class="row justify-start">
        <div class="col-md-4 col-xs-12 text-left">
          <q-btn rounded no-caps
            @click="programModuleComplete('courseReturn')"
            color="secondary"
            :label="message[lang]['module_complete']"/>
        </div>
      </div>
    </div>
    <div class="row">
      <!-- Longread   -->
      <div class="col-12 q-pa-md" v-if="lesson.DETAIL_TEXT && mode==='L'">
        <q-card flat class="bg-negative" style="max-width: 80vw; box-shadow: none">
          <q-card-section>
            <div  v-html="parent.showBase64Text(lesson.DETAIL_TEXT.text)"/>
          </q-card-section>
        </q-card>

        <div v-for="(item,index) in old_videoFiles" :key="index">
          <div class="text-positive h4">{{item.N}}</div>
          <video v-if="item.data" :src="item.data" controls allow="fullscreen" style="width: 100%">
            Sorry, your browser doesn't support embedded videos!
          </video>
          <div v-else class="column items-center">
            <q-spinner-ios color="positive" size="lg"/>
          </div>
        </div>
      </div>
      <!-- Ссылка youtube, SCORM-->
      <div class="col-12" v-if="lesson &&  lesson.PROPERTY_VALUES && lesson.PROPERTY_VALUES.youtube && (!mode || mode==='Y' || mode==='S' && showSCORM)"  >
        <!--        <q-btn icon="done" @click="initSCORM('noEvents')"  />-->

        <div v-if="typeof(lesson.PROPERTY_VALUES.youtube) == 'string'" style="min-height: 460px">
              <div class="resp-container">
                <iframe class="resp-iframe" :src="`https://www.youtube.com/embed/${lesson.PROPERTY_VALUES.youtube}`" frameborder="0" allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" ></iframe>
              </div>
          </div>
          <div v-else-if="lesson.PROPERTY_VALUES.youtube.provider.indexOf('youtu')!=-1 && lesson.PROPERTY_VALUES.youtube.id" style="min-height: 460px">
              <div class="text-right q-ma-sm">
               <q-btn v-if="!parent.isMobile && !parent.isDesktop" flat color="secondary" icon-right="open_in_new" 
                  no-caps rounded
                  @click="openVideoInNewWindow(`https://www.youtube.com/embed/${lesson.PROPERTY_VALUES.youtube.id}`)" :label="message[lang].open_new_tab"/>
              </div>
              <div class="resp-container">
                <iframe class="resp-iframe" :src="`https://www.youtube.com/embed/${lesson.PROPERTY_VALUES.youtube.id}`" frameborder="0" allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" ></iframe>
              </div>
          </div>
          <div v-else-if="lesson.PROPERTY_VALUES.youtube.provider.indexOf('rutube')!=-1 && lesson.PROPERTY_VALUES.youtube.id" style="min-height: 460px">
              <div class="text-right q-ma-sm">
               <q-btn v-if="!parent.isMobile && !parent.isDesktop" flat color="secondary" icon-right="open_in_new" 
                  no-caps rounded
                  @click="openVideoInNewWindow(`https://rutube.ru/play/embed/${lesson.PROPERTY_VALUES.youtube.id}`)" :label="message[lang].open_new_tab"/>
              </div>
              <div class="resp-container">
                <iframe class="resp-iframe" :src="`https://rutube.ru/play/embed/${lesson.PROPERTY_VALUES.youtube.id}`" frameBorder="0" allow="clipboard-write; autoplay" webkitAllowFullScreen mozallowfullscreen allowFullScreen></iframe>
              </div>
          </div>
          <div v-else-if="lesson.PROPERTY_VALUES.youtube.provider.indexOf('vk.')!=-1 && lesson.PROPERTY_VALUES.youtube.id" style="min-height: 460px">
              <div class="text-right q-ma-sm">
               <q-btn v-if="!parent.isMobile && !parent.isDesktop" flat color="secondary" icon-right="open_in_new" 
                  no-caps rounded
                  @click="openVideoInNewWindow(`https://${lesson.PROPERTY_VALUES.youtube.provider}/video_ext.php?${lesson.PROPERTY_VALUES.youtube.id}&hd=2&autoplay=1`)" :label="message[lang].open_new_tab"/>
              </div>
              <div class="resp-container">
                <iframe class="resp-iframe" :src="`https://${lesson.PROPERTY_VALUES.youtube.provider}/video_ext.php?${lesson.PROPERTY_VALUES.youtube.id}&hd=2&autoplay=1`" frameBorder="0" allow="clipboard-write; autoplay" webkitAllowFullScreen mozallowfullscreen allowFullScreen></iframe>
              </div>
          </div>
          <!-- SCORM-->
          <div v-else-if="lesson.PROPERTY_VALUES.youtube.provider.indexOf('scorm')!=-1 && lesson.PROPERTY_VALUES.youtube.id" style="min-height: 500px">
              <div class="text-right m-1">
                  <!-- SCORM нельзя открывать в отдельном окне, иначе не сохранится состояние           -->
                  <!-- нужно написать что могут не сохраняться. Потому что в пределах сессии скорм видимо может сохранять результаты в себя и одновременно в тот экземпляр который остался в портале.
                  Но это не точно) -->
                  <a target="_blank" class="text-primary h4" :href="lesson.PROPERTY_VALUES.youtube.id">
                    {{message[lang].open_new_tab}}
                    <q-tooltip anchor="top middle" self="bottom middle">{{message[lang].open_new_tt}}</q-tooltip>
                  </a>
              </div>
            <div class="resp-container">
              <iframe class="resp-iframe" :src="lesson.PROPERTY_VALUES.youtube.id" frameborder="0" allowfullscreen></iframe>
            </div>
          </div>
          <div v-else-if="lesson.PROPERTY_VALUES.youtube.id" style="min-height: 460px">
              <div class="text-right q-ma-sm">
               <q-btn v-if="!parent.isMobile && !parent.isDesktop" flat color="secondary" icon-right="open_in_new" 
                  no-caps rounded
                  @click="openVideoInNewWindow(`https://player.vimeo.com/video/${lesson.PROPERTY_VALUES.youtube.id}`)" :label="message[lang].open_new_tab"/>
              </div>
              <div class="resp-container">
                <iframe class="resp-iframe" :src="`https://player.vimeo.com/video/${lesson.PROPERTY_VALUES.youtube.id}`" frameborder="0" allow="fullscreen" allowfullscreen></iframe>
              </div>
          </div>
      </div>
    </div>
    <!-- PDF -->
    <div  v-if="(!mode || mode==='P') && isPdfLoading" class="text-center q-mb-md">
      <q-spinner-oval color="positive" size="36px"/>
    </div>
    <div v-show="(!mode || mode==='P') && (pdfData || pdfUrl)" class="text-center  q-ma-md">
      <div class="text-right  q-mb-sm" >
        <a v-if="isPdfNameInLinks()" class="text-primary h4" :href="isPdfNameInLinks()" target="_blank">{{message[lang].open_new_tab}}</a>
        <q-btn v-else-if="!parent.isMobile && !parent.isDesktop" :loading="pdf_loading" flat color="secondary" icon-right="open_in_new" @click="openPDFinNewWindow(purePdf)" :label="message[lang].open_new_tab">
          <q-tooltip class="bg-primary h4" anchor="center left" self="center right" :offset="[10, 10]">{{message[lang].info}}</q-tooltip>
        </q-btn>
        <a v-if="parent.isMobile && pdfUrl" class="text-secondary h4" :href="pdfUrl" target="_blank">{{message[lang].file_download}}</a>
        <q-btn v-else-if="parent.isDesktop && pdfUrl" rounded flat color="secondary" @click="linkDownload(pdfUrl)" :label="message[lang].open_new_tab"/>

      </div>

      <view-pdf v-if="pdfData || pdfUrl"
        v-bind:lang="lang"
        v-bind:pdfScale=1.5
        v-bind:pdfData="pdfData"
        v-bind:pdfUrl="pdfUrl"
        v-bind:lesson_id="lesson.ID"
      ></view-pdf>


    </div>

    <!-- Доп мат   -->
    <div class="col-12 q-gutter-y-md q-pl-md q-mt-sm" v-if="Object.keys(listFiles).length > 0">
      <div class="h4 text-accent"  v-if="!parent.isMobile">
        {{message[lang].files}}
        <q-spinner-orbit v-if="loading"
                         color="primary"
                         size="1.3em"
        />
      </div>
      <div v-if="!parent.isMobile">
        <div v-for="item in listFiles" :key="item.I">
              <span v-if="item.A === 'N'">
                <q-img :src="showDocIcon(item.N)" style="width:40px; height:40px"/>   <a class="q-ml-md text-black body-0" @click="fileDownload(item)" href="#" >{{item.N}}</a>
              </span>
        </div>
      </div>
      <div v-if="file_download_link">
        <div v-html="file_download_link"></div>
        <div class="text-positive h5">{{message[lang].file_download_link}}</div>
      </div>
    </div>

    <!-- Ссылки   -->
    <div v-if="Object.keys(arLinks).length>0" class="text-left my-3">
      <div class="text-positive h4">{{message[lang].links}}:</div>
      <div class="q-gutter-y-sm">
        <div v-for="(item,index) in arLinks" :key="index">
          <a v-if="!isPdfNameInLinks()" target="_blank" :href="item">{{arLinksLabels[index]}}</a>
        </div>
      </div>
    </div>

    <!-- Навигация по курсу -->
    <div v-if="nav" class="q-mb-md">
      <div class="row justify-end q-my-md" v-if="!lessonItem.tree">
        <div class="col-5 text-right">
          <q-btn-dropdown flat no-caps rounded color="primary" :label="message[lang].course_navigation">
            <q-list>
              <q-item v-for="element in nav" :key="element.id"  v-close-popup :clickable="!element.dsbld" @click="viewItem(element)" :disable="element.stage === 'D'">
                <q-item-section>
                  <q-item-label>
                    <span v-if="!element.dsbld">{{message[lang][element.type]}}:{{parent.showSysInfo=='Y'?element.id:''}}</span>
                    <span :class="element.dsbld?'text-positive':'text-accent'"> {{element.name}}</span>
                  </q-item-label>
                </q-item-section>
              </q-item>
            </q-list>
          </q-btn-dropdown>
        </div>
      </div>
      <!-- Кнопки завершения/перехода   -->
      <div class="row justify-between">
        <div class="col-md-4 col-xs-12 text-left">
          <q-btn unelevated rounded no-caps :disable="!isNavFinished && lessonItem.alw_next==2" @click="completeTheCourse" color="warning" :label="message[lang].complete_the_course"/>
        </div>
        <div class="col-md-4 col-xs-12 text-left" v-if="!lessonItem.tree">
          <q-btn unelevated rounded no-caps :disable="!isLastNav || lessonItem.alw_next==2 && !isModuleCompleted" @click="getNextNav" color="primary" :label="message[lang].get_next"/>
        </div>
        <div class="col-md-4 col-xs-12 text-left">
          <q-btn unelevated rounded no-caps :disable="isModuleCompleted"
            @click="moduleComplete" :color="isModuleCompleted?'secondary':'warning'" :label="message[lang][isModuleCompleted?'module_completed':'module_complete']"/>
        </div>
      </div>
    </div>

  </div>

</template>

<script>
import { ref } from 'vue';
import viewPdf from './ViewPDF.vue';
// import { pdf } from 'vue-pdf'
import dayjs from "dayjs";
// import { QMediaPlayer } from '@quasar/quasar-ui-qmediaplayer/dist/QMediaPlayer.esm.js'
// import { VuePDF, usePDF } from '@tato30/vue-pdf'

export default {
    props:{
      parent:Object,
      lang:String,
      lessonItem:Object,
      back:String
    },
    data: function () {
        return {
          pdf_loading:false,
          userCourse:null,
          sco_ver:'API',
          showSCORM:false,
          saveSCORMitem:{},
          status:'',
          mode:ref(''),
          points:ref(0),
          pointsShow:'',
          splitterModel:ref(70),
          loadingVideo:false,
          old_videoFiles:[],
          videoFiles:[],
          file_download_link:'',
          loading:false,
          // showNav:ref(true),
          lesson:ref(''),
          goback:'lessonsList',
          purePdf:null,
          pdf:'',
          pdfData:null,
          pdfUrl:null,
          pdfDataName:null,
          listFiles:[],
          isLoading:false,
          isPdfLoading:false,
          nav:null,
          course_id:null,
          userCourse_id:'',
          arLinks:[],
          arLinksLabels:[],
          message:{
            ru:{
              file_download:"Скачать файл",
              scorm_title:"Есть сохраненные данные",
              scorm_message:"Можно продолжить выполненеие или начать с начала.",
              scorm_do:"Продолжить",
              scorm_no:"С начала",
              module_complete:"Завершить",
              module_completed:"Завершен",
              course_navigation:"Навигация по курсу",

              direct_link:"Прямая ссылка",
              textDone_copy:"Скопировано",
              get_next:"Перейти к следующему",
              complete_the_course:"Курс пройден. Завершить курс.",
              file_download_link:"Нажмите на ссылку что бы скачать файл",
              links:"Ссылки",
              title:"Урок",
              goback:"Вернуться",
              loading:"Загрузка",
              remove:"Удалить",
              files:"Дополнительные материалы",
              lesson:"Урок",
              test:"Тест",
              open_new_tab:"Открыть в отдельном окне",
              open_new_tt:"Внимание! Состояние пакета и результаты могут не сохраниться.",
              info:"Если всплывающее окно не открывается в браузере. Должно появиться всплывающее оповещение. Нажмите на оповещение о всплывающих окнах и выберите Всегда разрешать всплывающие окна с 'адрес в сети'."
            },
            en:{
              file_download:"Download file",
              module_complete: "Complete",
              module_completed: "Completed",
              course_navigation:"Course Navigation",
              direct_link:"Direct link",
              textDone_copy:"Copied",
              get_next:"Go to the next",
              complete_the_course:"The course has been completed. Complete the course.",
              file_download_link:"Click on the link to download the file",
              links: "Links",
              title:"Lesson",
              goback:"Back",
              loading:"Loading",
              remove:"Remove",
              files:"Support materials",
              lesson:"Lesson",
              test:"Test",
              open_new_tab: "Open in a separate window",
              open_new_tt:"Attention! The batch state and results may not be preserved.",
              info: "If the pop-up window does not open in the browser. A pop-up alert should appear. Click on the pop-up alert and select 'Always allow pop-ups from 'online address'."
            },
            ua:{
              file_download:"Завантажити файл",
              module_complete:"Завершити",
              module_completed:"Завершено",
              course_navigation: "Навігація за курсом",
              direct_link:"Пряме посилання",
              textDone_copy:"Скопійовано",
              get_next: "Перейти до наступного",
              complete_the_course: "Курс пройдено. Завершити курс.",
              file_download_link: "натисніть на посилання щоб завантажити файл",
              links: "посилання",
              title:"Урок",
              goback:"Повернутися",
              loading:"Завантаження",
              remove:"Видалити",
              files:"Додаткові матеріали",
              lesson:"Урок",
              test:"Тест",
              open_new_tab:"Відкрити в окремому вікні",
              open_new_tt:"Увага! Стан пакету та результати можуть не зберегтися.",
              info:"Якщо спливаюче вікно не відкривається в браузері. Повинне з'явитися спливаюче сповіщення. Натисніть на сповіщення про спливаючі вікна та виберіть 'Завжди дозволяти спливаючі вікна з адресою в мережі'."
            },
            fr:{
              file_download:"Télécharger le fichier",
              module_complete: "complet",
              module_completed: "terminé",
              course_navigation:"Navigation du cours",
              direct_link:"Lien direct",
              textDone_copy:"Copié",
              get_next: "Aller au suivant",
              complete_the_course: "Cours terminé. Terminer le cours.",
              file_download_link:"Cliquez sur le lien pour télécharger le fichier",
              links: "Liens",
              title:"Leçon",
              goback:"Retour",
              loading:"Chargement",
              remove:"Retirer",
              files:"Support documentaire",
              Leçon:"Leçon",
              test:"Test",
              open_new_tab: "Ouvrir dans une fenêtre séparée",
              open_new_tt: "Attention! L'état et les résultats du package ne peuvent pas être enregistrés.",
              info:"Si la fenêtre contextuelle ne s'ouvre pas dans le navigateur. Une alerte contextuelle devrait apparaître. Cliquez sur l'alerte contextuelle et sélectionnez Toujours autoriser les fenêtres contextuelles provenant de l'adresse en ligne."
            },
            it:{
              file_download:"Scarica il file",
              module_complete: "completo",
              module_completed: "completato",
              course_navigation:"Navigazione rotta",
              direct_link:"Collegamento diretto",
              textDone_copy:"Copiato",
              get_next:"Vai al successivo",
              complete_the_course: "corso completato. Completa il corso.",
              file_download_link:"clicca sul link per scaricare il file",
              links: "Link",
              title:"Lezione",
              goback:"Indietro",
              loading:"Caricamento",
              remove:"Rimuovi",
              files:"Materiali di supporto",
              lesson:"Lezione",
              test:"Test",
              open_new_tab: "Apri in una finestra separata",
              open_new_tt: "Attenzione! Lo stato del pacchetto e i risultati potrebbero non essere salvati.",
              info:"Se la finestra pop-up non si apre nel browser. Dovrebbe apparire un avviso pop-up. Fare clic sull'avviso pop-up e selezionare Consenti sempre popup da 'indirizzo online'."
            },
            tr:{
              file_download:"Dosya indir",
              module_complete: "tamamla",
              module_completed: "tamamlandı",
              Course_navigation:"Ders Gezintisi",
              direct_link:"Doğrudan bağlantı",
              textDone_copy:"Kopyalandı",
              get_next:"Bir sonraki git",
              complete_the_course:"Kurs tamamlandı. Rotayı tamamlayın.",
              file_download_link:"Dosyayı indirmek için bağlantıya tıklayın",
              links: "Bağlantılar",
              title:"Ders",
              goback:"Geri",
              loading:"Yükleniyor",
              remove:"Kaldırmak",
              files:"Destek malzemeleri",
              lesson:"Ders",
              test:"Ölçek",
              open_new_tab: "Ayrı bir pencerede aç",
              open_new_tt: "Uyarı! Paket durumu ve sonuçları kaydedilemeyebilir.",
              info: "Açılır pencere tarayıcıda açılmazsa. Bir açılır pencere uyarısı görünmelidir. Açılan uyarıyı tıklayın ve 'Çevrimiçi adresten' gelen açılır pencerelere her zaman izin ver'i seçin."
            },
            de:{
              file_download:"Datei herunterladen",
              module_complete: "Komplettes",
              module_completed: "abgeschlossen",
              course_navigation:"Kursnavigation",
              direct_link:"Directe link",
              textDone_copy:"Gekopieerd",
              get_next:"Zum nächsten wechseln",
              complete_the_course:"Der Kurs wurde bestanden. Den Kurs abschließen.",
              file_download_link:"Klicken Sie auf den Link, um die Datei herunterzuladen",
              links: "Links",
              title:"Lektion",
              goback:"Zurück",
              loading:"Wird geladen",
              remove:"Entfernen",
              files:"Unterstützungsmaterialien",
              lesson:"Lektion",
              test:"Prüfen",
              open_new_tab: "In einem separaten Fenster öffnen",
              open_new_tt: "Warnung! Der Paketstatus und die Ergebnisse werden möglicherweise nicht gespeichert.",
              info: "Wenn das Pop-up-Fenster nicht im Browser geöffnet wird. Es sollte eine Pop-up-Warnung erscheinen. Klicken Sie auf die Pop-up-Warnung und wählen Sie Pop-ups von 'Online-Adresse' immer zulassen."
            },
            pl:{
              file_download:"Pobierz plik",
              module_complete: "Kompletny",
              module_completed: "ukończony",
              Course_navigation:"Nawigacja po kursie",
              direct_link:"Bezpośredni link",
              textDone_copy:"Skopiowano",
              get_next: "przejdź do następnego",
              complete_the_course: "kurs został ukończony. Ukończ kurs.",
              file_download_link: "kliknij na link aby pobrać plik",
              links: "Spinki do mankietów",
              title:"Lekcja",
              goback:"Plecy",
              loading:"Ładowanie",
              remove:"Usunąć",
              files:"Materiały pomocnicze",
              lesson:"Lekcja",
              test:"Test",
              open_new_tab: "Otwórz w osobnym oknie",
              open_new_tt: "Uwaga! Status paczki i wyniki mogą nie zostać zapisane.",
              info: "Jeśli wyskakujące okienko nie otwiera się w przeglądarce. Powinien pojawić się wyskakujący alert. Kliknij wyskakujące powiadomienie i wybierz Zawsze zezwalaj na wyskakujące okienka z 'adresu online'."
            },
            pt:{
              file_download:"Baixar arquivo",
              module_complete: "completo",
              module_completed: "concluído",
              course_navigation:"Navegação do curso",
              direct_link:"Link direto",
              textDone_copy:"Copiado",
              get_next: "ir para o próximo",
              complete_the_course: "curso concluído. Terminar o curso.",
              file_download_link:"Clique no link para baixar o arquivo",
              links: "Links",
              title:"Lição",
              goback:"Voltar",
              loading:"Carregando",
              remove:"Retirar",
              files:"Materiais de apoio",
              lesson:"Lição",
              test:"Teste",
              open_new_tab: "Abra em uma janela separada",
              open_new_tt: "Atenção! O status e os resultados do pacote podem não ser salvos.",
              info: "Se a janela pop-up não abrir no navegador. Um alerta pop-up deverá aparecer. Clique no alerta pop-up e selecione Sempre permitir pop-ups de 'endereço online'."
          },
            es:{
              file_download:"Descargar archivo",
              module_complete: "completo",
              module_completed: "concluído",
              course_navigation:"Navegação do curso",
              direct_link:"Enlace directo",
              textDone_copy:"Copiado",
              get_next: "Ir al siguiente",
              complete_the_course: "Curso completado. Completar el curso.",
              file_download_link:"haga Clic en el enlace para descargar el archivo",
              links: "Enlaces",
              title:"Lección",
              goback:"Atrás",
              loading:"Cargando",
              remove:"Eliminar",
              files:"Materiales de apoyo",
              lesson:"Lección",
              test:"Prueba",
              open_new_tab: "Abrir en una ventana separada",
              open_new_tt: "¡Advertencia! Es posible que el estado del paquete y los resultados no se guarden.",
              info: "Si la ventana emergente no se abre en el navegador, debería aparecer una alerta emergente. Haga clic en la alerta emergente y seleccione Permitir siempre ventanas emergentes desde 'dirección en línea'."
          },
            vn:{
              file_download:"Tải xuống tệp",
              module_complete: "Hoàn thành mô-đun",
              module_completed: "Mô-đun đã hoàn thành",
              course_navigation:"Điều hướng khóa học",
              direct_link:"Liên kết trực tiếp",
              textDone_copy:"Đã sao chép",
              get_next: "chuyển đến mô-đun tiếp theo",
              complete_the_course: "khóa học đã được hoàn thành. Hoàn thành khóa học.",
              file_download_link:"Nhấn vào liên kết để tải về những file",
              links: "Liên kết",
              title:"Bài học",
              goback:"Mặt sau",
              loading:"Đang tải",
              remove:"Di dời",
              files:"Vật liệu hỗ trợ",
              lesson:"Bài học",
              test:"Thử nghiệm",
              open_new_tab: "Mở trong một cửa sổ riêng",
              open_new_tt: "Cảnh báo! Trạng thái và kết quả của gói có thể không được lưu.",
              info: "Nếu cửa sổ bật lên không mở trong trình duyệt. Một cảnh báo bật lên sẽ xuất hiện. Nhấp vào cảnh báo bật lên và chọn Luôn cho phép cửa sổ bật lên từ 'địa chỉ trực tuyến'."
          },

          },
        }
    },
    updated: function () {
        //console.log(':: LessonView updated ');
        // const $this=this;
        // if(!$this.lesson.isLoaded){
        //     this.initLesson();
        // }
    },
    mounted: function(){
      // console.log(':: LessonView mounted ', BX24.proxyContext(), BX24.getDomain());
      this.parent.checkPulse('lesson').then(res=>{
        // console.log(':: LessonView checkPulse res= ', res);
        this.initLesson();
      },err=>{
        console.error(':: LessonView checkPulse error ', err);
        this.$emit('goback', 'studentProfile');
      });

    },
     watch:{
        // lang: function(){
        //     //console.log(':: lang',lang);
        // },
    },
    computed:{
      isLastNav(){
        let ret=false;
        let len = Object.keys(this.nav).length;
        for(let i=0; i<len-1; i++){
          if(this.nav[i].id==this.lessonItem.ID && i<len-1){
            ret=true;
            break;
          }
        }
        return ret;
      },
      isNavFinished(){
        // console.log('::isNavFinished nav=',this.nav);
        let ret=true;
        if(this.nav){
          let nav = this.nav;
          if(this.lessonItem.tree) {
            nav = this.parent.flattenArray(nav);
          }

          for(let i in nav) {
            let item = nav[i];
            // console.log('::isNavFinished item=',item.type, item.id, this.lessonItem.ID);

            if (item.stage !== 'Y' && this.lessonItem.alw_next == 2) {
              ret = false;
              break;
            }else if(item.id == this.lessonItem.ID){
              //если это последний модуль и не стоит признак "не переходить дальше если этот не пройден"
              // то можно здесь завершать курс
              ret = true;
            }else if(item.stage !== 'Y') {
                ret = false;
            }
          }
        }else{
          ret = false;
        }
        return ret;
      },
      isModuleCompleted(){
        // console.log('::isModuleCompleted ',this.lessonItem.ID, this.userCourse);
        // let ret=this.nav.find(el=>{return el.stage === 'Y' && el.id==this.lessonItem.ID});
        // return ret?true:false;
        let ret = this.userCourse && this.userCourse.lessons && this.userCourse.lessons.find(el=>{return el.lesson_id==this.lessonItem.ID && el.test_passed==='Y'});
        return ret?true:false;
        // return false;
      }
    },
    methods: {
      programModuleComplete(action='moduleReturn'){
        let emit_data = {
              program_chedule_id:this.lessonItem.program_chedule_id,
              module_id:this.lessonItem.ID,
              passed:true,
              type:'lesson',
            };
            console.log(':+++: complete lesson emit',action, emit_data);
            // this.parent.savePoints('L', this.lessonItem.ID, this.points);
            let for_course=this.lessonItem.for_course;
            if(!for_course || for_course && (!for_course.active || for_course.active && for_course.dop === 'Y')){
              //в курсе заданы условия что за уроки нужно начислять дополнительно
              this.parent.savePoints('L', this.lessonItem.ID, this.points);
            }
            this.$emit(action, JSON.stringify(emit_data));
      },
      linkDownload(href){
        let link = document.createElement('a');
        link.href = href;
        link.download = href;
        link.target = '_blank';
        document.body.appendChild(link);
        link.click();
        console.log(':: linkDownload link=', link);
        link.remove();
      },
      // initSCORM(noEvents) {
      //   let $this = this;
      //   var settings = {
      //     logLevel: 5
      //   }
      //   // # AICC
      //   // window.API = new AICC(settings);
      //
      //   //# SCORM 1.2
      //   window.API = new Scorm12API(settings);
      //
      //   // # SCORM 2004
      //   window.API_1484_11 = new Scorm2004API(settings);
      //
      //   console.log('::initSCORM ok')
      // },
      moduleComplete(){
        //В курсе могут быть заданы баллы и условия за прохождние курса
        let for_course=this.lessonItem.for_course;
        if(!for_course || for_course && (!for_course.active || for_course.active && for_course.dop === 'Y')){
          //в курсе заданы условия что за уроки нужно начислять дополнительно
          this.parent.savePoints('L', this.lessonItem.ID, this.points);
        }

        this.setStage('Y');
        this.status='Y';
        let index=this.userCourse.lessons.findIndex(el=>{return el.lesson_id==this.lessonItem.ID});
        if(index !== -1){
          this.userCourse.lessons[index].test_passed='Y';
        }
        this.saveLessonToCourseOption().then(ok=>{}, err=>{});
        if(this.lessonItem.tree) {
          this.$emit('lessonComplete', this.lessonItem.ID);
        }
        this.parent.getServerTimeStamp().then(startTime=> {
          this.saveSCORMitem.test_passed = 'Y';
          this.saveSCORMitem.end = startTime;
          this.saveLessonToAppOptions()
        });
      },
      copy(id) {
        this.$refs['clone'+id].focus();
        document.execCommand('copy');
      },
      getNextNav(){
        let len = Object.keys(this.nav).length;
        for(let i=0; i<len-1; i++){
          if(this.nav[i].id==this.lessonItem.ID){
            this.viewItem(this.nav[i+1]);
            break;
          }
        }
      },
      completeTheCourse(){
        this.goback.completed='Y';
        this.goback.program_chedule_id=this.lessonItem.program_chedule_id;

        this.$emit('goback', this.goback);
        this.saveLessonToCourseOption().then(ok=>{}, err=>{});
      },
      isPdfNameInLinks(){
        // console.log('::isPdfNameInLinks this.pdfDataName=',this.pdfDataName)

        for(let i in this.arLinksLabels){
          let linkName = this.arLinksLabels[i];
          // console.log('::isPdfNameInLinks linkName=',linkName)
          if(linkName === this.pdfDataName){
            // console.log(':: +++++ ', this.arLinks[i]);
            return this.arLinks[i];
          }
        }
        return '';
      },
      openVideoInNewWindow(videoUrl){

            // Открытие нового окна
            const newWindow = window.open('', '_blank', 'width=750,height=430');

            // Проверка, что окно было успешно открыто
            if (newWindow) {
                // Заполнение нового окна содержимым
                newWindow.document.write(`
                    <!DOCTYPE html>
                    <html lang="en">
                    <head>
                        <meta charset="UTF-8">
                        <meta name="viewport" content="width=device-width, initial-scale=1.0">
                        <title>Video</title>
                    </head>
                    <body>
                        <iframe
                            width="720"
                            height="405"
                            src="${videoUrl}"
                            frameborder="0"
                            allow="autoplay; encrypted-media"
                            allowfullscreen>
                        </iframe>
                    </body>
                    </html>
                `);

                // Фокус на новое окно
                newWindow.focus();
            } else {
                alert('Не удалось открыть новое окно. Пожалуйста, проверьте настройки вашего браузера.');
            }
        
      },
      openPDFinNewWindow(pdfInBase64) {
        if(pdfInBase64){
          this.openPDFinNewWindow_pure(pdfInBase64)
        }else if(this.pdfUrl){
          this.pdf_loading=true;
          this.urlDownload(this.pdfUrl, this.pdfDataName).then(res=>{
            this.openPDFinNewWindow_pure(res)
          });
        }
      },
      openPDFinNewWindow_pure(pdfInBase64){
        // console.log(pdfInBase64);
        pdfInBase64 = pdfInBase64.substr(pdfInBase64.indexOf("base64,")+7);
        var URL = window.URL || window.webkitURL,
          byteChars = atob(pdfInBase64),
          bytes = [],
          i = 0;

        for (; i < byteChars.length; i++)
          bytes[i] = byteChars.charCodeAt(i);

        var blob = new Blob([new Uint8Array(bytes)], {type: 'application/pdf'});
        // создаём object URL из Blob
        var downloadUrl = URL.createObjectURL(blob);

        if(window.navigator && window.navigator.msSaveOrOpenBlob)
          window.navigator.msSaveOrOpenBlob(blob);
        else
        {
          let blank = this.parent.isMobile?'_self':'_blank';
          var newWin = window.open(downloadUrl, blank, 'menubar=yes,scrollbars=yes,status=yes,resizable=yes'); //width=500,height=300,
          newWin.focus();
          // newWin.print();//чтобы эта строка сработала страница должна быть в сети, т.е. НЕ локально.
          URL.revokeObjectURL(downloadUrl);
        }
        this.pdf_loading = false;
      },
      showDocIcon(item){
        let arr=item.split('.');
        let ext = ""+arr[arr.length-1];
        ext = ext.toLowerCase();
        let ret = `img/docs/${ext}.svg`;
        // console.log(':: ', item, ext, ret);
        return ret;
      },
      viewItem(element, force){
        console.log('::viewItem element=',element, force);
        if(force){
          element.stage = 'View';
        }
        if(element.stage !=='D' || this.lessonItem.alw_next<2) {
            let meth = element.type + 'ViewById';
            if(this.lesson){
              // eslint-disable-next-line vue/no-mutating-props
              this.lesson.isLoaded = false;
            }
            //console.log('::viewItem id=' + element.id + ' meth=' + meth + ' nav=', this.nav);
            this.$emit(meth,
              {id: element.id,
                nav: this.nav,
                course_id: this.course_id,
                save_id:this.userCourse_id,
                sched_id:this.lessonItem.sched_id,
                program_chedule_id:this.lessonItem.program_chedule_id,
                alw_next:this.lessonItem.alw_next,
                time:this.lessonItem.time,
                start:this.lessonItem.start,
                for_course:this.lessonItem.for_course
              });
        }
      },
      setStage(stage){
        // console.log('::setStage stage,lesson.ID,nav=',stage,this.lesson.ID, this.nav);
        if(this.lessonItem.tree){
          //Навигация в виде дерева
          let currentItem = this.parent.getInTreeById(this.nav, this.lesson.ID);
          // console.log('::setStage currentItem=',currentItem);
          if(currentItem){
            currentItem.stage = stage;
          }
          if(stage === 'Y') {
            currentItem.test_passed=true;
            let flatten = this.parent.flattenArray(this.nav);
            // console.log('::setStage flatten=',flatten);
            let nextItem = this.parent.findNextElement(flatten, this.lesson.ID);
            // console.log('::setStage nextItem=',nextItem);
            if (nextItem) {
              nextItem.stage = stage === 'D' ? '' : nextItem.stage;
              this.parent.updateInTreeById(this.nav, nextItem.id, nextItem);
            }
          }
        }
        else if(this.nav) {
            let $this=this;
            for (let i in this.nav) {
              let item = this.nav[i];
              if (item.id == this.lesson.ID) {
                  this.nav[i].stage = stage;
                  let inext = parseInt(i)+1;
                  // console.log('::setStage ',i, inext, stage);
                  if(stage === 'Y' && Object.keys(this.nav).length > inext){

                    // console.log(':: inext=',inext, this.nav[inext].stage);
                    this.nav[inext].stage = this.nav[inext].stage === 'D'?'':this.nav[inext].stage;
                  }else{
                    // console.log('::setStage err ',(stage === 'Y'),  (Object.keys(this.nav).length < inext) );
                  }
                  break;
              }
            }

          }
      },
      initLesson(){
        let $this=this;
        console.log(':1:initLesson this.lessonItem=',this.lessonItem);
        //В уроке могут быть заданы свои баллы за прохождение
          if(this.lessonItem.nav){
            this.nav = this.lessonItem.nav;
            this.course_id = this.lessonItem.course_id;
            if(this.lessonItem.program_chedule_id){
              this.goback = {scr:'courseView', id:this.course_id, program_chedule_id:this.lessonItem.program_chedule_id};;
            }else{
              this.goback = {scr:'courseView', id:this.course_id, sched_id:this.lessonItem.sched_id}; //'courseView:'+this.course_id;
            }
            this.userCourse_id = this.lessonItem.save_id;
            let thisNav = this.nav ? this.nav.find(el=>{return el.id == this.lessonItem.ID}) : false;
            this.status=thisNav?thisNav.stage:'N';
            this.geUserCourse().then(res=>{
              this.userCourse = res;
              console.log(':: initLesson this.userCourse=',this.userCourse);
            });
          }else{
            if(this.lessonItem.program_chedule_id){
              this.goback = {scr:'courseView', id:this.course_id, program_chedule_id:this.lessonItem.program_chedule_id};;
            }else{
              //Навигации нет, значит урок проходится не в курсе, вероятно модератором
              this.goback = {scr:'lessonsList'}; //this.back?this.back:'lessonsList';
            }
          }
          //

          //
        this.getLessonFromAppOptions().then(data=> {
          console.log('::getLessonFromAppOptions data=',data);
          if(!data.att){
            this.saveSCORMitem.att = 1;
          }else{
            this.saveSCORMitem.att += 1;
          }
          if(this.userCourse && this.userCourse.lessons){
            let userLesson = this.userCourse.lessons.find(el=>{return el.lesson_id == this.lessonItem.ID});
            if(userLesson){
              this.saveSCORMitem.test_passed =  userLesson.test_passed;
            }
          }
          this.saveLessonToAppOptions();
          this.getLessonById(this.lessonItem.ID).then((less) => {

            // eslint-disable-next-line vue/no-mutating-props
            $this.lesson = less;
            console.log(':2:initLesson this.lesson=',this.lesson);
            // eslint-disable-next-line vue/no-mutating-props
            $this.lesson.isLoaded = true;
            // this.parent.savePoints('L', less.ID);

            //mode - что показывать линк, pdf, скорм или лонгрид
            //let points = 0;
            if ($this.parent.isJSON($this.lesson.PROPERTY_VALUES.flags)) {
              let flags = JSON.parse($this.lesson.PROPERTY_VALUES.flags);
              $this.mode = flags && flags.mode ? flags.mode : '';
              $this.points = flags && flags.points ?  parseInt(flags.points) : 0;
            } else {
              $this.mode = '';
            }

            let for_course=this.lessonItem.for_course;
            if(!for_course || for_course && (!for_course.active || for_course.active && for_course.dop === 'Y')){
              //в курсе заданы условия что за уроки нужно начислять дополнительно или в курсе ни чего не задано на этот счет
              $this.pointsShow = $this.parent.showPoints('L', $this.lessonItem.ID, $this.points);
            
            }

            try {
              if ($this.parent.isJSON($this.lesson.PROPERTY_VALUES.youtube)) {
                // eslint-disable-next-line vue/no-mutating-props
                $this.lesson.PROPERTY_VALUES.youtube = JSON.parse($this.lesson.PROPERTY_VALUES.youtube);
              }
            } catch (e) {
              console.log(e);
            }

            //https://uni24.brusnika.solutions/local/scorm/novus-km.bitrix24.ru/13586/story.html
            if ($this.mode == 'S') {

              if (!$this.lesson.PROPERTY_VALUES.youtube.provider ||
                $this.lesson.PROPERTY_VALUES.youtube.provider == 'ideamanagement.novus-km.com') {
                $this.lesson.PROPERTY_VALUES.youtube.provider = 'scorm';
                $this.lesson.PROPERTY_VALUES.youtube.id = "/local/" + $this.lesson.PROPERTY_VALUES.youtube.id;
                // console.log(':--1:initSCORM SCORM link correction:', $this.lesson.PROPERTY_VALUES.youtube);
              }
              let [empty, local, scorm, portal, ...otherElements] = $this.lesson.PROPERTY_VALUES.youtube.id.split('/');
              // console.log(':--2:initSCORM SCORM link correction:',local,scorm,portal,otherElements);
              if (portal !== $this.parent.portal) {
                $this.lesson.PROPERTY_VALUES.youtube.id = `/local/scorm/${$this.parent.portal}/` + otherElements.join('/');
                // console.log(':--3:initSCORM SCORM link correction:',$this.lesson.PROPERTY_VALUES.youtube.id);
              }

              $this.initSCORM();
            }

            // console.log(':1: lesson ', $this.lesson)
            // console.log(':2: lesson ', $this.lesson.PROPERTY_VALUES.files)
            if ($this.parent.isJSON($this.lesson.PROPERTY_VALUES.files)) {
              $this.listFiles = JSON.parse($this.lesson.PROPERTY_VALUES.files);
              // console.log('::mounted files:', $this.listFiles);
              $this.showPdf();
            } else {
              $this.listFiles = [];
              $this.pdfData = '';
              $this.pdfUrl = '';
              $this.purePdf = '';
            }

            $this.arLinks = [];
            $this.arLinksLabels = [];
            for (let i = 0; i < 10; i++) {
              let n = i + 1;
              if ($this.parent.isJSON($this.lesson.PROPERTY_VALUES['link' + n])) {
                let link = JSON.parse($this.lesson.PROPERTY_VALUES['link' + n]);
                $this.arLinks.push(link.href);
                $this.arLinksLabels.push(link.label);
              }
            }
            // console.log('::arLinks ',$this.arLinks)

            let detail_text = $this.lesson.DETAIL_TEXT;
            $this.lesson.DETAIL_TEXT = {};
            if ($this.parent.isBase64Encoded(detail_text)) {
              //устаревший лонгрид
              $this.lesson.DETAIL_TEXT.text = $this.parent.b64_to_utf8(detail_text);
            } else if ($this.parent.isJSON(detail_text)) {
              //лонгрид с видео ссылками
              let obj = JSON.parse(detail_text);
              if ($this.parent.isBase64Encoded(obj.text)) {
                $this.lesson.DETAIL_TEXT.text = $this.parent.b64_to_utf8(obj.text);
              } else {
                //не понятно что
                $this.lesson.DETAIL_TEXT.text = obj.text;
              }
              $this.videoFiles = obj.video;
            } else {
              //не понятно что
              $this.lesson.DETAIL_TEXT.text = detail_text;
            }

          }, error => {
            console.error(':: error ', error)
          });
        });
      },
      initSCORM(noEvents) {
        let $this=this;
        var settings = {
          logLevel:1,
        }
        // # AICC
        // window.API = new AICC(settings);

        //# SCORM 1.2
        window.API = new Scorm12API(settings);

        // # SCORM 2004
        window.API_1484_11 = new Scorm2004API(settings);

        let item = this.lesson.PROPERTY_VALUES.youtube;
        if(this.parent.isJSON(item)){
          item = JSON.parse(item)
        }
        this.sco_ver = item.provider.indexOf('2004')!==-1?'API_1484_11':'API';
        console.log(':!!!: window_api ',window[$this.sco_ver])

        this.getLessonFromAppOptions().then(data=>{

          if(data.suspend_data){
            //в suspend_data будут и правильные и неправильные ответы.
            // Значит нужно перед стартом спрашивать: начать с начала или продолжить
            let $this=this;
            $this.parent.title_confirm = this.message[this.lang].scorm_title;
            $this.parent.message_confirm = this.message[this.lang].scorm_message;
            $this.parent.modal_ok = this.message[this.lang].scorm_do;
            $this.parent.modal_ok1 = this.message[this.lang].scorm_no;

            $this.parent.do_confirm = () => {
              console.log(':!!!:getLessonFromAppOptions ',data)
              if(data.suspend_data)
                window[$this.sco_ver].cmi.suspend_data = atob(data.suspend_data);

              if(data.entry)
                window[$this.sco_ver].cmi.entry = data.entry;

              if(data.location)
                window[$this.sco_ver].cmi.location = data.location;

              $this.doInitSCORM();
              $this.parent.confirm=false;
            }

            $this.parent.do_confirm1 = () => {
              $this.doInitSCORM();
              $this.parent.confirm=false;
            }

            $this.parent.confirm=true;

          }else{
            this.doInitSCORM();
          }
          //

        });

        },
      doInitSCORM(){
          let $this=this;
          this.parent.getServerTimeStamp().then(startTime=> {
            this.saveSCORMitem.lesson_id = this.lesson.ID;
            this.saveSCORMitem.lesson_name = this.lesson.NAME;
            this.saveSCORMitem.startTime = startTime;

            this.saveLessonToAppOptions().then(res=>{
              //2004
              window[$this.sco_ver].on("SetValue.cmi.suspend_data", function(CMIElement, value) {
                console.log(':+++: ', window[$this.sco_ver]);
                $this.saveSCORMitem.suspend_data = btoa(value);
                $this.saveLessonToAppOptions();
              });
              window[$this.sco_ver].on("SetValue.cmi.core.score.raw", function(CMIElement, value) {
                console.log(':+++: ', CMIElement, '=', value);
                $this.saveSCORMitem.score = value;
                $this.saveLessonToAppOptions();
              });
              window[$this.sco_ver].on("SetValue.cmi.core.score.lesson_status", function(CMIElement, value) {
                console.log(':+++: ', CMIElement, '=', value);
                $this.saveSCORMitem.status = value;
                $this.saveLessonToAppOptions();
              });
              window[$this.sco_ver].on("SetValue.cmi.core.score.session_time", function(CMIElement, value) {
                console.log(':+++: ', CMIElement, '=', value);
                $this.saveSCORMitem.session_time = value;
                $this.saveLessonToAppOptions();
              });
              window[$this.sco_ver].on("Commit", function(CMIElement, value) {
                console.log(':+++: ', CMIElement, '=', value);
                $this.parent.getServerTimeStamp().then(endTime=> {
                  $this.saveSCORMitem.endTime = endTime;
                  $this.saveLessonToAppOptions();
                });
              });
              window[$this.sco_ver].on("Finish", function(CMIElement, value) {
                console.log(':+++: ', CMIElement, '=', value);
                $this.parent.getServerTimeStamp().then(endTime=> {
                  $this.saveSCORMitem.endTime = endTime;
                  $this.saveLessonToAppOptions();
                });
              });

              // 1.2
              window[$this.sco_ver].on("LMSSetValue.cmi.*", function(CMIElement, value) {
                console.log(':!!!: ', CMIElement, '=', value);
                if(CMIElement=='cmi.suspend_data') {
                  console.log(':!!!: suspend_data=', CMIElement, value);
                   $this.saveSCORMitem.suspend_data = btoa(value);
                   $this.saveLessonToAppOptions();
                }
                if(CMIElement=='cmi.entry'){
                  console.log(':!+!+!: entry=',value);
                  $this.saveSCORMitem.entry = value;
                  $this.saveLessonToAppOptions();
                }
                if(CMIElement=='cmi.location'){
                  console.log(':!+!+!: location=',value);
                  $this.saveSCORMitem.location = value;
                  $this.saveLessonToAppOptions();
                }
                if(CMIElement=='cmi.core.score.raw'){
                  //Процент верных ответов
                  console.log(':!!!: Процент верных ответов=',value);
                  $this.saveSCORMitem.score = value;
                  $this.saveLessonToAppOptions();
                }
                if(CMIElement=='cmi.core.lesson_status'){
                  //Пройден/Не пройден
                  console.log(':!!!: Статус теста=',value);
                  $this.saveSCORMitem.status = value;
                  $this.saveLessonToAppOptions();
                }
                if(CMIElement=='cmi.core.session_time'){
                  //Затраченное время
                  console.log(':!!!: Затраченное время=',value);
                  $this.saveSCORMitem.session_time = value;
                  $this.saveLessonToAppOptions();
                }


              });
              //
              window[$this.sco_ver].on("LMSCommit", function(commitInput) {
                // console.log(":-LMSCommit-: " + commitInput);
                $this.parent.getServerTimeStamp().then(endTime=> {
                  $this.saveSCORMitem.endTime = endTime;
                  $this.saveLessonToAppOptions();
                });
              });
              window[$this.sco_ver].on("LMSFinish", function(finishInput) {
                // console.log(":-LMSFinish-: " + finishInput);
                $this.parent.getServerTimeStamp().then(endTime=> {
                  $this.saveSCORMitem.endTime = endTime;
                  $this.saveLessonToAppOptions();
                });
              });

              // console.log(":-!!!-: showSCORM");
              this.showSCORM = true;
            });

          });
        },
      saveLessonToAppOptions(){
          let $this=this;
          // console.log('::saveLessonToAppOptions ',this.saveSCORMitem)
          let user_id = parseInt(this.parent.user_current.ID);
          return new Promise(resolve => {
            $this.parent.uCoursesTestsSet('lesson', user_id, $this.saveSCORMitem,  $this.saveSCORMitem.id).then(res => {
              // console.log('::saveLessonToAppOptions res= ', res);
              if(!$this.saveSCORMitem.id) {
                $this.saveSCORMitem.id = parseInt(res.result);
              }
              resolve(true);
            });

          });
        },
      getLessonFromAppOptions(){
          console.log('::getLessonFromAppOptions ',this.parent.user_current.ID)
          let $this=this;
          let user_id = parseInt(this.parent.user_current.ID);
          return new Promise(resolve => {
            $this.parent.uCoursesTestsGetActive('lesson', user_id, $this.lesson.ID, true).then(res => {
              console.log('::getLessonFromAppOptions res=',res)
              resolve(res.data?res.data:{})
            }, err=>{
              console.log('::getLessonFromAppOptions err=',err)
              resolve({})
            });
          });
        },
      //   initSCORMapi(noEvents){
      //   window.API.apiLogLevel = 1;
      //   console.log(':!!!: это SCORM ', window.API);
      //   let simplifiedObject = window.API.cmi.toJSON();
      //   console.log(':!!!: simplifiedObject=',simplifiedObject);
      //   if(!noEvents) {
      //     // window.API.on("LMSInitialize", function () {
      //     //   let simplifiedObject = window.API.cmi.toJSON();
      //     //   console.log(':!!!:LMSInitialize simplifiedObject=', simplifiedObject);
      //     // });
      //     window.API.on("LMSSetValue.cmi.core.score", function (CMIElement, value) {
      //       let score = window.API.core.score;
      //       console.log(':!!!:LMSSetValue.cmi.core.score score=', score);
      //     });
      //     window.API.on("LMSSetValue.cmi.suspend_data", function (CMIElement, value) {
      //       let simplifiedObject = window.API.cmi.toJSON();
      //       console.log(':!!!:LMSSetValue.cmi.student_data simplifiedObject=', simplifiedObject);
      //     });
      //     window.API.on("LMSFinish", function () {
      //       let score = window.API.core.score;
      //       console.log(':!!!:LMSFinish score=', score);
      //       let simplifiedObject = window.API.cmi.toJSON();
      //       console.log(':!!!:simplifiedObject=', simplifiedObject);
      //     });
      //     window.API.on("LMSCommit", function () {
      //       let score = window.API.core.score;
      //       console.log(':!!!:LMSCommit score=', score);
      //       let simplifiedObject = window.API.cmi.toJSON();
      //       console.log(':!!!:simplifiedObject=', simplifiedObject);
      //     });
      //     window.API.on("LMSSetValue.cmi.core.lesson_status", function (CMIElement, value) {
      //       let score = window.API.core.score;
      //       console.log(':!!!:LMSSetValue.cmi.core.lesson_status score=', score);
      //     });
      //     window.API.on("LMSSetValue.cmi.core.session_time", function (CMIElement, value) {
      //       let score = window.API.core.score;
      //       console.log(':!!!:LMSSetValue.cmi.core.session_time score=', score);
      //     });
      //     window.API.on("LMSSetValue.cmi.core.exit", function (CMIElement, value) {
      //       let score = window.API.core.score;
      //       console.log(':!!!:LMSSetValue.cmi.core.exit score=', score);
      //     });
      //   }
      // },
      showPdf(){
          this.old_videoFiles=[];
          for(var i in this.listFiles){
            let item = this.listFiles[i];
            // console.log('::showPdf ',i, item)
            if(item.A === 'Y'){
              if(item.H){
                this.pdfUrl = item.H;
              }else {
                this.loadPDF(this.listFiles[i].I);
              }
            }else if(this.listFiles[i].A === 'V'){
              this.old_videoFiles.push(this.listFiles[i]);
            }
          }
          if(this.old_videoFiles.length > 0){
            this.old_videoFiles.sort((a,b)=>{ return a.S - b.S; });
            this.loadVideosFromDisk();
          }else{
            this.splitterModel = 100;
          }
      },
      loadVideosFromDisk(nn){
        nn = nn ? nn : 0;
        if(!nn){
          // console.log('::loadVideosFromDisk start ',this.old_videoFiles)
          this.loadingVideo=true;
        }
        if(nn < this.old_videoFiles.length){
          this.parent.loadFile(this.old_videoFiles[nn].I).then(res=> {
            console.log('::loadFile res=',res)
            this.old_videoFiles[nn].data = res.DETAIL_TEXT;
            nn ++;
            this.loadVideosFromDisk(nn);
          });
        }else{
          this.loadingVideo=false;
          // console.log('::loadVideosFromDisk ok ',this.old_videoFiles)
        }

      },
      loadPDF(id){
        let $this = this;
        $this.pdfData='';
        $this.pdfDataName='';

        this.isPdfLoading=true;
        let params = {
          'ENTITY': 'uni_files',
          'SORT': {},
          'FILTER': {
            'ID': id
          }
        };
        let url =  $this.parent.bx24Init({ AJAX_ACTION: 'entityItemGet', params:params });//.then(params=> {
        $this.parent.b24POST(url, $this.parent.bx24params, function (res) {
          // console.log('::loadPDF res=',res)
            $this.isPdfLoading=false;
            if(res.result && res.result[0]){
              var item=res.result[0];
              var b64str = item.DETAIL_TEXT;
              $this.purePdf=item.DETAIL_TEXT;
              let idxb64=b64str.indexOf("base64,");
              if(idxb64>=0){
                b64str = b64str.substr(idxb64+7);
                $this.pdfData = atob(b64str); // b64str;
              }else{
                $this.pdfData = b64str;
              }
              // b64str = b64str.substr(b64str.indexOf("base64,")+7);
              // $this.pdfData = atob(b64str); // b64str;
              $this.pdfDataName = item.PREVIEW_TEXT;
              // console.log('::loadPDF pdfDataName=',$this.pdfDataName, $this.pdfData)
            }
          });
      },
      fileDownload(fileItem){
        // console.log('::fileDownload ', fileItem);
        let id = fileItem.I && !fileItem.I.startsWith('no_id')?fileItem.I:false;
        let href = fileItem.H;
        let name = fileItem.N;
        let $this = this;
        this.file_download_link='';
        this.isLoading=true;

        if(id) {
          let method = 'entityItemGet';
          let params = {
            'ENTITY': 'uni_files',
            'SORT': {},
            'FILTER': {
              'ID': id
            }
          }
          let url = $this.parent.bx24Init({AJAX_ACTION: method, params: params});
          $this.parent.b24POST(url, $this.parent.bx24params, function (res) {
            // console.log('::fileDownload res= ', res);
            $this.isLoading = false;
            if (res.result && res.result.length>0) {
              var item = res.result[0];
              var b64str = item.DETAIL_TEXT;
              $this.saveDownload(b64str,item.PREVIEW_TEXT);
            }
          });
        }else if(href){
          $this.urlDownload(href, name).then(res=>{
            $this.isLoading = false;
            $this.saveDownload(res, name);
          })
        }
      },
      saveDownload(b64str, name){
        // console.log('::navigator.userAgent= ', navigator.userAgent)
        // if (navigator.userAgent.search(/BitrixDesktop/g) >= 0) {
        //   this.getFileLink(name, b64str);
        // } else {
          let link = document.createElement('a');
          var blob = this.dataURLtoBlob(b64str);
          link.href = window.URL.createObjectURL(blob)
          link.download = name;
          link.target = '_blank';
          document.body.appendChild(link);
          link.click();
          //console.log(':: fileDownload link ok', link, b64str);
          link.remove();
        // }
      },
      urlDownload(url, name){
        return new Promise((resolve, reject) => {
          fetch(url)
            .then(response => response.blob())
            .then(blob => {
              const reader = new FileReader();
              reader.onloadend = () => {
                const base64String = reader.result;
                resolve(base64String);
              };
              reader.readAsDataURL(blob);

            })
            .catch(error => {
              console.error('Error:', error);
              reject(error);
            });
        });

      },
      getFileLink(name, b64str){
        let $this=this;
        let file=b64str.split(',')[1];
        let ext = name.split('.').at(-1);
        let random_file_name = Math.floor(Math.random() * 10000)+'.'+ext;
        this.loading=true;
        //поиск хранилища группы с именем содержащем "Фут"
        BX24.callMethod(
          "disk.storage.getlist",
          {
            filter: {
              'ENTITY_TYPE': 'common'
            }
          },
          function (result)
          {
            $this.loading=false;
            if (result.error()) {
              console.error(result.error());
            }
            else {
              let res = result.data();
              // console.log('::disk.storage.getlist result=', res);
              $this.loading=true;
              BX24.callMethod(
                "disk.storage.uploadfile",
                {
                  id: res[0].ID,
                  data: {
                    NAME: random_file_name
                  },
                  fileContent: [name, file]
                },
                function (result)
                {
                  $this.loading=false;
                  if (result.error())
                    console.error(result.error());
                  else {
                    console.log('::disk.storage.uploadfile result=', result.data());
                    let res = result.data();
                    $this.file_download_link = '<a href="'+res.DOWNLOAD_URL+'">'+name+'</a>';
                    // console.log('::file_download_link=', $this.file_download_link);

                  }
                }
              );
            }
          }
        );

      },
      dataURLtoBlob(dataurl) {
          var arr = dataurl.split(',');
          var mime = arr[0].match(/:(.*?);/)[1];
          // //console.log('::arr mime',arr, mime)
          var bstr = atob(arr[1]); //decode from base64
          var n = bstr.length;
          var u8arr = new Uint8Array(n);
          while(n--){
              u8arr[n] = bstr.charCodeAt(n);
          }
          return new Blob([u8arr], {type:mime});
      },
      getLessonById(id){
          //console.log('getLesgetLessonById ', id);
          let $this = this;
          this.isLoading=true;
          let params = {
            'ENTITY': 'uni_lessons',
            'SORT': {},
            'FILTER': {
              'ID': parseInt(id)
            }

          };

          return new Promise(function(resolve, reject) {
              // BX24.callMethod('entity.item.get', {
              //     'ENTITY': 'uni_lessons',
              //     'SORT': {},
              //     'FILTER': {
              //         'ID': parseInt(id)
              //     }
              //
              // }, function (res) {
            let url =  $this.parent.bx24Init({ AJAX_ACTION: 'entityItemGet', params:params });//.then(params=> {
            $this.parent.b24POST(url, $this.parent.bx24params, function (res) {

              //console.log(res);
                  $this.isLoading = false;
                  // if (res.answer.error) {
                  //     // console.error(res.answer.error_description);
                  //     // $this.msg = res.answer.error_description;
                  //     reject(res.answer.error_description);
                  // } else {
                      if (res.result[0]) {
                          var item = res.result[0];
                          // //console.log('lesson ', id, item);
                          resolve(item);
                      }
                  // }

              });
          });
      },
      geUserCourse(){
        let $this = this;
        return new Promise(function(resolve, reject) {
          if ($this.nav) {
            let lesson_id = $this.lesson.ID;
            let course_id = $this.course_id;
            let user_id = $this.parent.user_current.ID;
            $this.parent.uCoursesTestsGetActive('course', user_id, course_id, true).then(res => {
              // console.log(':@@@:uCoursesTestsGetActive res=',res)
              if (res) {
                resolve(res.data);
              } else {
                resolve(null)
              }

            });
          }else{
            resolve(null)
          }
        });
      },
      saveLessonToCourseOption(){
        let $this = this;

        console.log(':1:saveLessonToCourseOption');
        return new Promise(function(resolve, reject) {
          // Только если в урок зашли из курса
          if ($this.nav) {
            let lesson_id = $this.lesson.ID;
            let lesson_name = $this.lesson.NAME;
            let course_id = $this.course_id;
            let user_id = $this.parent.user_current.ID;
            console.log(':2:saveLessonToCourseOption lesson_id, course_id ', lesson_id, course_id);


            /////// new
            let lessonItem={
              lesson_id: parseInt(lesson_id),
              lesson_name: lesson_name,
              status: $this.status,
              test_passed: $this.status,
              points:$this.points,
              src:'lessonView',
              dt:dayjs().format('YYYY-MM-DD'),
            }
            console.log(':@@@:saveLessonToCourseOption lessonItem=',lessonItem)

            $this.parent.uCoursesTestsGetActive('course', user_id, course_id, true).then(res=>{
              console.log(':@@@:uCoursesTestsGetActive res=',res)
              if(res){
                let userCourse = res.data;
                // console.log(':@@@: userCourse=',userCourse);
                if(userCourse.lessons){
                  let foundIndex = userCourse.lessons.findIndex((element, index, array) => {return element.lesson_id == lesson_id }, $this);
                  // console.log(':@@@: foundIndex=',foundIndex);
                  if(foundIndex==-1){
                    userCourse.lessons.push(lessonItem);
                  }else{
                    userCourse.lessons[foundIndex] = lessonItem;
                  }

                }else{
                  userCourse.lessons=[];
                  userCourse.lessons.push(lessonItem);
                }
                console.log(':@@@: userCourse=',userCourse);
                lessonItem.course_id = res.id;
                if(lessonItem.lesson_id==res.id){
                  resolve(false);
                }else{
                  $this.parent.uCoursesTestsSet('course', user_id, userCourse,  res.id).then(ok => {
                    console.log(':@@@:uCoursesTestsSet ok= ', ok);
                    resolve(true);
                  });
                }
                //Сообщаем в курс что модуль завершили
                $this.programModuleComplete();
              }else{
                console.error(':: userCourse not recording')
              }

            });


          } else {
            //console.log(':/1:saveTestToCourseOption  нет навигации ');
            reject('');
          }
        });
      },


    },
    components: {
        'viewPdf':viewPdf,
      // VuePDF
      // QMediaPlayer
    }
}
</script>

<style scoped>

    .lesson{
        /*background-color: lightgray;*/
        margin: 1px;
        padding: 2px;
        font-size: smaller;
        /*border-radius: 0.3rem;*/
    }
    .test{
        /*background-color: darkgray;*/
        margin: 1px;
        padding: 2px;
        font-size: smaller;
        /*border-radius: 0.3rem;*/
    }
    .nav-link{
        display: block;
        padding: 0.2rem;
        /*border-radius: 0.3rem;*/
        /*border-bottom: lightgray solid 1px;*/
    }

    .resp-container {
      position: relative;
      overflow: hidden;
      padding-top: 56.25%;
    }
    .resp-iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 0;
    }
 </style>

