<template>
<div class="q-py-md">
  <div class="row items-center" >
    <div class="col-12 text-positive h4">{{message[lang].imgFiles}}</div>
    <div class="col-6" >
      <q-file
        v-model="listFiles"
        :label="message[lang].upload"
        :hint="message[lang].upload_hint"
        @update:model-value="fileListChange"
        accept=".doc, .ppt, .xlsx, .pdf, image/*"
        multiple
        use-chips
        append
        rounded
        outlined
        color="accent"
      >
        <template v-slot:prepend>
          <q-img src="img/docs/file.svg" style="height: 40px; width: 40px;"/>
        </template>
        <template v-slot:after>
          <q-btn
            color="primary"
            dense
            icon="cloud_upload"
            round
            @click="saveImgFiles(true)"
            :disable="!listFiles || listFiles.length==0"
            :loading="loading"
          >
            <q-tooltip class="bg-primary h-tt">{{message[lang].upload_title}}</q-tooltip>
          </q-btn>
        </template>
      </q-file>
    </div>
<!--    -->
    <div class="col-12 q-my-md">

      <div class="row q-gutter-sm" v-if="imgFiles">
        <q-card flat bordered class="q-pa-sm" v-for="(item,index) in imgFiles.filter(el=>el.curquest==curquest)" :key="item.I" style="width: 100%; max-width: 200px; border-radius: 20px">

          <q-card-section horizontal>
            <q-img
              class="col"
              :src="item.H && item.type.indexOf('image') === 0?item.H:'img/docs/file.svg'"
            />
            <q-card-actions vertical class="justify-around q-px-md">
              <q-btn round flat color="positive" icon="clear" @click="delImage(index)">
                <q-tooltip class="bg-primary h-tt">{{message[lang].delete_image}}</q-tooltip>
              </q-btn>
            </q-card-actions>
          </q-card-section>
          <q-card-section>
            <div class="text-positive h5">{{item.N}}</div>
          </q-card-section>
        </q-card>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import {ref} from 'vue';
import {messageObj} from './helpers/message';

export default {
  name: "LoadImages",
  props:{
    test_id:String,
    parent:Object,
    lang:String,
    curquest:String,
    imgFilesProp:Object
  },
  data: function () {
    return {
      imgFiles:[],
      loading:false,
      max_file_size:3145728,
      max_file_size_mb:'3',
      listFiles:[],
      message: messageObj,
    }
  },
  mounted: function(){
    // console.log('::imgFiles mounted', this.imgFilesProp, this.listFiles)
    if(this.imgFilesProp) {
      this.imgFiles = [...this.imgFilesProp];
    }
  },
  methods: {
    save(list){
      // console.log(':1: save, list=',list, typeof list, list.length, Object.keys(list).length)
      this.$emit('save_load_images', JSON.stringify(list));
    },
    fileListChange(listFiles){
      this.listFiles = listFiles;
      // console.log('::fileListChange listFiles=',listFiles);
    },
    onRejectedAny(rejectedEntries){
      let $this=this;
      // console.log('::onRejectedAny ', rejectedEntries)
      $this.parent.notify_title = this.message[this.lang].upload_err;
      $this.parent.notify_text = '';
      for(let item of rejectedEntries){
        $this.parent.notify_text += item.file.name + ': ' + this.message[this.lang].max_file_size + '; '
        $this.parent.notify_text = $this.parent.notify_text.replace('${mb}', this.max_file_size_mb)
      }
      $this.parent.notify = true;
    },
    saveImgFiles(isStart){
      // console.log('::saveFiles listFiles', this.listFiles);
      var $this = this;
      if(isStart) { // Первый запуск
        $this.imgFiles = $this.imgFiles?$this.imgFiles:[];
        this.loading=true;
      }
      if(Object.keys(this.listFiles).length > 0){
        let itemFile = this.listFiles.pop(); //достали последний файл
        // console.log(':*1*:saveImgFiles itemFile=', itemFile);
        if (!itemFile.I ) {
          $this.parent.readFileFromDisk(itemFile).then(item=> {

            $this.sendImage(item.data, item.name).then(res=> {
              let href = res.href;
              let name = res.name;

              var file_id = res.result;

                if (!$this.imgFiles.find(el => el.curquest == $this.curquest && el.I == file_id)) {
                  $this.imgFiles.push({
                    I: 'no_id_'+$this.parent.getRandomNumber(),
                    N: name,
                    H: href,
                    // data: item.data,
                    type: item.type,
                    curquest: $this.curquest
                  });
                }
                $this.saveImgFiles();
              });


          });
        } else {
          $this.imgFiles.push({
            I: itemFile.I,
            N: itemFile.name,
            H: itemFile.H,
            type: itemFile.type,
            curquest: $this.curquest
          });
          $this.saveImgFiles();
        }
      }else{
        $this.loading = false;
        $this.save($this.imgFiles);
      }
    },
    sendImage(base64, name){
      let $this=this;
      // console.log('::doSend name=', name);
      let url=`${this.parent.app_server_url}api/sendmedia?client_key=${this.parent.member_id}&portal=${this.parent.portal}&name=${name}`;
      return new Promise((resolve, reject) => {
        $this.parent.POST(url, {base64:base64, namr:name}, function (response) {
          // console.log('::callMethod response=', response);
          if (response) {
            resolve(response);
          } else {
            reject(false);
          }
        });
      });

    },
    delImage(index){
      let $this=this;
      $this.loading = true;
      let name = this.imgFiles[index].N;
      let url=`${this.parent.app_server_url}api/clearimage?client_key=${this.parent.member_id}&portal=${this.parent.portal}&name=${name}`;
      $this.parent.POST(url, {name:name}, function (response) {
        $this.imgFiles.splice(index,1);
        $this.loading = false;
        $this.save($this.imgFiles);
      }, "json");
    },

  },
  components: {

  }

}
</script>

<style scoped>

</style>
