<template>
  <div>
    <q-file
      clearable
      v-model="listFiles"
      :label="uploadsubtitle[lang]"
      :hint="uploadtitle[lang]"
      @update:model-value="fileListChange"
      accept=".udata, .xls, .xlsx, application/vnd.ms-excel"
      borderless
      multiple
      use-chips
      class="bg-negative"
      style="padding-left:15px; border-radius: 25px !important;"
    >
      <template v-slot:prepend>
        <q-img src="img/secondary/doc/file.svg" style="height: 40px; width: 40px;"/>
      </template>
      <template v-slot:after v-if="listFiles && Object.keys(listFiles).length>0">
        <q-btn
          color="primary"
          dense
          icon="cloud_upload"
          round
          @click="upload"
          :disable="!listFiles||Object.keys(listFiles).length==0"
          :loading="isLoading"
        />
      </template>
    </q-file>
    <div class="row justify-between q-my-sm">
      <div class="col-4 positive h5">
        <q-toggle
          v-model="set_published"
          color="primary"
          :label="message[lang].set_published"
        />
      </div>
      <div class="col-6 text-right">
        <a class="text-positive body-2" target="_blank" :href="'./assets/course_import_example_'+lang+'.xlsx'">{{message[lang].obrazec_xlsx}}</a>
      </div>
    </div>

  </div>
</template>
<script>
// import fileUpload from './FileUpload.vue';
import XLSX from 'xlsx';
import dayjs from "dayjs";

export default {
    props:{
      lang:String,
      parent:Object
    },
    data: function () {
        return {
          set_published:false,
            countLoaded:0,
            testsLoaded:0,
            lessonsLoaded:0,
            maxCount:100000,
            alertMessage:'',
            isLoading:false,
            listFiles:'',
            uploadtitle:{
              ru:"Для загрузки уроков можно использовать файлы ранее выгруженные в формате .udata или .xlsx, шаблон  можно скачать справа под кнопкой",
              en:"To load lessons, you can use files that were previously uploaded in .udata or .xlsx, the template can be downloaded on the right under the button",
              ua:"Для завантаження уроків можна використовувати файли вивантажені раніше у форматі .udata або .xlsx, шаблон  можна завантажити праворуч під кнопкою",
              fr:"Pour charger les leçons, les fichiers doivent être au format .udata or .xlsx. Vous pouvez télécharger le template en cliquant sous le bouton à droite.",
              it:"Per caricare lezioni, puoi usare file che sono stati precedentemente caricati in .udata or .xlsx, il template può essere scaricato a destra sotto il pulsante",
              tr:"Dersleri yüklemek için daha önce .udata veya .xlsx'e yüklenmiş dosyaları kullanabilirsiniz, şablon sağdaki düğmenin altında indirilebilir",
              de:"To load lessons, you can use files that were previously uploaded in .udata or .xlsx, the template can be downloaded on the right under the button",
              pl:"Aby załadować lekcje, możesz użyć plików, które zostały wcześniej przesłane w .udata lub .xlsx, szablon można pobrać po prawej stronie pod przyciskiem",
              pt:"Para carregar as aulas, você pode usar arquivos que foram carregados anteriormente em .udata ou .xlsx, o modelo pode ser baixado à direita sob o botão",
              es:"Para cargar lecciones, puede usar archivos que se cargaron previamente en .udata o .xlsx, la plantilla se puede descargar a la derecha debajo del botón",
              vn:"Để tải các bài học, bạn có thể sử dụng các tệp đã được tải lên trước đó ở dạng .udata hoặc .xlsx, mẫu có thể được tải xuống ở bên phải dưới nút",

            },
            uploadsubtitle:{
              ru:"Перетащите сюда файлы в формате .udata или .xlsx или кликните здесь для выбора файлов на диске.",
              en:"Drag and drop files in format .udata or .xlsx or click here to select files on disk.",
              ua:"Перетягніть сюди файли в форматі .udata або .xlsx або клікніть тут для вибору файлів на диску.",
              fr:"Glisser-déposer vos fichiers au format .udata or .xlsx ou cliquer ici pour les sélectionner sur votre PC.",
              it:"Trascina e rilascia file in formato .udata or .xlsx o fai click qui per selezionare dei file dal disco.",
              tr:"Dosyaları .udata .xlsx ile götürülüp götürülüp diskteki dosyaları için buraya tıklayın.",
              de:"Drag and drop files in format .udata or .xlsx or click here to select files on disk.",
              pl:"Przeciągnij i upuść pliki w formacie .udata lub .xlsx lub kliknij tutaj, aby wybrać pliki na dysku.",
              pt:"Arraste e solte os arquivos no formato .udata ou .xlsx ou clique aqui para selecionar os arquivos no disco.",
              es:"Arrastre y suelte archivos en formato .udata o .xlsx o haga clic aquí para seleccionar archivos en el disco.",
              vn:"Kéo và thả tệp ở định dạng .udata hoặc .xlsx hoặc nhấp vào đây để chọn tệp trên đĩa. ",
            },
          level_options: {
            ru: [
              {text: 'легкий', value: 'low'},
              {text: 'средний', value: 'moderate'},
              {text: 'сложный', value: 'high'},
            ],
            en: [
              {text: 'low', value: 'low'},
              {text: 'moderate', value: 'moderate'},
              {text: 'high', value: 'high'},
            ],
            ua: [
              {text: 'легкий', value: 'low'},
              {text: 'середній', value: 'moderate'},
              {text: 'складний', value: 'high'},
            ],
            fr: [
              {text: 'faible', value: 'low'},
              {text: 'moyen', value: 'moderate'},
              {text: 'fort', value: 'high'},
            ],
            it: [
              {text: 'basso', value: 'low'},
              {text: 'intermedio', value: 'moderate'},
              {text: 'alto', value: 'high'},
            ],
            tr: [
              {text: 'düşük', value: 'low'},
              {text: 'ılıman', value: 'moderate'},
              {text: 'yüksek', value: 'high'},
            ],
            de: [
              {text: 'niedrig', value: ' low'},
              {text: 'mäßig', value: 'moderate'},
              {text: 'hoch', value: 'high'},
            ],
            pl: [
              {text: 'niska', value: 'low'},
              {text: 'umiarkowany', value: 'moderate'},
              {text: 'wysoka', value: 'high'},
            ],
            pt: [
              {text: 'baixo', value: 'low'},
              {text: 'moderado', value: 'moderate'},
              {text: 'alto', value: 'high'},
            ],
            es: [
              {text: 'bajo', value: 'low'},
              {text: 'moderado', value: 'moderate'},
              {text: 'alto', value: 'high'},
            ],
            vn: [
              {text: 'thấp', value: 'low'},
              {text: 'vừa phải', value: 'moderate'},
              {text: 'cao', value: 'high'},
            ],

          },
          typeTest_options: {
            ru: [
              {text: 'Итоговый', value: 'final'},
              {text: 'Промежуточный', value: 'midpoint'},
            ],
            en: [
              {text: 'Final', value: 'final'},
              {text: 'Midpoint', value: 'midpoint'},
            ],
            ua: [
              {text: 'Підсумковий', value: 'final'},
              {text: 'Проміжний', value: 'midpoint'},
            ],
            fr:[
              {text: 'Final', value: 'final'},
              {text: 'Moyenne', value: 'midpoint'},
            ],
            it: [
              {text: 'Finale', value: 'final'},
              {text: 'Intermedio', value: 'midpoint'},
            ],
            tr: [
              {text: 'Son', value: 'final'},
              {text: 'Orta nokta', value: 'midpoint'},
            ],
            de: [
              {text: 'Finale', value: 'final'},
              {text: 'Mittelpunkt', value: 'midpoint'},
            ],
            pl: [
              {text: 'Finał', value: 'final'},
              {text: 'Środek', value: 'midpoint'},
            ],
            pt: [
              {text: 'Final', value: 'final'},
              {text: 'Ponto médio', value: 'midpoint'},
            ],
            es: [
              {text: 'Final', value: 'final'},
              {text: 'Punto medio', value: 'midpoint'},
            ],
            vn: [
              {text: 'Cuối cùng', value: 'final'},
              {text: 'Điểm giữa', value: 'midpoint'},
            ],

          },
          typeQuest_options: {
            ru: [
              {text: 'Выбор', value: 'qa'},
              {text: 'Последовательность', value: 'seq'},
              {text: 'Соответствие', value: 'eqw'},
              {text: 'Открытый', value: 'opn'},
              {text: 'Подстановка', value: 'sub'}
            ],
            en: [
              {text: 'Option', value: 'qa'},
              {text: 'Sequencing', value: 'seq'},
              {text: 'Matching', value: 'eqw'},
              {text: 'Open', value: 'opn'},
              {text: 'Substitution', value: 'sub'}
            ],
            ua: [
              {text: 'вибір', value: 'qa'},
              {text: 'послідовність', value: 'seq'},
              {text: 'відповідність', value: 'eqw'},
              {text: 'Відкритий', value: 'opn'},
              {text: 'Підстановка', value: 'sub'}
            ],
            fr:[
              {text: 'Choix multiple', value: 'qa'},
              {text: 'Séquence', value: 'seq'},
              {text: 'Correspondance', value: 'eqw'},
              {text: 'Ouvert', value: 'opn'},
              {text: 'Substitution', value:'sub'}
            ],
            it: [
              {text: 'Opzione', value: 'qa'},
              {text: 'Sequenziamento', value: 'seq'},
              {text: 'Matching', value: 'eqw'},
              {text: 'Aperto', value: 'opn'},
              {text: 'Sostituzione', value:'sub'}
            ],
            tr: [
              {text: 'Seçenek', value: 'qa'},
              {text: 'Sıralama', value: 'seq'},
              {text: 'Eşleştirme', value: 'eqw'},
              {text: 'Açık', value: 'opn'},
              {text: 'Ikame', value: 'sub'}
            ],
            de: [
              {text: 'Möglichkeit', value: 'qa'},
              {text: 'Sequenzierung', value: 'seq'},
              {text: 'Passende', value: 'eqw'},
              {text: 'Offen', value: 'opn'},
              {text: 'Substitution', value: 'sub'}
            ],
            pl: [
              {text: 'Opcja', value: 'qa'},
              {text: 'Sekwencjonowanie', value: 'seq'},
              {text: 'Dopasowanie', value: 'eqw'},
              {text: 'Otwarty', value: 'opn'},
              {text: 'Podstawienie', value: 'sub'}
            ],
            pt: [
              {text: 'Opção', value: 'qa'},
              {text: 'Sequenciamento', value: 'seq'},
              {text: 'Coincidindo', value: 'eqw'},
              {text: 'Abrir', value: 'opn'},
              {text: 'Substituição', value:'sub'}
            ],
            es: [
              {text: 'Opción', value: 'qa'},
              {text: 'Secuenciación', value: 'seq'},
              {text: 'Pareo', value: 'eqw'},
              {text: 'Abierto', value: 'opn'},
              {text: 'Sustitución', value:'sub'}
            ],
            vn: [
              {text: 'Lựa chọn', value: 'qa'},
              {text: 'Giải trình tự', value: 'seq'},
              {text: 'Phù hợp', value: 'eqw'},
              {text: 'Mở ra', value: 'opn'},
              {text: 'Thay', value: 'sub'}
            ],

          },
          message:{
            ru:{
              set_published:"Публиковать сразу",
              accept:"Неверный тип файла",
              max_file_size:"Размер файла не должен превышать 200Мб",
              upload:"Загрузить",
              obrazec_xlsx:"Скачать образец xlsx-файла для импорта",
            },
            en:{
              set_published:"Publish immediately",
              accept: "Invalid file type",
              max_file_size: "The file size should not exceed 200Mb",
              upload:"Upload",
              obrazec_xlsx:"Download a sample xlsx file for importing",
            },
            ua:{
              set_published:"Публікувати відразу",
              accept: "Неправильний тип файлу",
              max_file_size: "Розмір файлу не повинен перевищувати 200Мб",
              upload:"Завантажити",
              obrazec_xlsx:"Завантажити зразок xlsx-файлу для імпорту",
            },
            fr:{
              set_published:"Publier immédiatement",
              accept: "Type de fichier Incorrect",
              max_file_size: "La taille du fichier ne doit pas dépasser 200 Mo",
              Télécharger:"Télécharger",
              obrazec_xlsx:"Télécharger un modèle de fichier xlsx pour préparer votre importation",
            },
            it:{
              set_published:"Pubblica immediatamente",
              accept: "Tipo di file non valido",
              max_file_size: "La dimensione del file non deve superare i 200 MB",
              upload:"Carica",
              obrazec_xlsx:"Scarica un file xlsx di esempio per l’importazione",
            },
            tr:{
              set_published:"Hemen yayınla",
              accept: "Geçersiz dosya türü",
              max_file_size: "Dosya boyutu 200Mb'ı geçmemelidir",
              upload:"Yüklemek",
              obrazec_xlsx:"İçe aktarmak için örnek bir xlsx dosyası indirin",
            },
            de:{
              set_published:"Sofort veröffentlichen",
              accept: "ungültiger Dateityp",
              max_file_size: "Die Dateigröße sollte 200 MB nicht überschreiten",
              upload:"Hochladen",
              obrazec_xlsx:"Laden Sie eine xlsx-Beispieldatei zum Importieren herunter",
            },
            pl:{
              set_published:"Opublikuj natychmiast",
              accept: "Nieprawidłowy typ pliku",
              max_file_size: "Rozmiar pliku nie powinien przekraczać 200 MB",
              upload:"Wgrywać",
              obrazec_xlsx:"Pobierz przykładowy plik xlsx do importu",
            },
            pt:{
              set_published:"Publicar imediatamente",
              accept: "Tipo de arquivo inválido",
              max_file_size: "O tamanho do arquivo não deve exceder 200Mb",
              upload:"Envio",
              obrazec_xlsx:"Baixe um arquivo xlsx de amostra para importação",
            },
            es:{
              set_published:"Publicar inmediatamente",
              accept: "Tipo de archivo invalido",
              max_file_size: "El tamaño del archivo no debe exceder los 200Mb.",
              upload:"Subir",
              obrazec_xlsx:"Descargue un archivo xlsx de muestra para importar",
            },
            vn:{
              set_published:"Xuất bản ngay lập tức",
              accept: "Loại tệp không hợp lệ",
              max_file_size: "Kích thước tệp không được vượt quá 200Mb",
              upload:"Tải lên",
              obrazec_xlsx:"Tải xuống tệp xlsx mẫu để nhập",
            },

          },
        }
    },
    mounted: function(){
        this.countLoaded=0;
        this.maxCount=this.parent.getMaxCountByCache('courses');
    },
     watch:{

    },
    methods: {
      onRejectedOne (rejectedEntries) {
        console.log('::onRejectedOne ', rejectedEntries)
        if(rejectedEntries[0]) {

          // eslint-disable-next-line vue/no-mutating-props
          this.parent.notify_text='';
          for(let item of rejectedEntries){
            if(item.failedPropValidation == 'max-file-size') {
              // eslint-disable-next-line vue/no-mutating-props
              this.parent.notify_text += item.file.name + ': ' + this.message[this.lang].max_file_size
            }else if(item.failedPropValidation == 'accept') {
              // eslint-disable-next-line vue/no-mutating-props
              this.parent.notify_text = item.file.name + ': ' + this.message[this.lang].accept
            }

          }
          if(this.parent.notify_text){
            // eslint-disable-next-line vue/no-mutating-props
            this.parent.notify = true;
          }
        }
      },
        fileListChange(listFiles){
            // console.log(':: fileListChange', listFiles);
            this.listFiles = listFiles;
        },
        upload(){
            // console.log(':: upload', this.listFiles);
          // eslint-disable-next-line vue/no-mutating-props
          this.isLoading=true;
          // eslint-disable-next-line vue/no-mutating-props
          this.parent.isShowImport=false;
          // eslint-disable-next-line vue/no-mutating-props
          this.parent.isImportLoading=true;
            if(Object.keys(this.listFiles).length > 0){
                let item=this.listFiles.pop();
                // console.log(':: item=',item);
              this.parent.readFileFromDisk(item).then(data=> {
                let b64data = data.data.substr(data.data.indexOf('base64,') + 7);
                let b64data2 = atob(b64data);
                if(item.name.indexOf('.xls') !== -1){
                    // console.log(':: read to xls');
                    let workbook = XLSX.read(b64data2, {type:"binary"});
                    // console.log(':: workbook=', workbook);
                    let first_sheet_name = workbook.SheetNames[0];
                    /* Get worksheet */
                    let worksheet = workbook.Sheets[first_sheet_name];
                    let course={PROPERTY_VALUES:{}};
                    let courses=[];
                    let course_lesson={};
                    let lesson={};
                    let lessons=[];
                    let course_test={};
                    let test={DETAIL_TEXT:'', PROPERTY_VALUES:{}};;
                    let tests=[];
                    let test_blocks=[];
                    let item_blocks={};
                    let questions=[];
                    let answers={};
                    let quest_id=0;
                    let answ_id=0;
                    let row_type='';
                    let isEnd=false;
                    let emptyNums=0;
                    let columns=["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O"];
                    let last_row_type = '';
                    let last_value='';
                    let last_type='';
                    let last_weight='';
                    let last_block='';
                    for(let j=1; j<10010; j++){
                        if(isEnd){
                            break;
                        }
                        last_row_type = row_type;
                        for (let i in columns){
                            let col=columns[i];
                            let row=j;
                            let address_of_cell = col+row;
                            let cell = worksheet[address_of_cell];
                            /* Get the value */
                            let value = (cell ? cell.v : undefined);

                            // console.log(':'+address_of_cell+': value=|%s|', value);

                            if(col === 'A' && value && value.startsWith('#')){
                                //это комментарий
                                break;
                            }else if(col === 'A' && value==='COURSE'){
                                if(course.NAME) {
                                    course.lessons = lessons;
                                    course.tests = tests;
                                    // courses.push(course);
                                    if(this.countLoaded < this.maxCount) {
                                        courses.push(course);
                                        this.countLoaded++;
                                    }else{
                                        isEnd=true;
                                        break;
                                    }
                                }
                                row_type='course';
                                course={PROPERTY_VALUES:{course:[]}};
                                lessons=[];
                                tests=[];
                                test={};
                                lesson={PROPERTY_VALUES:[]};
                                quest_id=0;
                                emptyNums = 0;
                                break;
                            }else if(col === 'A' && value==='LESSON'){
                                course_lesson={id:'78910', type:'lesson'};
                                lesson={ID:'78910', PROPERTY_VALUES:{}};
                                row_type='lesson';
                                emptyNums = 0;
                                break;
                            }else if(col === 'A' && value==='TEST'){
                                if(test.NAME) {
                                    if(this.testsLoaded < this.maxCount) {
                                        // test.PROPERTY_VALUES.questions = JSON.stringify(questions);
                                        // test.PROPERTY_VALUES.answers = JSON.stringify(answers);
                                      test.DETAIL_TEXT = JSON.stringify({questions:questions, answers:answers});

                                      let test_obj = {data: JSON.stringify(test)};
                                        tests.push(test_obj);
                                        this.testsLoaded++;
                                    }
                                }
                                course_test={id:'78910', type:'test'};
                                test={ID:'78910', PROPERTY_VALUES:{}};
                                questions=[];
                                answers={};
                                row_type='test';
                                emptyNums = 0;
                                break;
                            }
                            else if(col === 'A' && value==='BLOCKS'){
                              row_type='blocks';
                              emptyNums = 0;
                              break;
                            }
                            else if(col === 'A' && value==='QUEST'){
                                row_type='qusetion';
                                quest_id ++;
                                emptyNums = 0;
                                break;
                            }else if(col === 'A' && value==='ANSW'){
                                row_type='answers';
                                answ_id = 0;
                                answers[quest_id]=[];
                                emptyNums = 0;
                                break;
                            }
                            else if(col === 'B' && !value){
                                emptyNums ++;
                                if(emptyNums > 5 || row >10000) {
                                    isEnd = true;
                                    if(course.NAME) {
                                        // test.PROPERTY_VALUES.questions = JSON.stringify(questions);
                                        // test.PROPERTY_VALUES.answers = JSON.stringify(answers);
                                      test.DETAIL_TEXT = JSON.stringify({questions:questions, answers:answers});

                                      let test_obj = {data:JSON.stringify(test)};
                                        tests.push(test_obj);
                                        course.lessons = lessons;
                                        course.tests = tests;
                                        if(this.countLoaded < this.maxCount) {
                                            courses.push(course);
                                            this.countLoaded++;
                                        }else{
                                            isEnd=true;
                                            break;
                                        }

                                    }
                                }
                                break;
                            }
                            //
                            // console.log(':'+address_of_cell+': row_type=', row_type, ' value=',value);
                            if(row_type === 'course'){
                                value = value ? value : '';
                                if(col === 'B'){
                                    course.NAME = value;
                                }else if(col === 'C'){
                                    course.PROPERTY_VALUES.target = value;
                                }else if(col === 'D'){
                                    course.PROPERTY_VALUES.duration = {hours:value};
                                }else if(col === 'E'){
                                    course.PROPERTY_VALUES.duration.lessons = value;
                                }else if(col === 'F'){
                                    course.PROPERTY_VALUES.program = value;
                                }else if(col === 'G'){
                                    course.PROPERTY_VALUES.thematics = value;
                                }else if(col === 'H'){
                                    course.DETAIL_PICTURE = value;
                                }
                            }
                            else if(row_type === 'lesson'){

                                if(col === 'B'){
                                    course_lesson.name = value;
                                    lesson.NAME = value;
                                    course.PROPERTY_VALUES.course.push(course_lesson);
                                }else if(col === 'C' && value){
                                    if(value.startsWith('http')){
                                        let url = document.createElement('a');
                                        url.href = value;
                                        if(url.pathname === "/watch"){
                                            value = url.search.split('&')[0].split('=')[1];
                                        }else {
                                            value = url.pathname.substr(1);
                                        }
                                    }
                                    lesson.PROPERTY_VALUES.youtube = value;
                                    let lesson_obj = {data:JSON.stringify(lesson)};
                                    if(this.lessonsLoaded < this.maxCount) {
                                        lessons.push(lesson_obj);
                                        this.lessonsLoaded++;
                                    }
                                }
                            }
                            else if(row_type === 'test'){
                                if(col === 'B'){
                                    course_test.name = value;
                                    test.NAME = value;
                                    course.PROPERTY_VALUES.course.push(course_test);
                                }else if(col === 'C'){
                                    test.PROPERTY_VALUES.thematics = value;
                                }else if(col === 'D'){
                                    test.PROPERTY_VALUES.description = value;
                                }else if(col === 'E'){
                                    test.PROPERTY_VALUES.interval = value?parseInt(value):0;
                                }else if(col === 'F'){
                                    test.PROPERTY_VALUES.todisplay = value;
                                }else if(col === 'G'){
                                    let level=this.level_options[this.lang].find((element, index, array)=>{ if(element.text.toLowerCase() === value.toLowerCase()) return true; else return false }, this);
                                    level = level ? level : value;
                                    test.PROPERTY_VALUES.level = level.value;
                                }else if(col === 'H'){
                                    test.PROPERTY_VALUES.score = value;
                                }else if(col === 'I'){
                                    test.PROPERTY_VALUES.time = value;
                                }else if(col === 'J'){
                                    let type=this.typeTest_options[this.lang].find((element, index, array)=>{ if(element.text.toLowerCase() === value.toLowerCase()) return true; else return false }, this);
                                    type = type ? type : value;
                                    test.PROPERTY_VALUES.type = type.value;
                                }else if(col === 'K'){
                                  let show_results = this.parent.show_results[this.lang].find((element, index, array) => {
                                    if (element.text == value) { return true; } else { return false; }
                                  }, this);
                                  test.PROPERTY_VALUES.show_results = show_results?show_results.value:'0';
                                }else if(col === 'L'){
                                  let no_prev = this.parent.YesNo[this.lang].find((element, index, array) => {
                                    if (element.text == value) { return true; } else { return false; }
                                  }, this);
                                  test.PROPERTY_VALUES.no_prev = no_prev?no_prev.value:'N';
                                }else if(col === 'M') {
                                  test.DETAIL_PICTURE = value;
                                }else if(col === 'N') {
                                  test.PROPERTY_VALUES.flags = value;
                                }
                            }
                            else if(row_type === 'blocks'){
                              // console.log(':'+address_of_cell+': value=', value, test_blocks);
                              if(col === 'B'){
                                item_blocks ={name:value};
                              }else if(col === 'C'){
                                item_blocks.id = value;
                              }else if(col === 'D'){
                                item_blocks.count = value;
                              }else if(col === 'E'){
                                item_blocks.rand = value === true || value === 'Y';
                                test_blocks.push(JSON.parse(JSON.stringify(item_blocks)));
                              }
                            }
                            else if(row_type === 'qusetion') {
                                if (col === 'B' && value) {
                                    last_value=value;
                                }else if (col === 'C' && last_value) {
                                    value = value ? value : 'Выбор';
                                    let type = this.typeQuest_options[this.lang].find((element, index, array) => {
                                        if (element.text.toLowerCase() === value.toLowerCase()) return true; else return false
                                    }, this);
                                    // if(!type){
                                    //     this.alertMessage += this.message[this.lang].no_type_quest+' ';
                                    // }
                                    type = type ? type.value : value;
                                    // questions.push({value: quest_id, text: last_value, type: type});
                                    last_type=type;
                                }else if (col === 'D') {
                                  last_weight=value ? value : 0;
                                  // questions.push({value: quest_id, text: last_value, type: last_type, weight:last_weight});
                                  // break;
                                }else if (col === 'E') {
                                  last_block=value ? value : '';
                                  questions.push({value: quest_id, text: last_value, type: last_type, weight:last_weight, block:last_block});
                                  if(test_blocks && test_blocks.length>0) {
                                    test.PROPERTY_VALUES.blocks = JSON.stringify(test_blocks);
                                  }
                                  break;
                                }
                            }else if(row_type === 'answers') {
                                if (col === 'B'  && value) {
                                    answ_id ++;
                                    last_value=value;
                                }else if (col === 'C' && last_value && last_type) {
                                    let item={value: answ_id, text: last_value};
                                    if(last_type === 'qa') {
                                        let isRight = value && (value.toLowerCase() === 'правильный' || value.toLowerCase() === 'right' || value.toLowerCase() === 'correct' || value.toLowerCase() === 'true');
                                        item.right = isRight;
                                    }else if(last_type === 'eqw') {
                                        item.text2 = value;
                                    }
                                    answers[quest_id].push(item);
                                    break;
                                }
                            }
                        }

                    }

                    console.log(':: course loaded from xls ', courses);
                    this.doSaveCourses(courses);

                }
                else {

                    let json = decodeURIComponent(escape(atob(b64data2)));
                    let course = JSON.parse(json);
                    console.log(':: course=', course);
                    let lessons = course.lessons_exp;
                    let tests = course.tests_exp;

                    if(this.countLoaded < this.maxCount) {
                        this.lessonsProcess(course, lessons).then(ok => {
                            console.log(':: +++ lessons imported ', ok);
                            this.testsProcess(course, tests).then(ok => {
                                console.log(':: +++ tests imported ', ok);
                                this.doSaveCourse(course).then(ok => {
                                    console.log(':: +++ course imported ', ok);
                                    this.upload();
                                    this.countLoaded++;
                                });
                            }, err => {
                                console.error(err)
                            });

                        }, err => {
                            console.error(err)
                        });
                    }else{
                        this.parent.tarifAlert('courses').then(isOk=>{});
                        this.listFiles=[];
                        this.upload();
                    }


                }
              });
            }else{
              this.isLoading=false;
              this.$emit('importCourses', true)
            }

        },
        testsProcess(course, tests){
          console.log('::testsProcess course=',course);
          console.log('::testsProcess tests=',tests);
            let $this = this;
            let len = tests ? Object.keys(tests).length : 0;
            return new Promise(function (resolve, reject) {
                if (len > 0) {
                    let item = tests.pop();
                    // console.log('::testsProcess item=',item);
                    if (item.data) {
                        let test = JSON.parse(item.data);
                        // console.log('::testsProcess test=',test);
                        //загрузим файлы теста
                        if (test.files) {
                            // console.log(':: test.files=', test.files);
                            let count = Object.keys(test.files).length;
                            for (let j in test.files) {
                                let item = test.files[j];
                                // console.log(':: item=', item);
                                $this.parent.saveFile(item).then(file_id => {
                                    // console.log(':: ------ count=%i old_id=%i new_id=%i', count, item.ID, file_id);
                                    if (test.PROPERTY_VALUES.answers) {
                                        test.PROPERTY_VALUES.answers = test.PROPERTY_VALUES.answers.replace('"file":' + item.ID, '"file":' + file_id);
                                    }
                                    count--;
                                    if (count <= 0) {
                                        // выполнились все
                                        test.files = null;
                                        $this.doSaveTest(test).then(res => {
                                            // console.log(':1: lesson imported ', ' course=>', course);
                                            if (res) {
                                                $this.setNewId(course, test.ID, res, 'test');
                                                // console.log(res);
                                            }
                                            if(Object.keys(tests).length <= 0){
                                                resolve('ok');
                                            }else{
                                                $this.testsProcess(course, tests).then(res=>{
                                                    resolve(res);
                                                })
                                            }
                                        });
                                    }

                                }, err => {
                                    console.log(err)
                                });

                            }
                        } else {
                            $this.doSaveTest(test).then(res => {
                                // console.log(':1: lesson imported ', ' course=>', course);
                                if (res) {
                                    $this.setNewId(course, test.ID, res, 'test');
                                    // console.log(res);
                                }
                                if(Object.keys(tests).length <= 0){
                                    resolve('ok');
                                }else{
                                    $this.testsProcess(course, tests).then(res=>{
                                        resolve(res);
                                    })
                                }
                            });
                        }

                    }
                }else{
                    resolve('ok');
                }
            });
        },
        lessonsProcess(course, lessons){
            // console.log(':1:lessonsProcess lessons=', lessons);
            let $this = this;
            let len = lessons ? Object.keys(lessons).length : 0;
            return new Promise(function (resolve, reject) {

                if(len > 0){
                    let item = lessons.pop();

                    if (item.data) {
                        let lesson = JSON.parse(item.data);
                        // console.log(':1:lessonsProcess lesson=', lesson);
                        if (lesson.files) {
                            // console.log(':: lesson.files=', lesson.files);
                            let count = Object.keys(lesson.files).length;
                            for (let jet in lesson.files) {
                                let item = lesson.files[jet];
                                // console.log(':: item=', item);
                                $this.parent.saveFile(item).then(file_id => {
                                    // console.log(':: ------ count=%i old_id=%i new_id=%i', count, item.ID, file_id);
                                    if (lesson.PROPERTY_VALUES.files) {
                                        lesson.PROPERTY_VALUES.files = lesson.PROPERTY_VALUES.files.replace('"I":' + item.ID, '"I":' + file_id);
                                    }
                                    count--;
                                    if (count <= 0) {
                                        // выполнились все
                                        lesson.files = null;
                                        $this.doSaveLesson(lesson).then(res => {
                                            console.log(':1: lesson imported res=', res);
                                            if (res) {
                                                $this.setNewId(course, lesson.ID, res, 'lesson');
                                                // console.log(res);
                                            }
                                            if(Object.keys(lessons).length <= 0){
                                                resolve('ok');
                                            }else{
                                                $this.lessonsProcess(course, lessons).then(res=>{
                                                    resolve(res);
                                                })
                                            }
                                        });
                                    }

                                }, err => {
                                    console.log(err)
                                });

                            }
                        } else {
                            $this.doSaveLesson(lesson).then(res => {
                                // console.log(':2: lesson imported res=',res);
                                if (res) {
                                    $this.setNewId(course, lesson.ID, res, 'lesson');
                                    // console.log(res);
                                }
                                if(Object.keys(lessons).length <= 0){
                                    resolve('ok');
                                }else{
                                    $this.lessonsProcess(course, lessons).then(res=>{
                                        resolve(res);
                                    })
                                }
                            });
                        }
                    }
                }else{
                    resolve('ok')
                }
            });
        },
        setNewId(course, oldId, newId, type){
            // console.log('::setNewId ',course, oldId, newId, type);
            if(course.PROPERTY_VALUES.course && parseInt(oldId) != parseInt(newId)) {
                let course_program = JSON.parse(course.PROPERTY_VALUES.course);
                let isChange=false;
                for(let i in course_program){
                    let item=course_program[i];
                    if(item.type === type && parseInt(oldId) === parseInt(item.id)){
                        course_program[i].id = newId;
                        course_program[i].imorted = 'Y';
                        isChange=true;
                        break;
                    }
                }
                if(isChange){
                    course.PROPERTY_VALUES.course = JSON.stringify(course_program);
                }
            }
        },
        doSaveTest(test){
            // console.log(':: doSaveTest tests=', tests);
            let $this = this;
            return new Promise(function (resolve, reject) {
                $this.parent.getTestByName(test.NAME).then(res=>{
                    // console.log('::getTestByName res=',res);

                    let method = 'entityItemAdd';
                    let id=0;
                    if(res.result && res.result[0] && res.result[0].ID){
                        id = res.result[0].ID;
                        method = 'entityItemUpdate';
                    }

                    let params = {
                        'ENTITY': 'uni_tests',
                        'PREVIEW_TEXT': $this.parent.categoria,
                        'NAME': test.NAME,
                        // 'DETAIL_TEXT': test.DETAIL_TEXT,
                        'DATE_CREATE': dayjs().format('DD.MM.YYYY'),
                        'ACTIVE': $this.set_published?'Y':'N',
                        'DATE_ACTIVE_FROM':'',
                        'PROPERTY_VALUES': test.PROPERTY_VALUES
                    };
                    if(id){
                        params.ID=id;
                    }
                    // console.log(':: params=', params);
                    // BX24.callMethod(method, params, function (res) {
                    //     //console.log(res);
                    //     if (res.answer.error) {
                    //         console.error(res.answer.error_description);
                    //         reject(false);
                    //     } else {
                  var url = $this.parent.bx24Init({ AJAX_ACTION: method, params:params });//.then(params=> {
                  $this.parent.bx24params.DETAIL_TEXT = test.DETAIL_TEXT;
                  $this.parent.bx24params.DETAIL_PICTURE = test.DETAIL_PICTURE;
                  $this.parent.b24POST(url, $this.parent.bx24params, function (res) {

                    let test_id = parseInt(id);
                      if (res.result) {
                        if (id) {
                            console.log('Test updated! id=', id);
                        } else {
                            test_id = parseInt(res.result);
                            console.log('New test added! id=', test_id);
                        }
                      }
                      resolve(test_id);
                      // }
                  }, "json");

                });
            });
        },
        doSaveLesson(lesson){
            // console.log(':: doSavelesson lessons=', lessons);
            let $this = this;
            return new Promise(function (resolve, reject) {
                $this.parent.getLessonByName(lesson.NAME).then(res => {
                    console.log('::getlessonByName res=',res);

                    let method = 'entityItemAdd';
                    let id = 0;
                    if (res.result && res.result[0] && res.result[0].ID) {
                        id = res.result[0].ID;
                        method = 'entityItemUpdate';
                    }

                    let params = {
                        'ENTITY': 'uni_lessons',
                        'PREVIEW_TEXT': $this.parent.categoria,
                        'NAME': lesson.NAME,
                        // 'DETAIL_TEXT': lesson.DETAIL_TEXT,
                        'DATE_CREATE': dayjs().format('DD.MM.YYYY'),
                        'ACTIVE':  $this.set_published?'Y':'N',
                        'DATE_ACTIVE_FROM': '',
                        'PROPERTY_VALUES': lesson.PROPERTY_VALUES
                    };
                    if (id) {
                        params.ID = id;
                    }
                    // console.log(':: params=', params);
                    // BX24.callMethod(method, params, function (res) {
                    //     // console.log(':: res=',res);
                    //     if (res.answer.error) {
                    //         console.error(res.answer.error_description);
                    //         reject(false);
                    //     } else {
                  var url = $this.parent.bx24Init({ AJAX_ACTION: method, params:params });//.then(params=> {
                  $this.parent.bx24params.DETAIL_TEXT = lesson.DETAIL_TEXT;
                  $this.parent.bx24params.DETAIL_PICTURE = lesson.DETAIL_PICTURE;
                  $this.parent.b24POST(url, $this.parent.bx24params, function (res) {
                    let lesson_id = parseInt(id);
                    if (res.result) {
                        if(id){
                            console.log('lesson updated! ',id);
                        }else {
                            lesson_id = parseInt(res.result);
                            console.log('New lesson added! ',lesson_id);
                        }
                    }
                    resolve(lesson_id);
                        // }
                  });

                });
            });
        },
        doSaveCourse(course){
            // console.log(':: doSaveCourse course=', course);
            let $this = this;
            return new Promise(function (resolve, reject) {
              $this.getCourseByName(course.NAME).then(res => {
                // console.log('::getCourseByName res=',res);
                let method = 'entityItemAdd';
                let id = 0;
                if (res.result && res.result[0] && res.result[0].ID) {
                    id = res.result[0].ID;
                    method = 'entityItemUpdate';
                }

                let params = {
                    'ENTITY': 'uni_courses',
                    'PREVIEW_TEXT': $this.parent.categoria,
                    'NAME': course.NAME,
                    'DATE_CREATE': dayjs().format('DD.MM.YYYY'),
                    'ACTIVE': $this.set_published?'Y':'N',
                    'DATE_ACTIVE_FROM': '',
                    'PROPERTY_VALUES': course.PROPERTY_VALUES
                };
                    // if(course.DETAIL_TEXT){
                    //     params.DETAIL_TEXT=course.DETAIL_TEXT;
                    // }
                if (id) {
                  params.ID = id;
                }
                    // console.log(':: params=', params);
                    // BX24.callMethod(method, params, function (res) {
                    //     //console.log(res);
                    //     if (res.answer.error) {
                    //         console.error(res.answer.error_description);
                    //     } else {
                  var url = $this.parent.bx24Init({ AJAX_ACTION: method, params:params });//.then(params=> {
                  $this.parent.bx24params.DETAIL_PICTURE = course.DETAIL_PICTURE;
                  $this.parent.b24POST(url, $this.parent.bx24params, function (res) {
                    let course_id = parseInt(id);
                    if (res.result) {
                      if (id) {
                        console.log('course updated! id=', id);
                      } else {
                        course_id = parseInt(res.result);
                        console.log('New course added! id=', course_id);
                      }
                    }
                    resolve(course_id);
                        // }
                  }, "json");

                });
            });

        },
        getCourseByName(name) {
            // console.log(':: getCourseByName: ', name);
            let $this = this;
            let params={
              'ENTITY': 'uni_courses',
              'SORT': {},
              'FILTER': {
                'NAME': name
              }
            };
            return new Promise(function (resolve, reject) {
                // BX24.callMethod('entity.item.get', {
                //     'ENTITY': 'uni_courses',
                //     'SORT': {},
                //     'FILTER': {
                //         'NAME': name
                //     }
                //
                // }, function (res) {
              let url =  $this.parent.bx24Init({ AJAX_ACTION: 'entityItemGet', params:params });
              $this.parent.b24POST(url, $this.parent.bx24params, function (res) {
                resolve(res);
              }, "json");
            });
        },
        doSaveCourses(courses){
            if(courses && Object.keys(courses).length > 0){
                let course = courses.pop();
                course.PROPERTY_VALUES.course =  JSON.stringify(course.PROPERTY_VALUES.course);
                course.PROPERTY_VALUES.duration =  JSON.stringify(course.PROPERTY_VALUES.duration);
                let lessons = course.lessons;
                let tests = course.tests;
                console.log('::doSaveCourses course=',course);
                this.lessonsProcess(course, lessons).then(res=>{
                    // console.log('::doSaveCourses lessonsProcess res=',res);
                    this.testsProcess(course, tests).then(res=>{
                        // console.log('::doSaveCourses testsProcess res=',res);
                        this.doSaveCourse(course).then(res=>{
                            console.log('::doSaveCourses res=',res);
                            this.upload();
                        });
                    });
                });
            }else{
                console.log('::doSaveCourses finish!');

            }

        },
        ///
    },
    components: {
     // fileUpload
    }
}
</script>

<style scoped>


 </style>

