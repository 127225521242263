<template>
    <div :class="!hideTabs?'component_padding q-my-md':'q-my-xs'">

        <q-tabs
              v-model="tab"
              class="text-accent"
              active-color="primary"
              align="left"
            >
            <q-tab v-if="!hideTabs" name="list_programs" :label="message[lang].list_programs" />
            <q-tab v-if="!hideTabs" name="list_schedules" :label="message[lang].list_schedules"/>
            <q-tab v-if="!hideTabs" name="list_user_schedules" :label="message[lang].results"/>

        </q-tabs>
        <q-tab-panels
              v-model="tab"
              class="bg-dark text-accent q-pr-md"
            >
            <!-- Программы -->
            <q-tab-panel name="list_programs" style="height: 750px; padding:0px; margin:0px;">
                <div class="row items-center justify-between q-mb-md" style="margin-top: 0px">
                  <div class="col-11 h5 text-positive">
                    <q-expansion-item
                        icon="help_outline"
                        :label="message[lang].program_info.substr(0,100)+'...'"
                      >
                        <div v-html="message[lang].program_info"/>
                    </q-expansion-item>
                  </div>
                  <div class="col-1 text-right q-gutter-md" style="padding-right: 10px">
                      <q-btn :disable="loading" padding="xs" size="lg" flat round color="secondary" icon="add_circle_outline" @click="addProgram(null,null)">
                          <q-tooltip class="bg-primary h-tt">{{message[lang].add_program}}</q-tooltip>
                      </q-btn>
                  </div>
                </div>
                
                <!-- Фильтр по шаблонам -->
                <div class="q-pa-md text-right">
                    <q-btn-dropdown color="positive" :label="message[lang].prog_filter" dropdown-icon="tune" no-caps rounded flat>
                    <q-list>
                        <q-item clickable v-close-popup @click="loadMorePrograms(true)">
                        <q-item-section>
                            <q-item-label>{{message[lang].prog_filter_all}}</q-item-label>
                        </q-item-section>
                        </q-item>

                        <q-item clickable v-close-popup @click="loadMorePrograms(true, 'grp', 500)">
                        <q-item-section>
                            <q-item-label>{{message[lang].prog_filter_tgrp}}</q-item-label>
                        </q-item-section>
                        </q-item>

                        <q-item clickable v-close-popup @click="loadMorePrograms(true, 'user', 10)">
                        <q-item-section>
                            <q-item-label>{{message[lang].prog_filter_tuser}}</q-item-label>
                        </q-item-section>
                        </q-item>
                    </q-list>
                    </q-btn-dropdown>
                </div>
                <div class="g-mr-md">
                    <q-card class="cat-card q-py-sm">
                        <q-card-section>
                            <q-list separator v-if="listPrograms && listPrograms.length">
                                <q-item v-for="(item,index) in listPrograms"
                                    :key="index"
                                    clickable
                                    v-ripple
                                    >
                                    <q-item-section @click="addProgram(index, item)">
                                        <span :class="item.ACTIVE==='Y' ? 'text-primary' : 'text-positive'">
                                            <q-icon size="sm"  v-if="item.PREVIEW_TEXT==='template_type:user'"
                                                name="person" :color="item.ACTIVE==='Y' ? 'primary':'positive'"/>
                                            <q-icon size="sm"  v-else-if="item.PREVIEW_TEXT==='template_type:grp'"
                                                name="groups" :color="item.ACTIVE==='Y' ? 'primary':'positive'"/>    

                                            {{parent.restoreQuotes(item.NAME)}}
                                        </span>
                                    </q-item-section>
                                    <q-item-section side>
                                    <q-btn flat round icon="file_copy" color="positive" @click="copyProgram(index, item)">
                                        <q-tooltip class="bg-primary h-tt">{{message[lang].copy_program}}</q-tooltip>
                                    </q-btn>
                                    <q-btn :disable="item.ACTIVE==='Y'" flat round icon="clear" color="positive" @click="delProgram(index, item, 'programs')"/>
                                    </q-item-section>
                                </q-item>
                            </q-list>
                            <div class="text-center" v-if="isMoreProgram">
                            <q-btn @click="loadMorePrograms()" round flat color="positive" icon="keyboard_arrow_down" />
                            </div>
                        </q-card-section>
                    </q-card>
                </div>
            </q-tab-panel>

            <!-- Назначения -->
            <q-tab-panel name="list_schedules" style="height: 750px; padding:0px; margin:0px">
                <div class="row items-center justify-end q-mb-md" style="margin-top: 0px">
                    <div class="col-3 text-right q-gutter-md" style="padding-right: 10px">
                        <q-btn :disable="loading" padding="xs" size="lg" flat round color="secondary" icon="add_circle_outline" @click="addSchedule(null,null)">
                            <q-tooltip class="bg-primary h-tt">{{message[lang].add_schedule}}</q-tooltip>
                        </q-btn>
                    </div>
                </div>

                <div class="g-mr-md">
                    <q-card class="cat-card">
                        <q-list separator v-if="listSchedules && listSchedules.length">
                            <q-item v-for="(item,index) in listSchedules"
                                :key="index"
                                clickable
                                v-ripple
                                >
                                <q-item-section @click="addSchedule(index, item)">
                                    <span :class="item.ACTIVE==='Y' ? 'text-primary' : 'text-accent'">
                                        {{item.ID}} {{parent.restoreQuotes(item.program_name)}}
                                        [{{ parent.customFormatter(item.DATE_ACTIVE_FROM) }} - {{ parent.customFormatter(item.DATE_ACTIVE_TO) }}]

                                    </span>
                                    <div class="row q-gutter-xs text-positive h6" v-if="item.DETAIL_TEXT && item.DETAIL_TEXT.students && item.DETAIL_TEXT.students.length" >
                                        <div class="q-my-none" v-for="(student, st_index) in item.DETAIL_TEXT.students" :key="st_index">{{student.name}}, </div>
                                    </div>
                                </q-item-section>
                                <q-item-section side>
                                    <q-btn :disable="item.ACTIVE==='Y'" flat round icon="clear" color="positive" @click="delProgram(index, item, 'progscheds')"></q-btn>
                                </q-item-section>
                            </q-item>
                        </q-list>
                        <!--   -->
                        <div class="text-center" v-if="isMore">
                          <q-btn @click="loadSchedules()" round flat color="positive" icon="keyboard_arrow_down" />
                        </div>
                    </q-card>
                </div>
            </q-tab-panel>

            <!-- Результаты назначений -->
            <q-tab-panel name="list_user_schedules" style="height: 750px; padding:0px; margin:0px">
                <div class="g-mr-md" style="margin-top: 56px">
                    <!-- ЛК мои программы -->
                    <q-card v-if="hideTabs" class="cat-card">
                        <q-list separator>
                            <q-item v-for="(item, index) in listUserSchedules" :key="index">
                                <q-item-section>
                                    <q-item-label class="text-accent h4">
                                        {{ parent.restoreQuotes(item.program_name) }}
                                    </q-item-label>
                                    <q-item-label caption>
                                        {{ parent.customFormatter(item.DATE_ACTIVE_FROM) }} - {{ parent.customFormatter(item.DATE_ACTIVE_TO) }}
                                    </q-item-label>
                                    <div class="q-pt-sm q-pl-md">
                                        <div class="q-pa-none q-ma-none" v-for="(moduleRow, index_module_row) in item.DETAIL_TEXT" :key="index_module_row" style="margin-left: 20px; border-bottom: 1px dotted #cccccc">
                                          <div v-if="moduleRow && moduleRow.length > 1">
                                            <!-- На уровне несколько модулей свернем их -->
                                            <q-expansion-item
                                              clickable
                                              :label="`${message[lang].module_level} ${index_module_row+1} `"
                                              :icon="isModulesLevelPassed(moduleRow)?'done':'remove'"
                                              :header-class="isModulesLevelPassed(moduleRow)?'text-secondary':'text-accent'"
                                            >
                                              <div class="q-pa-none q-ma-none" v-for="(module, index_module) in moduleRow" :key="index_module">
                                                  <div class="row q-pa-xs myselect">
                                                      <div class="col-10 q-pr-md text-positive">
                                                          {{ showTypeModule(module.mtype) }}
                                                          <span v-if="isShowModule(item.DETAIL_TEXT, index_module, index_module_row, item.method)"
                                                              class="text-primary cursor-pointer" @click="goModule(module, item.ID)">
                                                              {{ module.mname }}
                                                          </span>
                                                          <span v-else>{{ module.mname }}</span>
                                                      </div>
                                                      <div class="col-2" :class="'text-'+(showModulePassed(module.passed).color)">
                                                          <q-icon :name="showModulePassed(module.passed).icon"/>
                                                          {{ showModulePassed(module.passed).label }}
                                                      </div>

                                                  </div>
                                              </div>
                                            </q-expansion-item>
                                          </div>
                                          <div v-else>
                                           <!-- На уровне 1 модуль -->
                                              <div class="q-pa-none q-ma-none" v-for="(module, index_module) in moduleRow" :key="index_module">
                                                  <div class="row q-pa-xs myselect">
                                                      <div class="col-10 q-pr-md text-positive">
                                                          {{index_module_row+1}} {{ showTypeModule(module.mtype) }}
                                                          <span v-if="isShowModule(item.DETAIL_TEXT, index_module, index_module_row, item.method)"
                                                              class="text-primary cursor-pointer" @click="goModule(module, item.ID)">
                                                              {{ module.mname }}
                                                          </span>
                                                          <span v-else>{{ module.mname }}</span>
                                                      </div>
                                                      <div class="col-2" :class="'text-'+(showModulePassed(module.passed).color)">
                                                          <q-icon :name="showModulePassed(module.passed).icon"/>
                                                          {{ showModulePassed(module.passed).label }}
                                                      </div>

                                                  </div>
                                              </div>


                                          </div>

                                        </div>
                                    </div>
                                </q-item-section>
                            </q-item>
                        </q-list>

                    </q-card>
                    <!-- Назначения У модератора -->
                    <q-card v-else class="cat-card">
                        <q-list separator v-if="listSchedules && listSchedules.length">
                            <q-expansion-item
                                group="somegroup"
                                clickable
                                v-for="(item,index) in listSchedules"  :key="index"
                                :label="parent.restoreQuotes(item.program_name) + ' ' + parent.customFormatter(item.DATE_ACTIVE_FROM) + ' - ' + parent.customFormatter(item.DATE_ACTIVE_TO)"
                                @click="loadUserSchedules(item.ID)"
                                header-class="text-accent"
                                >
                                <q-item-section v-if="listUserSchedules && listUserSchedules.length">
                                    <q-item-section class="q-px-xl q-my-sm h5 text-positive" >
                                        <div>
                                            {{message[lang].type_open}}: {{showProgramType(item.program_type)}}"
                                        </div>
                                    </q-item-section>
                                        <q-expansion-item group="usergroup" class="q-px-xl" v-for="(sched, ind_sched) in listUserSchedules" :key="ind_sched">
                                          <template v-slot:header>
                                            <q-item-section class="text-accent" v-if="listUsers && Object.keys(listUsers).length && listUsers[sched.NAME]">
                                                <q-separator />
                                                <div><q-icon
                                                    :name="showModulePassed(isAllModulesPassed(sched.DETAIL_TEXT)).icon"
                                                    :color="showModulePassed(isAllModulesPassed(sched.DETAIL_TEXT)).color"
                                                  />
                                                  <span class="text-primary">{{listUsers[sched.NAME].name}}</span>
                                                  <span v-if="!listUsers[sched.NAME].active" class="text-warning">{{message[lang].not_in_sched}}</span>

                                                </div>
                                            </q-item-section>
                                          </template>
                                        <!-- Список модулей одного юзера -->
                                        <template v-slot:default>
                                          <q-item-section v-if="sched.DETAIL_TEXT">
                                              <div v-for="(modRow,index_mod_row) in sched.DETAIL_TEXT" :key="index_mod_row" style="margin-left: 20px; border-bottom: 1px dotted #cccccc">
                                                  <div>-{{ index_mod_row+1 }}-</div>
                                                  <div v-for="(mod,index_mod) in modRow" :key="index_mod">
                                                      <div class="row text-positive h6 q-my-xs">
                                                          <div class="col-1">
                                                              {{ showTypeModule(mod.mtype) }}
                                                          </div>
                                                          <div class="col-8">{{mod.mname}} </div>
                                                          <div class="col-3">
                                                              <q-select
                                                                  dense
                                                                  option-label="label"
                                                                  option-value="value"
                                                                  map-options
                                                                  emit-value
                                                                  v-model="mod.passed"
                                                                  :options="message[lang].mod_passed"
                                                                  @update:model-value="setModulePassed(sched, mod)"
                                                                  color="positive"
                                                                  label-color="positive"
                                                                  class="text-positive"
                                                              >
                                                                  <template v-slot:prepend>
                                                                      <q-icon
                                                                          :name="showModulePassed(mod.passed).icon"
                                                                          :color="showModulePassed(mod.passed).color"
                                                                      />
                                                                  </template>
                                                                  <q-tooltip v-if="mod.who" class="bg-primary h-tt">
                                                                      {{listUsers[mod.who].name}},
                                                                      {{ message[lang].mod_action[mod.action] }},
                                                                      {{ mod.date }}
                                                                  </q-tooltip>
                                                              </q-select>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                          </q-item-section>
                                        </template>
                                      </q-expansion-item>
                                    <!--  -->
                                </q-item-section>
                            </q-expansion-item>
                        </q-list>
                        <div class="text-center" v-if="isMore">
                          <q-btn @click="loadSchedules()" round flat color="positive" icon="keyboard_arrow_down" />
                        </div>
                    </q-card>
                </div>
            </q-tab-panel>

        </q-tab-panels>

        <q-inner-loading :showing="loading">
          <q-spinner-oval size="5em" color="positive" />
        </q-inner-loading>

        <!-- Dialogues -->
        <q-dialog v-model="course_modal" persistent full-width full-height>
            <q-card class="bg-dark">
                <q-card-section class="q-pt-xl">
                    <course-view
                        @lessonViewById="parent.lessonViewById"
                        @testViewById="parent.testViewById"
                        @goback="courseClose"
                        @courseReturn="courseReturn"
                            v-bind:parent="parent"
                            v-bind:lang="lang"
                            v-bind:courseItem="moduleItem"
                            v-bind:user_current="parent.user_current"
                            v-bind:back="parent.currentScreen"
                        ></course-view>
                </q-card-section>
            </q-card>
        </q-dialog>
        <q-dialog v-model="test_modal" persistent full-width full-height>
            <q-card class="bg-dark">
                <q-card-section class="q-pt-xl">
                    <test-view
                        @testViewById="parent.testViewById"
                        @lessonViewById="parent.lessonViewById"
                        @goback="courseClose"
                        @courseReturn="courseReturn"
                       v-bind:parent="parent"
                       v-bind:back="parent.currentScreen"
                       v-bind:lang="lang"
                       v-bind:testItem="moduleItem"
                       v-bind:user_current="parent.user_current"
            ></test-view>
                </q-card-section>
            </q-card>
        </q-dialog>
        <q-dialog v-model="lesson_modal" persistent full-width full-height>
            <q-card class="bg-dark">
                <q-card-section class="q-pt-xl">
                    <lesson-view
                        @lessonViewById="parent.lessonViewById"
                        @testViewById="parent.testViewById"
                        @goback="courseClose"
                        @courseReturn="courseReturn"
                         v-bind:parent="parent"
                         v-bind:back="parent.currentScreen"
                         v-bind:lang="lang"
                         v-bind:lessonItem="moduleItem"
                        ></lesson-view>
                </q-card-section>
            </q-card>
        </q-dialog>
        <q-dialog v-model="events_modal" persistent full-width full-height>
            <q-card class="bg-dark">
              <q-card-section class="row items-center q-pb-none">
                <q-btn rounded no-caps
                  @click="courseReturn({...moduleItem,passed:true})"
                  color="secondary"
                  :label="message[lang]['module_complete']"/>
                  <q-space />
                  <q-btn icon="close" flat round dense v-close-popup color="accent"/>
                </q-card-section>
                <q-card-section class="q-pt-xl">
                  <events-list @addNewEvent="addNewEvent" @editEvent="editEvent" @goback="goback"
                          v-bind:parent="parent"
                          v-bind:lang="lang"
                          v-bind:back="backScreen"
                          v-bind:mode="'programs'"
                          v-bind:eventItem="moduleItem"
                  ></events-list>
                </q-card-section>
            </q-card>
        </q-dialog>

    </div>

</template>

<script>
import {messageObj} from "src/helpers/message";
import dayjs from "dayjs";
import courseView from './CourseView.vue';
import testView from './TestView.vue';
import lessonView from './LessonView.vue';
import listOfEvents from './ListOfEvents.vue';

    export default {
        name: "ListOfPrograms",
        props:{
            parent:Object,
            lang:String,
            back:String,
            mntTab:String,
            hideTabs:Boolean,
            search:String

        },
        data() {
            return {
                loading:false,
                last_id:null,
                total:0,
                count:10,
                listPrograms:[],
                listSchedules:[],
                listUserSchedules:[],
                tab:'list_programs',
                listUsers:{},
                moduleItem:{},
                course_modal:false,
                test_modal:false,
                lesson_modal:false,
                events_modal:false,
                program_chedule_id:0,


                message: messageObj
            }
        },
        computed: {
          isMore(){
            // console.log('isMore',this.listSchedules, this.total, !this.loading, Object.keys(this.listSchedules).length >= this.count)
            return this.listSchedules && this.total && !this.loading && Object.keys(this.listSchedules).length >= this.count;
          },
          isMoreProgram(){
            // console.log('isMore',this.listSchedules, this.total, !this.loading, Object.keys(this.listSchedules).length >= this.count)
            return this.listPrograms && this.total && !this.loading && Object.keys(this.listPrograms).length >= this.count;
          },

        },
        watch: {
          tab(val){
            if(val=='list_user_schedules' || val=='list_schedules'){
              this.loadSchedules(true);
            }else{
              this.loadMorePrograms(true);
            }
          },
          search(val){
            console.log(':: search=',val)
            let filter = null;
            let table='programs';
            if(this.tab=='list_user_schedules'){
              table='uprogscheds'
            }else if(this.tab=='list_schedules'){
              table='progscheds'
              filter = {
                    '1': {
                    LOGIC: "AND",
                    '%DETAIL_TEXT': '%' +val + '%'
                    }
                };
            }else{
              filter = {
                    '1': {
                    LOGIC: "AND",
                    '%NAME': '%' +val + '%'
                    }
                };
            }
            this.loading=true;
            this.parent.loadPrograms(table, this.count, this.last_id, null, null, null, null,filter).then(res=>{
                this.total = false;
                this.loading=false;
                if(res && res.length>0){
                  this.listPrograms = res;
                }else{
                  this.listPrograms = [];
                }
              });
          }
        },
        mounted() {
          this.tab = this.mntTab;
            // this.loading=true;
            this.loadMorePrograms(true);//.then(res=>{
                // this.listPrograms = res;
                // console.log(':: mounted listPrograms ',this.listPrograms)
                // this.loading=false;

                if(this.tab=='list_schedules' || this.tab=='list_user_schedules'){
                    if(this.hideTabs){
                        this.loadMySchedules();
                    } else{
                        this.loadSchedules(true);
                    }
                }

            // });

        },
        methods: {
            showProgramType(type){
                const ret = this.message[this.lang].type_open_opt.find(el=>{return el.value==type});
                return ret?ret.label:type;
            },
            setModulePassed(user_sched, module, isAuto){
                //TODO: сохранять id модератора, который сделал ручное изменеие
                // будет еще параметр self_passed, который будет проставляться автоматически при
                // завершении модуля студентом (ищем в назначениях студента этот модуль и ставим self_passed=Y|N)
                //
                module.who = this.parent.user_current.ID; //Кто изменил
                module.date = dayjs().format('YYYY-MM-DD HH:mm'); //Дата изменения
                module.action = isAuto?'auto':'hand'; //Способ изменения - ручками
                console.log(':: setModulePassed ',user_sched);
                let params = {
                    'ENTITY': 'uni_uprogscheds',
                    'ID': user_sched.ID,
                    'DETAIL_TEXT': JSON.stringify(user_sched.DETAIL_TEXT),
                };
                let url =  this.parent.bx24Init({ AJAX_ACTION: 'entityItemUpdate', params:params });
                this.parent.b24POST(url, this.parent.bx24params, function (res) {
                    if (res) {
                        console.log(':: setModulePassed saved:',res);
                    }
                }, "json");

            },
            showTypeModule(type){
                const item = this.message[this.lang].module_type_opt.find(i=>i.value==type);
                return item?item.label:'';
            },
            loadMorePrograms(isReload, tmpl_type, limit){
                this.loading=true;
                if(isReload){
                    this.last_id = null;
                    this.listPrograms = [];
                }
                let count = this.count;
                let filter = null;
                let active = null;
                if(tmpl_type){
                    filter = {
                        '1': {
                        'LOGIC': "AND",
                        'PREVIEW_TEXT': 'template_type:'+tmpl_type
                        }
                    };
                    count = limit;
                }
                console.log(':: loadSchedules',isReload, tmpl_type, limit, filter)

              this.parent.loadPrograms('programs', count, this.last_id, active, null, null, null, filter).then(res=>{
                this.total = false;
                this.loading=false;
                if(res && res.length>0){
                  this.total = true;
                  this.last_id = res[res.length-1].ID;
                  this.listPrograms = [...this.listPrograms, ...res];
                }
              });
            },
            loadSchedules(isReload){
                let active = this.tab=='list_user_schedules' ? 'Y' : false; //В результатах показываем только активные назначения
                this.loading=true;
                if(isReload){
                    this.last_id = null;
                }
                
                this.parent.loadPrograms('progscheds', this.count, this.last_id, active).then(res=>{
                    this.total = false;
                    if(isReload){
                        this.listSchedules = [];
                    }
                    this.listSchedules = this.listSchedules?this.listSchedules:[]
                    if(res && res.length>0){
                      this.total = true;

                      for(let item of res){
                          let program = this.listPrograms.find(p=>p.ID==item.NAME);
                          item.program_name = program?program.NAME:'---';
                          item.program_type = program&&program.PROPERTY_VALUES.type?program.PROPERTY_VALUES.type:'P';
                          item.DETAIL_TEXT = this.parent.isJSON(item.DETAIL_TEXT)?JSON.parse(item.DETAIL_TEXT):item.DETAIL_TEXT;
                          item.STUFF = this.parent.isJSON(item.STUFF)?JSON.parse(item.STUFF):item.STUFF;
                          this.last_id = item.ID;
                          this.listSchedules.push(item)
                      }
                    }
                    // console.log('::listSchedules ',this.listSchedules)

                    this.loading=false;
                });
            },
            /**
             *  Для ЛК
             */

            /**
             * функция findElement итерирует по каждому подмассиву
             * в массиве array и использует метод find для поиска элемента
             * с указанным mid. Если элемент найден, функция возвращает его,
             * иначе возвращает null.
             */
             findElement(array, mid){
                for (const subArray of array) {
                    const element = subArray.find(item => {return item.mid == mid});
                    if (element) {
                        return element;
                    }
                }
                return null;
            },
             courseClose(arg) {
                console.log('::courseClose ', arg);
                this.course_modal = false;
                this.test_modal = false;
                this.lesson_modal = false;
            },
            courseReturn(arg) {
                console.log('::courseReturn ', arg);
                arg = this.parent.isJSON(arg)?JSON.parse(arg):arg;
                let user_sched = this.listUserSchedules.find(s=>{ return s.ID==arg.program_chedule_id});
                console.log('::courseReturn user_sched=', user_sched);
                if(user_sched){
                  // for(let itemRow of user_sched.DETAIL_TEXT){
                    let module = this.findElement(user_sched.DETAIL_TEXT, arg.module_id);
                    // user_sched.DETAIL_TEXT.find(m=>{return m.mid == arg.module_id});
                    console.log('::courseReturn module=', module);
                    if(module){
                        module.passed = arg.passed?'Y':'N';
                        this.setModulePassed(user_sched, module, true);
                    }
                  // }
                }
                this.course_modal = false;
                this.test_modal = false;
                this.lesson_modal = false;
                this.events_modal = false;
            },
            loadMySchedules(){
                this.loading=true;
                this.parent.loadPrograms('uprogscheds', 5000, null, null, null, this.parent.user_current.ID).then(res=>{
                    console.log(':: loadMySchedules res=',this.parent.user_current.ID, res)
                    this.listUserSchedules = res?res:[];
                    for(let item of this.listUserSchedules){
                        item.DETAIL_TEXT = this.parent.getProgramModules(item.DETAIL_TEXT);
                        item.STUFF = this.parent.isJSON(item.STUFF)?JSON.parse(item.STUFF):item.STUFF;
                        let program = this.listPrograms.find(p=>p.ID==item.STUFF);
                        item.program_name = program?program.NAME:'---';
                        item.method = program?program.PROPERTY_VALUES.type:'S'; //P
                    }
                    console.log(':: listUserSchedules ',this.listUserSchedules)
                    this.loading=false;

                });

            },
            goModule(mod, usched_id){
                console.log(':: goModule ',mod, usched_id);
                this.program_chedule_id = usched_id;
                this.moduleItem = {ID: mod.mid,  go: true, program_chedule_id: usched_id, module_id: mod.mid};
                if(mod.mtype==1){
                    this.course_modal = true;
                }else if(mod.mtype==2){
                    this.test_modal = true;
                }else if(mod.mtype==3){
                    this.lesson_modal = true;
                }else if(mod.mtype==4){
                    this.events_modal = true;
                }

            },
            showModuleLink(mod, usched_id){
                let mod_type = mod.mtype==1?'course':(mod.mtype==2?'test':'lesson');
                const link = `${this.parent.appURL}?${mod_type}=${mod.mid}&program=${usched_id}`;
                return link;
            },
            showModulePassed(passed){
                let label = this.message[this.lang].mod_passed.find(i=>{return i.value==passed});
                label = label?label.label:'-?-';
                const color = passed=='Y'?'secondary':(passed=='N'?'warning':'positive');
                const icon = passed=='Y'?'check':(passed=='N'?'close':'remove');
                return {label:label, color:color, icon:icon};
            },
            isModulesLevelPassed(modules){
              let ret=true;
              for(let mod of modules){
                if(mod.passed!=='Y'){
                  ret=false;
                  break;
                }
              }
              return ret;
            },
            isShowModule(list, index_module, index_module_row, method){
                //console.log(':: isShowModule ',list, index_module, index_module_row, method);
                if(method=='P' || index_module_row == 0){
                    //Первый уровень всегда открыт
                    return true;
                }else {
                    /**
                     * method=='H': Открывать последовательно. Если модуль не пройден, не открывать последующий
                     * method=='S': Открывать последовательно. Если модуль не пройден, все равно открывать следующий

                     * Если в программе задано последовательное открытие модулей,
                     * то модули на одном уровне доступны все сразу,
                     * при условии что весь прошлый уровень закрыт.
                        •⁠  ⁠m1√
                        •⁠  ⁠⁠m2√,m3√,m4√
                        •⁠  ⁠⁠m5,m6 <-на этом уровне доступны оба модуля сразу
                        •⁠  ⁠⁠m7 <-этот уровень не доступен
                     */
                    for(let i in list[index_module_row-1]){
                        let item = list[index_module_row-1][i];
                        // console.log(':: ? ',item.passed, item );
                        if(item.passed!=='Y'&& method=='H' || item.passed==0 && method=='S'){
                            //console.log('-------')
                            return false;
                        }
                    }
                    // console.log('+++')
                    return true;
                }
                return false;
            },
            isAllModulesPassed(list){
                for(let itemRow of list){
                    for(let item of itemRow){
                        if(item.passed=='N'){
                            return 'N';
                        }else if(item.passed!=='Y'){
                            return null;
                        }
                    }
              }
              return 'Y';
            },
            loadUserSchedules(sched_id){
                this.loading=true;
                let schedule = this.listSchedules.find(el=>{return el.ID==sched_id});
                console.log(':: loadUserSchedules schedule=',schedule)

                this.parent.loadPrograms('uprogscheds', 5000, null, null, sched_id).then(res=>{
                    console.log(':: loadUserSchedules res=',sched_id,res)
                    this.listUserSchedules = res?[...res]:[];
                    let usersIds=[];
                    for(let item of this.listUserSchedules){
                        item.DETAIL_TEXT = this.parent.isJSON(item.DETAIL_TEXT)?JSON.parse(item.DETAIL_TEXT):item.DETAIL_TEXT;
                        item.DETAIL_TEXT.modules = this.parent.getProgramModules(item.DETAIL_TEXT.modules);
                        usersIds.push(parseInt(item.NAME));
                        //достанем все who
                        const whoArray = item.DETAIL_TEXT.reduce((acc, obj) => {
                            if(obj.who) acc.add(parseInt(obj.who));
                            return acc;
                        }, new Set()).values();
                        usersIds = [...usersIds, ...whoArray];
                    }
                    //Оставим только уникальные id
                    usersIds = Array.from(new Set(usersIds));

                    console.log(':: loadUserSchedules usersIds=',usersIds)
                    this.parent.getUsers(true, '', usersIds).then(users => {
                        let items = [];
                        for (let i in users) {
                            let user = users[i];
                            let user_id = parseInt(user.ID);
                            let index_in_sched = schedule.DETAIL_TEXT.students?schedule.DETAIL_TEXT.students.findIndex(el=>el.id==user_id):-1;
                            let itemUser = {id: user.ID, name: user.NAME + ' ' + user.LAST_NAME, dep: user.UF_DEPARTMENT, type:user.USER_TYPE, active:index_in_sched>=0};
                            this.listUsers[user_id] = itemUser;
                        }
                        console.log(':: loadUserSchedules listUsers=',this.listUsers)
                        let list = [];
                        for(let i in this.listUsers){
                          list.push(this.listUsers[i]);
                        }
                        this.parent.getSubAccounts(list).then(res=>{
                          for(let i in res){
                            this.listUsers[parseInt(res[i].id)] = res[i];
                          }
                          console.log(':: this.listUsers=',this.listUsers);
                        });
                    });

                    this.loading=false;
                });
            },
            // loadRecursiveUserSchedules(programs){
            //     let program = programs.shift();
            //     if(program){
            //         this.parent.loadPrograms('uprogscheds', 5000, null, program.ID).then(res=>{
            //             this.listUserSchedules = [...this.listUserSchedules, ...res];
            //             this.loadRecursiveUserSchedules(programs);
            //         });
            //     }

            // },
            addSchedule(index, item){
                this.$emit('goback',{scr:'scheduleAdd', itemSchedule:item});
            },
            addProgram(index, item){
                this.$emit('goback',{scr:'programAdd', itemProgram:item});
            },
            copyProgram(index, item){
              item.ID=null;
              item.ACTIVE='N';
              this.$emit('goback',{scr:'programAdd', itemProgram:item});
            },


            delProgram(index, item, table){
                console.log('::delProgram',index, item);
                let $this = this;
                $this.parent.do_confirm = ()=>{
                    let params = {
                        'ENTITY': `uni_${table}`,
                        'ID': item.ID
                    };
                    let url =  $this.parent.bx24Init({ AJAX_ACTION: 'entityItemDel', params:params });
                    $this.parent.b24POST(url, $this.parent.bx24params, function (res) {
                        if(table=='progscheds'){
                            $this.listSchedules.splice(index,1);
                        }else{
                            $this.listPrograms.splice(index,1);
                        }
                    });

                    $this.parent.confirm=false;
                };
                $this.parent.title_confirm = this.message[this.lang].del_program;
                $this.parent.message_confirm = this.message[this.lang].del_program_msg + ' "'+item.NAME+'"?';
                $this.parent.modal_ok = null;
                $this.parent.modal_cancel = null;

                $this.parent.confirm=true;
            }

        },
        components: {
            courseView,
            testView,
            lessonView,
            'eventsList': listOfEvents,
        }
    }
</script>

<style scoped>
.myselect:hover {
    filter: brightness(70%);
}


</style>
